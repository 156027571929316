import ServerSideDataTable from "../../../Feature/DataTable/ServerSideDataTable";
import ModalDatatableItem from "../../../Feature/Modal/ModalDatatableItem";
import AppLoading from "../../../Feature/AppLoading";
import PageDependencies from "../../../Feature/PageDependencies";
import TooltipTemplate from "../../../Layout/AppNav/TooltipTemplate"

const ProjectDataTable = (props) => {
  const { 
    Fragment, 
    useState,
    guid,
    dataExtension,
    getData,
    tryCatchFinally,
    toastPrint,
  } = PageDependencies();
  const debug = props.debug === undefined ? false : props.debug;
  const emptyGuid = guid.emptyGuid();
  const [modal, setModal] = useState({
    isOpen: false,
    title: "",
    message: "",
    comfirmButtonStyle: "",
    comfirmButtonText: "Comfirm",
    handleComfirm: () => {},
  });
  const [loading, setLoading] = useState({isLoading:false,text:""});
  const [data, setData] = useState([]);
  const tableId = "Project_99998d90-e946-4514-9300-45dcdb39157a";
  const tableReload = props.tableReload;
  const setTableReload = props.setTableReload;
  const extraPostModelProject = props.extraPostModelProject;
  const setExtraPostModelProject = props.setExtraPostModelProject;
  const extraPostModelDevelopment = props.extraPostModelDevelopment;
  
  const columns = [
    { name: "name", text: "Name", width: "16%" },
    { name: "address", text: "Address", width: "16%" },
    { name: "developmentName", text: "Development", width: "16%" },
    { name: "client", text: "Client", width: "16%" },
    { name: "starRating", text: "Star rating", width: "16%" },
    { name: "submittedDate", text: "Submitted Date", width: "17%" },
  ];
  const contextMenu = {
    items: {
      new: {
        name: "New",
        icon: "add",
        url: "/ProjectAccessor/Detail/" + getSelectedDevelopmentId() + "/" + guid.emptyGuid(),
        defaultAction: true,
      },
      separator: "---------",
      edit: {
        name: "Edit",
        icon: "edit",
        url: "/ProjectAccessor/Detail/" + getSelectedDevelopmentId() + "/",
        defaultAction: true,
          disabled: function () {
          return dataExtension.getSelectedRowDataIds(data).length !== 1;
        },
      },
      delete: {
        name: "Delete",
        icon: "delete",
        url: "",
        defaultAction: false,
        disabled: function () {
          return dataExtension.getSelectedRowDataIds(data).length === 0;
        },
      },
      duplicate: {
        name: "Duplicate",
        icon: "copy",
        url: "",
        defaultAction: false,
        disabled: function () {
          return dataExtension.getSelectedRowDataIds(data).length === 0;
        },
      },
      // downloadSimulation: {
      //   name: "Download simulation",
      //   icon: "download",
      //   url: "",
      //   defaultAction: false,
      //   disabled: function () {
      //     return dataExtension.getSelectedRowDataIds(data).length !== 1;
      //   },
      // },
    },
    callBack: (
      contextMenuCallBackKey,
      contextMenuCallBackElement,
      contextMenuCallBackTableRoadData,
      localtion
    ) => {
      if (contextMenuCallBackKey === "delete") {
        setModal({
          ...modal,
          isOpen: true,
          title: "Delete projects",
          message:
            "Are sure to delete " +
            dataExtension.getSelectedRowDataIds(data).length +
            " selected projects?",
          comfirmButtonStyle: "danger",
          comfirmButtonText: "Confirm to delete",
          handleComfirm: handleDeleteComfirm,
        });
      }
      if (contextMenuCallBackKey === "duplicate") {
        setModal({
          ...modal,
          isOpen: true,
          title: "Duplicate projects",
          message:
            "Are sure to duplicate " +
            dataExtension.getSelectedRowDataIds(data).length +
            " selected projects?",
          comfirmButtonStyle: "primary",
          comfirmButtonText: "Confirm to duplicate",
          handleComfirm: handleDuplicatedComfirm,
        });
      }
    },
  };

  function getSelectedDevelopmentId(){
    const developmentIds = extraPostModelDevelopment.selectedItemIds;
      if (developmentIds.length === 0) return emptyGuid;
      const developmentId = developmentIds[0];
      return developmentId;
  }

  const handleDeleteComfirm = async () => {
    setLoading({isLoading:true,text:"Deleting projects"});
    await tryCatchFinally.asyncProcess(async ()=>{
      const selectedItemIds = dataExtension.getSelectedRowDataIds(data);
      const deleteResponse = await getData.processPost(
        true,
        "api/Project/Accessor/Delete",
        {selectedItemIds:selectedItemIds}
      );
      setTableReload(guid.newGuid());
      toastPrint.printResponse(deleteResponse);
    }, setLoading);
  };

  const handleDuplicatedComfirm = async () => {
    setLoading({isLoading:true,text:"Duplicating projects"});
    await tryCatchFinally.asyncProcess(async ()=>{
      const selectedItemIds = dataExtension.getSelectedRowDataIds(data);
      const deleteResponse = await getData.processPost(true, "api/Project/Accessor/Copy", {selectedItemIds: selectedItemIds});
      setTableReload(guid.newGuid());
      toastPrint.printResponse(deleteResponse);
    }, setLoading);
  };

  return (
    <Fragment>
      <ModalDatatableItem
        modal={modal}
        setModal={setModal}
      ></ModalDatatableItem>
       {debug &&  <p> Selected items:  {JSON.stringify(extraPostModelProject.selectedItemIds)} </p>}
      <div id="ProjectsAccordion">
        <div className="main-card mb-3 card">
          <div className="card-header">
            <button
              type="button"
              data-toggle="collapse"
              data-target="#ProjectsCollapsibleDiv"
              aria-expanded="true"
              aria-controls="ProjectsCollapsibleDiv"
              className="text-left m-0 p-0 btn btn-block"
            >
              <h5 className="m-0 p-0 card-title">Projects of selected developments<TooltipTemplate text={"This table displays the project(s) associated with the selected Development(s) from the Developments table.\nIf no Development(s) are selected, all saved projects will be shown."} result={0} color="grey" /></h5>
            </button>
          </div>
          <div id="ProjectsCollapsibleDiv" className="collapse show">
            <div className="card-body">           
              {loading.isLoading && <AppLoading active><div>{loading.text}</div></AppLoading>}
              <ServerSideDataTable
                id={tableId}
                location="Project"
                data={data}
                setData={setData}
                reload={tableReload}
                columns={columns}
                actionOnDoubleClickRow
                allowSelect
                allowSelectMultiple
                dataSource={{ isAuth: true, url: "api/Project/Accessor" }}
                contextMenu={contextMenu}
                extraPostModel={extraPostModelDevelopment}
                setSelectedItemIds = {setExtraPostModelProject}
              ></ServerSideDataTable>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default ProjectDataTable;
