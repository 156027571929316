import React, { Fragment, useEffect, useState } from 'react';
import objectPropertyValueGetSet from "../Function/ObjectPropertyValueGetSet";
const BindedSelect = (props)=>{
    const newProps = {};
    const multipleValues = '(Multiple Values)';
    const binding = props.binding;
    const bindingObject = binding[0];
    const bindingProperty = binding[1];
    const bindingMethod = binding[2];
    const bindingValue = objectPropertyValueGetSet.getPropertyValue(bindingObject, bindingProperty);
    const [message, setMessage] = useState("");
    const [selectValue, setSelectValue] = useState("");
    const validate = props.validate;
    const optionPropertyName = props['options'];
    let optionsRaw = [];
    if(typeof optionPropertyName === 'string'){
        optionsRaw = objectPropertyValueGetSet.getPropertyValue(bindingObject, optionPropertyName) === "" || objectPropertyValueGetSet.getPropertyValue(bindingObject, optionPropertyName) === undefined  ? [] : objectPropertyValueGetSet.getPropertyValue(bindingObject, optionPropertyName);
    }else{
        optionsRaw = optionPropertyName;
    }
    let options = [];
    if(optionsRaw === undefined || optionsRaw === null)optionsRaw = [];
    const optionsRawClone = [];
    for(let i = 0; i < optionsRaw.length; i++ ){
        optionsRawClone.push({...optionsRaw[i]});
    }

    for(let i = 0; i < optionsRawClone.length; i++ )
    {
        const selectItem = optionsRawClone[i];
        if(selectItem.text === multipleValues){
            selectItem.value = multipleValues;
            selectItem.description = multipleValues;
        }
        if(options.filter((o)=>{return o.value === selectItem.value}).length === 0){
            options.push(selectItem);
        }
    }
    //put (Multiple Values) as first element
    const newOpts = [];
    if(options.filter((o)=>{return o.value === multipleValues}).length > 0){
        const firstElement = options.filter((o)=>{return o.description === multipleValues || o.text === multipleValues})[0];
        newOpts.push(firstElement);
        const otherElements = options.filter((o)=>{return !(o.description === multipleValues || o.text === multipleValues)});
        for(let i = 0; i < otherElements.length; i++ ){
            newOpts.push(otherElements[i]);
        }
        options = newOpts;
    }
    for (const prop in props) {
        if (!(prop==='binding') && !(prop==='componentRef') && !(prop==='validate')) {
            newProps[prop] = props[prop];
            if(prop === "className"){
                let classValue = props[prop];
                if (!classValue.includes("form-select")) {
                    classValue = classValue + " form-select"
                    newProps[prop] = classValue;
                }
            }
        }
    }

    useEffect(() => {
        if (validate !== undefined) {
            if (validate.setMessages !== undefined) {
                validate.setMessages[1](oldSetMessage => {
                    let newMessages = {};
                    if (oldSetMessage !== undefined) {
                        newMessages = { ...oldSetMessage };
                    }
                    newMessages[bindingProperty] = setMessage;
                    return newMessages;
                });
            }
        }    
    }, []);

    useEffect(()=>{
       if(bindingValue === null || bindingValue === ""){
        setSelectValue(multipleValues);
       }else{
        setSelectValue(bindingValue);
       }
    },[bindingValue])

    const getValidateVisibility = ()=>{
        if(validate === undefined) return false;
        if(validate.validateVisibility === undefined) return false;
        return validate.validateVisibility;
    }

    return <Fragment>
    <select 
        {...newProps} 
        value={selectValue} 
        ref ={props.componentRef}      
        onChange={(e) => {
        const value = e.target.value;
        setSelectValue(value);
        const bindingObjectNew = { };
        bindingObjectNew[bindingProperty] = value;     
        bindingMethod(bindingObjectNew, e);
        if (validate !== undefined) {
            const validateObject = { ...bindingObject };
            validateObject[bindingProperty] = value;
            validate.checkValidateResult(validateObject);
        }
    }}
    >
        {options.map((l)=>{
            const style = {};
            if(l.disabled === true){
                style.color = "black";
                style.backgroundColor = "#E3E3E3";
            }
            if(l.description === undefined || l.description.length === 0){
                l.description = l.text;
            }
            if(l.description === undefined){
                l.description = l.value;
            }
            return (<option style={style} disabled={l.disabled} key={l.value} value={l.value}>{l.description}</option>);
        })}
    </select>
    {<span id={bindingProperty + "-Validate"} className="field-validation-valid" style={{color:"red"}}>{getValidateVisibility() && message}</span>}
    </Fragment>;
};
export default BindedSelect;