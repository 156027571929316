import { connectAppStore } from "../../../../../Store/AppStore";
import PageDependencies from "../../../../../Feature/PageDependencies";
import LightingDataTable from "./LightingDataTable";

const LightingModule = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        layoutManager,
        useReducerSmart,
        getAppStore,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        BindedInput,
        FormValidate,
        tryCatchFinally,
        toastPrint,
        getData,
        guid
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const defaultViewModel = {
        ...defaultParams,
    };
    const [extraPostModel, setExtraPostModel] = useState({
        ...defaultParams,
      });
    const [data, setData] = useState([]);
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [zoneHalogenViewModel, dispatchZoneHalogenViewModel, bindingZoneHalogenViewModel] = useReducerSmart({...defaultViewModel, numberOfHalogen: 0, zoneId: "00000000-0000-0000-0000-000000000000", zoneName:"Not selected yet"});
    const [selectedIds, setSelectedIds] = useState({});
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/ExistingHome/LightingModule/Accessor/Get";
    const [reloadForm, setReloadForm] = useState("");

    const validateMethods = {
        powerDensity:(value)=>{ return FormValidate.validateNumberBetween(value, "Power Density", 1, 10);}
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Lighting", appStoreDispatch, undefined, setInit);
    },[]);

    const submit = ()=>{
        formValidate.setValidateVisibility(true);
        const validateResult = formValidate.checkValidateResult(viewModel);
        if(!validateResult) return;
        setLoading({ isLoading: true, text: "Updating Lighting" });
        tryCatchFinally.syncProcess(async()=>{
            const postUrl = "api/ExistingHome/LightingModule/Accessor/Update";
            const response = await getData.processPost(true, postUrl, viewModel);
            toastPrint.printResponse(response);
            loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Lighting", appStoreDispatch);
        }, setLoading, ()=>{
            setReloadForm(guid.newGuid());
        });
    }

    const submitHalogen = ()=>{
        setLoading({ isLoading: true, text: "Updating zone halogen" });
        tryCatchFinally.syncProcess(async()=>{
            const postUrl = "api/ExistingHome/LightingModule/Accessor/UpdateZoneHalogen";
            const response = await getData.processPost(true, postUrl, zoneHalogenViewModel);
            toastPrint.printResponse(response);
            loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Lighting", appStoreDispatch);
        }, setLoading, ()=>{
            setReloadForm(guid.newGuid());
        });
    }

    useEffect(()=>{
        if(selectedIds.selectedItemIds !== undefined){
            if(selectedIds.selectedItemIds.length >= 1){
                const zoneId = selectedIds.selectedItemIds[0];
                const zoneInfos = data.filter(m=>m.id===zoneId);
                if(zoneInfos.length >= 1){
                    const zoneInfo = zoneInfos[0];
                    dispatchZoneHalogenViewModel({numberOfHalogen: zoneInfo.numberOfHalogen, zoneId: zoneInfo.id, zoneName: zoneInfo.zoneName});
                }
            }
        }
    }, [selectedIds])

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-light icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Lighting
                                <div className="page-title-subheading">
                                    {pageProfile.getPageSubTitle(appStoreState)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {init === true &&
                    <>
                        <div className="row">
                    <div className="col-4">
                        <div className="main-card mb-3 card">
                            <div className="card-header">
                                <button type="button" data-toggle="collapse" data-target="#AnnualEnergyConsumptionDiv" aria-expanded="true" aria-controls="AnnualEnergyConsumptionDiv" className="text-left m-0 p-0 btn btn-block">
                                    <h5 className="m-0 p-0 card-title">Annual Energy Consumption</h5>
                                </button>
                            </div>
                            <div data-parent="#AnnualEnergyConsumptionAccordion" id="AnnualEnergyConsumptionDiv" className="collapse show">
                                <div className="card-body">
                                    <div className="separator"></div>
                                    <div className="form-row row">
                                        <div className="col-6">
                                            <div className="position-relative form-group">
                                                <label>Total Consumption (kWh/yr): {parseFloat(viewModel.annualElectricityEnergy + viewModel.annualHalogenConsumption).toFixed(2)}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-6">
                                            <div className="position-relative form-group">
                                                <label>Without Halogen (kWh/yr): {parseFloat(viewModel.annualElectricityEnergy).toFixed(2)}</label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="position-relative form-group">
                                                <label>Halogen (kWh/yr): {parseFloat(viewModel.annualHalogenConsumption).toFixed(2)}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="main-card mb-3 card">
                            <div className="card-header">
                                <button type="button" data-toggle="collapse" data-target="#AnnualEnergyConsumptionDiv" aria-expanded="true" aria-controls="AnnualEnergyConsumptionDiv" className="text-left m-0 p-0 btn btn-block">
                                    <h5 className="m-0 p-0 card-title">Power Density Input</h5>
                                </button>
                            </div>
                            <div data-parent="#AnnualEnergyConsumptionAccordion" id="AnnualEnergyConsumptionDiv" className="collapse show">
                                <div className="card-body">
                                    <div className="separator"></div>
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <div className="position-relative form-group">
                                                <label htmlFor="PowerDensity" id="PowerDensity">Power Density</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "powerDensity", bindingViewModel]}
                                                    id="PowerDensity"
                                                    name="PowerDensity"
                                                    type="number" 
                                                    step="0.1" 
                                                    validate={formValidate}
                                                ></BindedInput>
                                            </div>     
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <button type="button" style={{marginTop:"10px"}} className="btn btn-primary" onClick={submit}>Apply</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="main-card mb-3 card">
                            <div className="card-header">
                                <button type="button" data-toggle="collapse" data-target="#AnnualEnergyConsumptionDiv" aria-expanded="true" aria-controls="AnnualEnergyConsumptionDiv" className="text-left m-0 p-0 btn btn-block">
                                    <h5 className="m-0 p-0 card-title">Zone ({zoneHalogenViewModel.zoneName}) Halogen</h5>
                                </button>
                            </div>
                            <div data-parent="#AnnualEnergyConsumptionAccordion" id="AnnualEnergyConsumptionDiv" className="collapse show">
                                <div className="card-body">
                                    <div className="separator"></div>
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <div className="position-relative form-group">
                                                <label htmlFor="numberOfHalogen" id="numberOfHalogen">Number of  Halogen</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[zoneHalogenViewModel, "numberOfHalogen", bindingZoneHalogenViewModel]}
                                                    id="numberOfHalogen"
                                                    name="numberOfHalogen"
                                                    type="number" 
                                                    step="1" 
                                                    min = "0"
                                                    validate={formValidate}
                                                ></BindedInput>
                                            </div>     
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <button type="button" style={{marginTop:"10px"}} className="btn btn-primary" onClick={submitHalogen}>Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>              
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#HeatingConditionersListDiv" aria-expanded="true" aria-controls="HeatingConditionersListDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Lighting in zones</h5>
                        </button>
                    </div>
                    <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                        <div className="card-body">
                            <LightingDataTable
                                debug = {debug}
                                extraPostModel = {extraPostModel}
                                url="api/ExistingHome/LightingModule/Accessor"
                                tableReload = {reloadForm}
                                setTableReload = {setReloadForm}
                                setSelectedIds = {setSelectedIds}
                                data = {data}
                                setData = {setData}                              
                            ></LightingDataTable>
                        </div>
                    </div>
                </div>
                    </>
                }
            
            </div>
        </Fragment>
    );
}
export default connectAppStore(LightingModule);