
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";
import ConstructionElementDataTable from "../../../Accessor/Construction/ConstructionElementDataTable";
import InfiltrationPenetrationDataTable from "../InfiltrationPenetrationDataTable"
import RoofWindowSkylightDataTable from "../RoofWindowSkylightDataTable"

const RoofDetail = (props) => {
    const {
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        useLocation,
        BaseFormNoHeader,
        BindedInput,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
        cookiesManager,
        FormValidate,
        BindedInputNumber,
        htmlToReactParse,
        useEffectWithPrevious,
        Fragment, useEffect, useState 
    } = PageDependencies();
    const formId = "RoofDetail";
    const [init, setInit] = useState(false);
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const currentUrl = useLocation().pathname;
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [reloadForm, setReloadForm] = useState("");
    let {designVariationVersionName, developmentId, projectId, designVariationId, elementId, zoneId, redirectUrlHex} = defaultParams;
    if(zoneId === undefined) zoneId = emptyGuid;
    const defaultViewModel = {
        ...defaultParams,
        id:emptyGuid,
        zoneId: zoneId,
        selectedItemIds: [elementId]
    };
    const [pitchViewModel, setPitchViewModel] = useState({
      pitchValue: 0,
      pitchValues: [{text: "N", value: 0}]
    });
    const [extraPostModelForConstruction, setExtraPostModelForConstruction] = useState({
      developmentId: developmentId,
      projectId: projectId,
      designVariationId: designVariationId,
      designVariationVersionName: designVariationVersionName,
      constructionType:"Roof"
    });
    const [tableReloadForConstruction, setTableReloadForConstruction] = useState("");
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const defaultCookiesObject =  cookiesManager.getCookies(formId, {elementTableType: "RoofWindowSkylights"}).elementTableType;
    const [elementTableType, setElementTableType] = useState(defaultCookiesObject);
    const postUrl = "api/Element/Accessor/UpdateRoof";
    const getUrl = "api/Element/Accessor/GetRoof";
    const validateMethods = {
      name:(value)=>{return FormValidate.validateTextRequired(value, "Roof name");},
      constructionId:(value)=>{ return value === emptyGuid ? "Must select a construction." : "";},
        grossArea: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Area");},
      azimuth: (value)=>{return FormValidate.validateNumberBetween(value, "Azimuth", 0 , 360);},
      pitch: (value)=>{return FormValidate.validateNumberBetween(value, "Pitch", 0 , 360);}
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading roof", appStoreDispatch, undefined, setInit);
    },[reloadForm]);

    useEffect(()=>{   
      if(init){
        dispatchViewModel({...viewModel, elementId: elementId, id: elementId, selectedItemIds: [elementId]}, ()=>{
          setReloadForm(guid.newGuid());
        });
      }
    },[elementId]);

    useEffectWithPrevious((oldState) => {
      if(oldState === undefined) return;
      const prevpitchInputMethodId = oldState[0];
      if(prevpitchInputMethodId !== undefined && viewModel.pitchInputMethodId !== undefined){
          if(viewModel.dwellingType !== "New"){
            if (viewModel.pitchInputMethodId == 2) {
              dispatchViewModel({...viewModel, pitch: 5});
            }
          }
      }
    },[viewModel.pitchInputMethodId]);

    const calculateAzimuth = (value) =>{
      if(value === undefined ) value = 0;
      if(value === null ) value = 0;
      if(value === "" ) value = 0;
      value = Number(value);
      const directions = ["North", "Northeast", "East", "Southeast", "South", "Southwest", "West", "Northwest"];
      const index = Math.round(value / 45) % 8;
      return directions[index];
    }

    const generateStayUrl = (submitResponse)=>{
      let redirectUrl = currentUrl;
      if(elementId === emptyGuid){
        //ElementAccessor/Detail/Roof/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:redirectUrlHex
        redirectUrl =  "/ElementAccessor/Detail/Roof/" + designVariationVersionName + "/" + developmentId +"/"+projectId+"/"+designVariationId+"/"+zoneId+"/"+submitResponse.elementId+"/"+redirectUrlHex;
      }
      return redirectUrl;
    }

    const handleTagClick = (elementTableType)=>{
      setElementTableType(elementTableType);
      cookiesManager.setCookies(formId, {elementTableType: elementTableType});
    }

    const generateTagClass = (elementType) =>{
      let className = elementType === elementTableType ? "active" : "";
      className = "nav-link show " + className;
      return className;
    }

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {debug && <p>{JSON.stringify(viewModel.zoneId)}</p>}
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <div className="page-title-icon">
                    <i className="pe-7s-safe icon-gradient bg-tempting-azure"></i>
                  </div>
                  <div>
                    Roof
                    <div className="page-title-subheading">
                      {pageProfile.getPageSubTitle(appStoreState)}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-6 col-lg-12">
                <div className="main-card mb-3 card">
                  <BaseFormNoHeader
                      id="RoofDetail"
                      submitButton={{ postUrl: postUrl, redirectUrl: viewModel.redirectUrl, text: "Save and close"}}
                      submitButtonAndStay={{ text:"Save", postUrl: postUrl, redirectMethod: (submitResponse)=>{return generateStayUrl(submitResponse)}}}
                      cancelButton={{ postUrl: postUrl, redirectUrl: viewModel.redirectUrl }}
                      validate={formValidate}
                      setReload = {setReloadForm}
                      loading={loading}
                      init={init}
                      setLoading={setLoading}
                      data={viewModel}
                      cardTitle={"Roof in " + viewModel.zoneName}
                    >
                      <div className="form-row row">
                        <div className="col-6">
                          <div className="position-relative form-group">
                            <label htmlFor="Name">Name *</label>
                            <BindedInput
                              className="form-control"
                              id="Name"
                              name="Name"
                              type="text"
                              validate={formValidate}
                              binding={[viewModel, "name", bindingViewModel]}
                            ></BindedInput>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="position-relative form-group">
                            <label htmlFor="ZoneId">Zone *</label>
                            <BindedSelect
                              className="form-control"
                              id="ZoneId"
                              name="ZoneId"
                              options="zones"
                              binding={[viewModel, "zoneId", bindingViewModel]}
                            ></BindedSelect>
                          </div>
                        </div>
                      </div>
                      <div className="form-row row">
                        <div className="col-12">
                          <div className="position-relative form-group">
                            <label htmlFor="RoofConstructionId">
                              Construction *
                            </label>
                            <BindedSelect
                              className="form-control"
                              id="RoofConstructionId"
                              name="RoofConstructionId"
                              options="constructions"
                              validate={formValidate}
                              binding={[
                                viewModel,
                                "constructionId",
                                bindingViewModel,
                              ]}
                            ></BindedSelect>
                          </div>
                        </div>
                      </div>
                      {viewModel.dwellingType === "New" && <>
                      <div className="form-row row">
                        <div className="col-3">
                          <div className="position-relative form-group">
                            <BindedInputNumber inputId="RoofGrossArea" label={htmlToReactParse("Area (m<sup>2</sup>) *")} min="0" minFractionDigits={0} maxFractionDigits={2} binding={[viewModel, "grossArea", bindingViewModel]} validate = {formValidate}/>
                          </div>
                        </div>
                        {/* <div className="col-3">
                          <div className="position-relative form-group">
                            <BindedInputNumber inputId="RoofHeight" label={htmlToReactParse("Height *")} min="0" binding={[viewModel, "height", bindingViewModel]} validate = {formValidate}/>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="position-relative form-group">
                            <BindedInputNumber inputId="RoofLength" label={htmlToReactParse("Length *")} min="0" binding={[viewModel, "length", bindingViewModel]} validate = {formValidate}/>
                          </div>
                        </div> */}
                        <div className="col-3">
                          <div className="position-relative form-group">
                            <label htmlFor="RoofAzimuth">
                              Azimuth{" "} *
                              <span>
                                (
                                {viewModel.azimuth === "" ||
                                viewModel.azimuth === null ||
                                viewModel.azimuth === undefined
                                  ? "0"
                                  : viewModel.azimuth}
                              </span>
                              <sup>o</sup>{" "}
                              <span>{calculateAzimuth(viewModel.azimuth)})</span>
                            </label>
                            <BindedInput
                              className="form-control"
                              min="0"
                              max="360"
                              id="RoofAzimuth"
                              name="RoofAzimuth"
                              type="number"
                              validate={formValidate}
                              binding={[viewModel, "azimuth", bindingViewModel]}
                            ></BindedInput>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="position-relative form-group">
                            <label htmlFor="RoofPitch">
                              Pitch (&#176;) *
                            </label>
                            <BindedInput
                              className="form-control"
                              min="0"
                              max="360"
                              id="RoofPitch"
                              name="RoofPitch"
                              type="number"
                              validate={formValidate}
                              binding={[viewModel, "pitch", bindingViewModel]}
                            ></BindedInput>
                          </div>
                        </div>
                        {/* <div className="col-1">
                          <div className="position-relative form-group">
                            <label htmlFor="pitchValue">
                            &nbsp;
                            </label>
                            <BindedSelect
                              className="form-control"
                              id="pitchValue"
                              name="pitchValue"
                              options="pitchValues"
                              validate={formValidate}
                              binding={[pitchViewModel, "pitchValue", bindingViewModel]}
                            ></BindedSelect>
                          </div>
                        </div> */}
                        <div className="col-3">
                          <div className="position-relative form-group">
                            <label htmlFor="RoofExposureTypeId">
                              Exposure *
                            </label>
                            <BindedSelect
                              className="form-control"
                              id="RoofExposureTypeId"
                              name="RoofExposureTypeId"
                              options="exposureTypes"
                              validate={formValidate}
                              binding={[viewModel, "exposureTypeId", bindingViewModel]}
                            ></BindedSelect>
                          </div>
                        </div>
                      </div>
                      </>}
                      {viewModel.dwellingType !== "New" && <>
                        <div className="form-row row">
                          <div className="col-4">
                            <div className="position-relative form-group">
                              <BindedInputNumber inputId="RoofGrossArea" label={htmlToReactParse("Area (m<sup>2</sup>) *")} min="0" minFractionDigits={0} maxFractionDigits={2} binding={[viewModel, "grossArea", bindingViewModel]} validate = {formValidate}/>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="position-relative form-group">
                              <label htmlFor="RoofAzimuth">
                                Azimuth{" "} *
                                <span>
                                  (
                                  {viewModel.azimuth === "" ||
                                  viewModel.azimuth === null ||
                                  viewModel.azimuth === undefined
                                    ? "0"
                                    : viewModel.azimuth}
                                </span>
                                <sup>o</sup>{" "}
                                <span>{calculateAzimuth(viewModel.azimuth)})</span>
                              </label>
                              <BindedInput
                                className="form-control"
                                min="0"
                                max="360"
                                id="RoofAzimuth"
                                name="RoofAzimuth"
                                type="number"
                                validate={formValidate}
                                binding={[viewModel, "azimuth", bindingViewModel]}
                              ></BindedInput>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="position-relative form-group">
                              <label htmlFor="RoofExposureTypeId">
                                Exposure *
                              </label>
                              <BindedSelect
                                className="form-control"
                                id="RoofExposureTypeId"
                                name="RoofExposureTypeId"
                                options="exposureTypes"
                                validate={formValidate}
                                binding={[viewModel, "exposureTypeId", bindingViewModel]}
                              ></BindedSelect>
                            </div>
                          </div>
                        </div>
                        <div className="form-row row">
                          <div className="col-4">
                            <div className="position-relative form-group">
                              <label htmlFor="RoofExposureTypeId">
                                Pitch Input Method
                              </label>
                              <BindedSelect
                                className="form-control"
                                id="RoofPitchInputMethodId"
                                name="RoofPitchInputMethodId"
                                options="pitchInputMethodTypes"
                                validate={formValidate}
                                binding={[viewModel, "pitchInputMethodId", bindingViewModel]}
                              ></BindedSelect>
                            </div>
                          </div>
                          {viewModel.pitchInputMethodId == 2 &&
                          <div className="col-4">
                            <div className="position-relative form-group">
                              <label htmlFor="RoofExposureTypeId">
                                Pitch (simplified)
                              </label>
                              <BindedSelect
                                className="form-control"
                                id="RoofPitchTypeId"
                                name="RoofPitchTypeId"
                                options={[{text:"Flat (0 - <10 &#176;)", value:5}, {text: "Moderate (10 - <35 &#176;)", value:22}, {text:"Steep (&#8805; 35 &#176;)", value:40}].map((m)=>{return  {text: htmlToReactParse(m.text), value: m.value};})}
                                validate={formValidate}
                                binding={[viewModel, "pitch", bindingViewModel]}
                              ></BindedSelect>
                            </div>
                          </div>}
                          <div className="col-4">
                            <div className="position-relative form-group">
                              {viewModel.pitchInputMethodId == 2 && <>
                              <label htmlFor="RoofPitch">
                                Pitch (&#176;) *
                              </label>
                              <BindedInput
                                className="form-control"
                                min="0"
                                max="360"
                                id="RoofPitch"
                                name="RoofPitch"
                                type="number"
                                validate={formValidate}
                                binding={[viewModel, "pitch", bindingViewModel]}
                                disabled={viewModel.pitchInputMethodId == 2}
                              ></BindedInput></>}
                              {viewModel.pitchInputMethodId != 2 &&
                              <BindedInputNumber inputId="RoofPitch" label={htmlToReactParse("Pitch (&#176;) *")} min="0" max="360" binding={[viewModel, "pitch", bindingViewModel]} validate = {formValidate} />}
                            </div>
                          </div>
                        </div>
                      </>}
                  </BaseFormNoHeader>
                </div>
              </div>
              {init === true &&
                   <div className="col-xl-6 col-lg-12">
                   <div className="row">
                     <div className="main-card mb-3 card">
                       <div className="card-header">
                         <button type="button" data-toggle="collapse" data-target="#ConstructionsCollapsibleDiv" aria-expanded="true" aria-controls="ConstructionsCollapsibleDiv" className="text-left m-0 p-0 btn btn-block">
                           <h5 className="m-0 p-0 card-title">{props.elementType} Constructions</h5>
                         </button>
                       </div>
                       <div data-parent="#ConstructionsAccordion" id="ConstructionsCollapsibleDiv" className="collapse show">
                         <div className="card-body">
                           <div className="separator"></div>
                             <ConstructionElementDataTable
                                 tableId = {"RoofElement-fd8f0ecc-d6f2-46fb-9f96-39919b821cfa"}
                                 tableReload={tableReloadForConstruction}
                                 setTableReload={setTableReloadForConstruction}
                                 extraPostModel = {extraPostModelForConstruction}
                                 constructionType = "Roof"
                                 redirectUrl = {currentUrl}
                                 debug = {debug}
                             >
                             </ConstructionElementDataTable>
                         </div>
                       </div>
                     </div>
                     {elementId !== emptyGuid &&
                           <div className="main-card mb-3 card">
                               <div className="card-header">
                                   <ul className="nav nav-justified">
                                       <li className="nav-item" onClick={() => handleTagClick("RoofWindowSkylights")}><a className={generateTagClass("RoofWindowSkylights")}>Roof windows/Skylights</a></li>
                                       <li className="nav-item" onClick={() => handleTagClick("InfiltrationPenetrations")}><a className={generateTagClass("InfiltrationPenetrations")}>Infiltration penetrations</a></li>
                                   </ul>
                               </div>
                               <div data-parent="#ConstructionsAccordion" id="ConstructionsCollapsibleDiv" className="collapse show">
                                   <div className="card-body">
                                       <div className="separator"></div>
                                       {elementTableType === 'InfiltrationPenetrations' &&
                                           <InfiltrationPenetrationDataTable
                                               id="InfiltrationPenetration_c5ed3a42-15a2-46aa-ab5c-ac8ba6a9ea17"
                                               elementType="Roof"
                                               elementId={elementId}
                                               designVariationVersionName={designVariationVersionName}
                                               developmentId={developmentId}
                                               projectId={projectId}
                                               designVariationId={designVariationId}
                                               zoneId={zoneId}
                                           ></InfiltrationPenetrationDataTable>
                                       }
                                       {elementTableType === 'RoofWindowSkylights' &&
                                           <RoofWindowSkylightDataTable
                                               id="RoofWindowSkylight_2e5064b2-728d-4d93-9979-9f3182b89e4a"
                                               roofId={elementId}
                                               designVariationVersionName={designVariationVersionName}
                                               developmentId={developmentId}
                                               projectId={projectId}
                                               designVariationId={designVariationId}
                                               zoneId={zoneId}
                                           ></RoofWindowSkylightDataTable>
                                       }
                                               
                                   </div>
                               </div>
                           </div>
                       }
                   </div>
                 </div>
              }
         
            </div>
          </div>
        </Fragment>
    );
}
export default connectAppStore(RoofDetail);