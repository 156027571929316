import { useContext } from "react";
import PageDependencies from "../../../Feature/PageDependencies";
import { SharedConstructionsContext } from "./SharedConstructionsContext";
const ConstructionDataTable = (props)=>{
  const debug = props.debug === undefined ? false : props.debug;
    const { 
        tryCatchFinally,
        stringCoding,
        getData,
        guid,
        toastPrint,
        useNavigate,
        Fragment, useState,
        dataExtension,
        ServerSideDataTable,
        ModalDatatableItem,
    } = PageDependencies();
    const emptyGuid = guid.emptyGuid();
    const nav = useNavigate();
    const [modal, setModal] = useState({
        isOpen: false,
        title: "",
        message: "",
        comfirmButtonStyle: "",
        comfirmButtonText: "Comfirm",
        handleComfirm: () => {},
    });
    const [loading, setLoading] = useState({isLoading:false,text:""});
    const [data, setData] = useState([]);
    const tableId = props.tableId;
    const tableReload = props.tableReload;
    const redirectUrl = props.redirectUrl;
    const setTableReload = props.setTableReload;
    const extraPostModel = props.extraPostModel;
    const setSelectedIds = props.setSelectedIds;
    const designVariationVersionName = extraPostModel.designVariationVersionName;
    const developmentId = extraPostModel.developmentId;
    const projectId = extraPostModel.projectId;
    const designVariationId = extraPostModel.designVariationId;
    const columns = [
        { name: "name", text: "Name", width: "50%" },
        { name: "type", text: "Type", width: "25%" },
        { name: "area", text: "Area", width: "25%" }
      ];

    const {copiedItem, setCopiedItem} = useContext(SharedConstructionsContext);

    const generateBaseUrl = (type, isMultiple = false)=>{
        const multiple =  isMultiple ? "Multiple" : "";
        return "/ConstructionAccessor/Detail" + multiple + "/" + type + "/" + designVariationVersionName + "/" + developmentId + "/" + projectId + "/" + designVariationId + "/"  + emptyGuid + "/" + stringCoding.encode(redirectUrl);
    }

    const handleImport = async (type) => {
      await tryCatchFinally.asyncProcess(async () => {
            setLoading({ isLoading: true, text: "Importing constructions" });
            const selectResponse = await getData.processPost(
                true,
                "api/Construction/Accessor/Import" + type,
                { designVariationVersionName: designVariationVersionName, projectId: projectId, developmentId: developmentId }
            );
            toastPrint.printResponse(selectResponse);
            setTableReload(guid.newGuid());
        }, setLoading);
    }

    const handleCopy = () => {
      const selectedItemDic = dataExtension.getSelectedRowsDic(data, "type");
      setCopiedItem(selectedItemDic);
    }

    const handlePaste = async () => {
      await tryCatchFinally.asyncProcess(async () => {
          // const selectedItemDic = dataExtension.getSelectedRowsDic(data, "type");
          setLoading({ isLoading: true, text: "Duplicating constructions" });
          const selectResponse = await getData.processPost(
              true,
              "api/Construction/Accessor/CopyConstructions",
              { selectedItemDic: copiedItem, designVariationVersionName: designVariationVersionName, projectId: projectId, developmentId: developmentId}
          );
          toastPrint.printResponse(selectResponse);
          setTableReload(guid.newGuid());
      }, setLoading);
  }

    const contextMenu = {
      items: {
        new: {
          name: "New",
          icon: "add",
          items: {
            createCeiling: {
              name: "Ceiling",
              icon: "",
              url: generateBaseUrl("Ceiling", false),
              isNavOption: true
            },
            createFloor: {
              name: "Floor",
              icon: "",
              url: generateBaseUrl("Floor", false),
              isNavOption: true
            },
            createDoor: {
              name: "Door",
              icon: "",
              url: generateBaseUrl("Door", false),
              isNavOption: true
            },
            createExternalWall: {
              name: "External Wall",
              icon: "",
              url: generateBaseUrl("ExternalWall", false),
              isNavOption: true
            },
            createInternalWall: {
                name: "Internal Wall",
                icon: "",
                url: generateBaseUrl("InternalWall", false),
                isNavOption: true
              },
              createRoof: {
                name: "Roof",
                icon: "",
                url: generateBaseUrl("Roof", false),
                isNavOption: true
              },
              createRoofWindow: {
                name: "Roof Window",
                icon: "",
                url: generateBaseUrl("RoofWindow", false),
                isNavOption: true
              },
              createWindow: {
                name: "Window",
                icon: "",
                url: generateBaseUrl("Window", false),
                isNavOption: true
              },
              createSkylight: {
                name: "Skylight",
                icon: "",
                url: generateBaseUrl("Skylight", false),
                isNavOption: true
              },
          },
        },
        separator: "---------",
        edit: {
            name: "Edit",
            icon: "edit",
            defaultAction: false,
              disabled: function () {
              return dataExtension.getSelectedRowDataIds(data).length !== 1;
            },
          },
          delete: {
            name: "Delete",
            icon: "delete",
            url: "",
            defaultAction: false,
            disabled: function () {
              return dataExtension.getSelectedRowDataIds(data).length === 0;
            },
          },
          duplicate: {
            name: "Duplicate",
            icon: "copy",
            url: "",
            defaultAction: false,
            disabled: function () {
              return dataExtension.getSelectedRowDataIds(data).length === 0;
            },
          },
          copy: {
            name: "Copy",
            icon: "copy",
            url: "",
            defaultAction: false,
            disabled: function () {
              return dataExtension.getSelectedRowDataIds(data).length === 0;
            },
          },
          paste: {
            name: "Paste",
            icon: "paste",
            url: "",
            defaultAction: false,
            disabled: function () {
              return copiedItem.length === 0;
            },
          },
          separator: "---------",
          import: {
            name: "Import from library",
            icon: "add",
            items: {
              importFloorCeiling: {
                name: "Floor/Ceiling",
                icon: "",
                url: "",
                defaultAction: false,
              },
              importDoor: {
                name: "Door",
                icon: "",
                url: "",
                defaultAction: false,
              },
              importExternalWall: {
                name: "External Wall",
                icon: "",
                url: "",
                defaultAction: false,
              },
              importInternalWall: {
                  name: "Internal Wall",
                  icon: "",
                  url: "",
                  defaultAction: false,
                },
                importRoof: {
                  name: "Roof",
                  icon: "",
                  url: "",
                  defaultAction: false,
                },
                importRoofWindow: {
                  name: "Roof Window",
                  icon: "",
                  url: "",
                  defaultAction: false,
                },
                importWindow: {
                  name: "Window",
                  icon: "",
                  url: "",
                  defaultAction: false,
                },
                importSkylight: {
                  name: "Skylight",
                  icon: "",
                  url: "",
                  defaultAction: false,
                },
            },
        }
      },
      callBack: (
        contextMenuCallBackKey,
        contextMenuCallBackElement,
        contextMenuCallBackTableRoadData,
        localtion
      ) => {
        if (contextMenuCallBackKey === "edit") {
          const selectedZoneIds = dataExtension.getSelectedRowDataIds(data);
          if(selectedZoneIds.length == 1){
              nav("/ConstructionAccessor/Detail/" + dataExtension.getSelectedRows(data)[0].type.replace(" ", "").replace("/", "") + "/" + designVariationVersionName + "/" + developmentId + "/"+ projectId + "/" + designVariationId + "/" + selectedZoneIds[0] + "/" + stringCoding.encode(redirectUrl));
          }else{
             nav("/ConstructionAccessor/MultipleDetail/" + designVariationVersionName + "/" + developmentId + "/" + projectId + "/" + designVariationId + "/" + stringCoding.encodeObject(selectedZoneIds));
          }
        }
          if (contextMenuCallBackKey === "delete") {
            setModal({
                ...modal,
                isOpen: true,
                title: "Delete constructions",
                message:
                    "Are sure to delete " +
                    dataExtension.getSelectedRowDataIds(data).length +
                    " selected constructions?",
                comfirmButtonStyle: "danger",
                comfirmButtonText: "Confirm to delete",
                handleComfirm: handleDeleteComfirm,
            });
        }
          if (contextMenuCallBackKey === "duplicate") {
             setModal({
                ...modal,
                isOpen: true,
                title: "Duplicate constructions",
                message:
                    "Are sure to duplicate " +
                    dataExtension.getSelectedRowDataIds(data).length +
                    " selected constructions?",
                comfirmButtonStyle: "primary",
                comfirmButtonText: "Confirm to duplicate",
                handleComfirm: handleDuplicatedComfirm,
            });
        }
        if (contextMenuCallBackKey === "importFloorCeiling"){
          handleImport("FloorCeiling");
        }
        if (contextMenuCallBackKey === "importDoor"){
          handleImport("Door");
        }
        if (contextMenuCallBackKey === "importExternalWall"){
          handleImport("ExternalWall");
        }
        if (contextMenuCallBackKey === "importInternalWall"){
          handleImport("InternalWall");
        }
        if (contextMenuCallBackKey === "importRoof"){
          handleImport("Roof");
        }
        if (contextMenuCallBackKey === "importRoofWindow"){
          handleImport("RoofWindow");
        }
        if (contextMenuCallBackKey === "importWindow"){
          handleImport("Window");
        }
        if (contextMenuCallBackKey === "importSkylight"){
          handleImport("Skylight");
        }
        if (contextMenuCallBackKey === "copy") {
          handleCopy();
        }
        if (contextMenuCallBackKey === "paste") {
          handlePaste();
        }
      },
    };

    const actionOnDoubleClickRow = (model, newData) =>{
      nav("/ConstructionAccessor/Detail/" + model.type.replace(" ","").replace("/","") + "/" + designVariationVersionName + "/" + developmentId + "/" + projectId + "/" + designVariationId + "/" + model.id + "/" + stringCoding.encode(redirectUrl)) ;
    }

    const handleDeleteComfirm = async () => {
        await tryCatchFinally.asyncProcess(async () => {
            const selectedItemDic = dataExtension.getSelectedRowsDic(data, "type");
            setLoading({ isLoading: true, text: "Deleting constructions" });
            const selectResponse = await getData.processPost(
                true,
                "api/Construction/Accessor/DeleteConstructions",
                { selectedItemDic: selectedItemDic, designVariationVersionName: designVariationVersionName, projectId: projectId, developmentId: developmentId }
            );
            toastPrint.printResponse(selectResponse);
            setTableReload(guid.newGuid());
        }, setLoading);
    }

    const handleDuplicatedComfirm = async () => {
        await tryCatchFinally.asyncProcess(async () => {
            const selectedItemDic = dataExtension.getSelectedRowsDic(data, "type");
            setLoading({ isLoading: true, text: "Duplicating constructions" });
            const selectResponse = await getData.processPost(
                true,
                "api/Construction/Accessor/CopyConstructions",
                { selectedItemDic: selectedItemDic, designVariationVersionName: designVariationVersionName, projectId: projectId, developmentId: developmentId }
            );
            toastPrint.printResponse(selectResponse);
            setTableReload(guid.newGuid());
        }, setLoading);
    }

    return(<Fragment>
        {debug && <p>Selected items: {JSON.stringify(dataExtension.getSelectedRowDataIds(data))}</p>}
        {debug && <p>Copied items: {JSON.stringify(copiedItem)}</p>}
            <ModalDatatableItem
                modal={modal}
                setModal={setModal}
            ></ModalDatatableItem>
           <ServerSideDataTable
                id={tableId}
                location="Constructions"
                data={data}
                setData={setData}
                reload={tableReload}
                columns={columns}
                actionOnDoubleClickRow = {actionOnDoubleClickRow}
                allowSelect
                allowSelectMultiple
                dataSource={{ isAuth: true, url: "api/Construction/Accessor" }}
                contextMenu={contextMenu}
                extraPostModel={extraPostModel}
                setSelectedItemIds = {setSelectedIds}
            >
            </ServerSideDataTable>
    </Fragment>);
}
export default ConstructionDataTable;