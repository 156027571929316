
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import BridgeMaterialDetail from "./BridgeMaterialDetail";
import React from "react";
import TooltipTemplate from "../../../../Layout/AppNav/TooltipTemplate";

const InternalExternalConstructionDetail = (props)=>{
    const { 
        Fragment, 
        useEffect, 
        useRef, 
        useState ,
        stringCoding,
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        BaseForm,
        BindedInput,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
        FormValidate,
        dataExtension,
        DataTableFancyTree,
        DropDragDataTable,
        DragDropContext, 
    } = PageDependencies();

    const debug = layoutManager.Layout_GetDebugModel(props);
    const constructionLayers = useRef();
    const redirectUrl = stringCoding.decode(useParams().redirectUrlHex);
    const projectId = useParams().projectId;
    const designVariationId = useParams().designVariationId;
    const developmentId = useParams().developmentId;
    const constructionId = useParams().constructionId;
    const designVariationVersionName = useParams().designVariationVersionName;
    const typeName = useParams().typeName;
    const defaultViewModel = {
        id:constructionId,
        projectId: projectId,
        typeName: typeName,
        designVariationId: designVariationId,
        designVariationVersionName: designVariationVersionName,
        developmentId: developmentId,
        constructionId: constructionId,
        selectedItemIds:[constructionId],
        constructionLayersJsonString: "[]",
        constructionLayers: [],
        category:"",
        constructionTypeName: "Loading",
        isLibrary: props.isLibrary === true
      };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const postUrl = "api/Construction/Accessor/Update";
    const getUrl = "api/Construction/Accessor/Get";
    const [init, setInit] = useState(false);
    //materials
    const tableId = "table-278d5eea-ffac-492c-a45d-2778d904fb19";
    const treeId = "tree-278d5eea-ffac-492c-a45d-2778d904fb19";
    const [dataTree, setDataTree] = useState([]);

    //bridge material
    const [isOpen, setIsOpen] = useState(false);
    const [bridgeViewModel, setBridgeViewModel] = useState({});
    const [bridgeIndexModal, setBridgeIndexModal] = useState(0);

    //construction layers
    const [dataTable, setDataTable] = useState ([]);
    const draggableTablesDic = {};
    draggableTablesDic[tableId] = { data: dataTable, setData: setDataTable };
    draggableTablesDic[treeId] = { data: dataTree, setData: setDataTree };
    const validateMethods = {
      name:(value)=>{ return FormValidate.validateTextRequired(value, "Construction name"); },
      externalAbsorptance:(value)=>{ return FormValidate.validateNumberBetween(value, "External absorptance", 0 , 100); },
      internalAbsorptance:(value)=>{ return FormValidate.validateNumberBetween(value, "Internal absorptance", 0 , 100); },
      firstAbsorptance:(value)=>{ return FormValidate.validateNumberBetween(value, "First absorptance", 0 , 100); },
      lastAbsorptance:(value)=>{ return FormValidate.validateNumberBetween(value, "Last absorptance", 0 , 100); },
      constructionLayersJsonString: (value) => { return value === "[]" ? "Construction at least have one layer." : ""}
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);
    const columns = [
      {
        name: "position",
        text: "Position",
        width: "5%",
        style: { textAlign: "center" },
      },
      { name: "name", text: "Material", width: "30%" },
      {
        name: "thickness",
        text: "Thickness (mm)",
        width: "5%",
        generate: thicknessGenerate,
      },
      { name: "bridge1", text: "Bridge1", width: "15%", generate: operationBridge1Generate },
      { name: "bridge2", text: "Bridge2", width: "15%", generate: operationBridge2Generate },
      //TODO related to work item 1369, wait fix in future
      //{ name: "resU", text: "R Layer (up)", width: "8%" },
      //{ name: "resD", text: "R Layer (down)", width: "8%" },
      {
        name: "operation",
        text: "Operation",
        width: "4%",
        generate: operationGenerate,
      },
    ];

      function thicknessGenerate(model, cloumn, index, style, onColumnValueChangeEvent){
        if (!model.thickFix) {
          const inputId = model.id+tableId+cloumn.name;
          const ref = React.createRef();
          return (
            <input 
            style={{ background: "transparent", border: "none"}}
            type="number"
            id={inputId}
            ref = {ref}
            value={model[cloumn.name]}
            onChange={(e)=> onColumnValueChangeEvent(e, cloumn.name, processThickness)}
            maxLength='4'
          ></input>
          );
        } else {
          return (
            <Fragment>
              {model[cloumn.name]}
            </Fragment>
          );
        }
      };

      function deleteHandler(e){
        const id =  e.currentTarget.getAttribute("id");
        setDataTable((oldData)=>{
          let newData = dataExtension.deleteById(oldData,id);
          newData = calculatePosition(newData);
          return newData;
        });
      }

      function bridgeEditHandler(e){
        const id =  e.currentTarget.getAttribute("id");
        const bridgeIndex =  e.currentTarget.getAttribute("bridgeindex");
        const index = parseInt(bridgeIndex);
        setBridgeIndexModal(index);
        for(let i = 0; i < dataTable.length; i++){
          if(dataTable[i].id == id){
            let bridge = {};
            if(index == 1){
              bridge = dataTable[i].bridge1Data;
            }else{
              bridge = dataTable[i].bridge2Data;
            }
            setBridgeViewModel(bridge);
          }
        }
        setIsOpen(true);
      }

      function bridgeDeleteHandler(e){
        const bridgeIndex =  e.currentTarget.getAttribute("bridgeindex");
        const index = parseInt(bridgeIndex);
        const id =  e.currentTarget.getAttribute("id");
        setDataTable((oldData)=>{
          let newData = [...oldData];
          for(let i = 0; i < newData.length; i++){
            if(newData[i].id == id){
              newData[i].bridgeMaterials = [];
              if(index == 1){
                newData[i].bridge1Data = null;
              }
              else
              {
                newData[i].bridge2Data = null;
              }
              if(newData[i].bridge1Data !== null){
                newData[i].bridgeMaterials.push(newData[i].bridge1Data);
              }
              if(newData[i].bridge2Data !== null){
                newData[i].bridgeMaterials.push(newData[i].bridge2Data);
              }
            }
          }
          return newData;
        });
      }

      function bridgeAddHandler(e){
        const bridgeIndex =  e.currentTarget.getAttribute("bridgeindex");
        const id =  e.currentTarget.getAttribute("id");
        const index = parseInt(bridgeIndex);
        const floorCeilingType = (viewModel.typeName.includes("Floor") || viewModel.typeName.includes("Ceiling")) ? 1 : -999;
        let sfw = 0;
        let nfw = 0;
        if(viewModel.typeName == "Roof"){
          sfw = 75;
          nfw = 75;
        }
        if(viewModel.typeName == "ExternalWall" || viewModel.typeName == "InternalWall"){
          sfw = 40;
          nfw = 40;
        }
        if(floorCeilingType > 0){
          sfw = 50;
          nfw = 50;
        }
        let bridge = {
          airGapThickness: 0,
          airGapWidth: 0,
          airGapTypeId: 0,
          baseMetalThickness: 0.75,
          bridgeMaterialFrameTypeId: 2,
          bridgeMaterialIndex: 18,
          constructionLayerId: id,
          floorCeilingConstructionTypeId: floorCeilingType,
          fraction: 0,
          hasAirGap: false,
          hasMultipleDwellings: false,
          hasThermalBreakApplied: false,
          noggingDepth: 0,
          noggingFlangeWidth: nfw,
          noggingSpacing: 0,
          noggingWidth: 0,
          studDepth: 0,
          studFlangeWidth: sfw,
          studSpacing: 0,                 
          studWidth: 0,
          thermalBreak: 0,
          sequenceNumber: bridgeIndex,
          bridgeMaterialId: guid.newGuid()
        };
        setBridgeIndexModal(index);
        setBridgeViewModel(bridge);
        setIsOpen(true);
      }

      function bridgeSubmitHandler(bridgeViewModel, bridgeValidate, bridgeIndex){
        const fixedModel = {...bridgeViewModel};
        delete fixedModel.bridgeMaterialAirGapTypes;
        delete fixedModel.bridgeMaterialFrameTypes;
        delete fixedModel.bridgeMaterials;
        delete fixedModel.floorCeilingConstructionTypes;
        const validateResult = bridgeValidate.checkValidateResult(fixedModel);
        if (validateResult){
          const newViewModel = {...viewModel};
            const layerId = fixedModel.constructionLayerId;
          for(let i = 0; i< newViewModel.constructionLayers.length; i++){
            if(newViewModel.constructionLayers[i].id == layerId){
              if(parseInt(bridgeIndex) == 1){
                  newViewModel.constructionLayers[i].bridge1Data = { ...fixedModel };
              }else{
                  newViewModel.constructionLayers[i].bridge2Data = { ...fixedModel };;
              }
              newViewModel.constructionLayers[i].bridgeMaterials = [];
              if(newViewModel.constructionLayers[i].bridge1Data !== null){
                newViewModel.constructionLayers[i].bridgeMaterials.push(newViewModel.constructionLayers[i].bridge1Data);
              }
              if(newViewModel.constructionLayers[i].bridge2Data !== null){
                newViewModel.constructionLayers[i].bridgeMaterials.push(newViewModel.constructionLayers[i].bridge2Data);
              }
            }
          }
          dispatchViewModel(newViewModel);
          setIsOpen(false);
        }
      }

      function operationGenerate(model, cloumn, index, style, onColumnValueChangeEvent){
        return(<FontAwesomeIcon size="lg" type="button" id={model.id} onClick={deleteHandler} style={{color:"red", size: "lg"}} icon={faTrash} />);
      }

      function operationBridge1Generate(model, cloumn, index, style, onColumnValueChangeEvent){
        if(model.bridge1Data !== null && model.bridge1Data !== undefined){
          return(<Fragment>
            <FontAwesomeIcon size="lg" type="button" bridgeindex = {1} id={model.id} onClick={bridgeEditHandler} style={{color:"blue", size: "lg", marginRight: "5px"}} icon={faEdit} />
            <FontAwesomeIcon size="lg" type="button" bridgeindex = {1} id={model.id} onClick={bridgeDeleteHandler} style={{color:"red", size: "lg"}} icon={faTrash} />
            </Fragment>);
        }else{
          return(<FontAwesomeIcon size="lg" type="button" bridgeindex = {1} id={model.id} onClick={bridgeAddHandler} style={{color:"green", size: "lg"}} icon={faPlus} />);
        }
      }

      function operationBridge2Generate(model, cloumn, index, style, onColumnValueChangeEvent){
        if(model.bridge2Data !== null && model.bridge2Data !== undefined){
          return(<Fragment>
            <FontAwesomeIcon size="lg" type="button" bridgeindex = {2} id={model.id} onClick={bridgeEditHandler} style={{color:"blue", size: "lg", marginRight: "5px"}} icon={faEdit} />
            <FontAwesomeIcon size="lg" type="button" bridgeindex = {2} id={model.id} onClick={bridgeDeleteHandler} style={{color:"red", size: "lg"}} icon={faTrash} />
            </Fragment>);
        }else{
          return(<FontAwesomeIcon size="lg" type="button" bridgeindex = {2} id={model.id} onClick={bridgeAddHandler} style={{color:"green", size: "lg"}} icon={faPlus} />);
        }
      }

      function processThickness(valueO){
        let value = valueO;
        if(value.length ==0){
            value = 0;
        }else{
          value = parseInt(value.toString());
        }
        return value;
      }

      const OnDragEndOperation = (result, callBack = undefined) => {
        try {
          const sourceTableId = result.source.droppableId;
          const sourceRowIndex = result.source.index;
          const sourceRowId = result.draggableId;
          if (result.destination !== null) {
            const destinationTableId = result.destination.droppableId;
            const destinationRowIndex = result.destination.index;
            const destinationData = draggableTablesDic[destinationTableId].data;
            const destinationSetData = draggableTablesDic[destinationTableId].setData;
            //tree to table
            if (
              sourceTableId.includes("tree") &&
              destinationTableId.includes("table")
            ) {
              const materialId = sourceRowId;
              const material = dataTree.filter((m) => {
                return m.id == materialId;
              })[0];
              const tableMaterial = {
                position: "",
                constructionId : constructionId,
                name: material.name,
                thickness: material.thickness,
                resU: material.resU,
                resD: material.resD,
                thickFix: material.thickFix,
                materialId: material.index,
                id: guid.newGuid(),
              };
              let destinationDataClone = [...destinationData];
              destinationDataClone.splice(destinationRowIndex, 0, tableMaterial);
              //calculate posiotion
              destinationDataClone = calculatePosition(destinationDataClone);
                destinationSetData(destinationDataClone);
                if (callBack !== undefined) {
                    callBack();
                }
              return;
            }
            //in side table
            if (
              sourceTableId.includes("table") &&
              destinationTableId.includes("table")
            ) {
              const row = dataTable.filter((r) => {
                return r.id == sourceRowId;
              })[0];
              //remove from source table
              let sourceDataClone = [...dataTable];
              sourceDataClone.splice(sourceRowIndex, 1);
              sourceDataClone.splice(destinationRowIndex, 0, row);
              sourceDataClone = calculatePosition(sourceDataClone);
              setDataTable(sourceDataClone);
            }
          }
          if(callBack !== undefined){
            callBack();
          }
        } catch (ex) {
          console.log(ex);
        }
      };

      const calculatePosition = (tableRows) => {
        const destinationDataClone = [...tableRows];
        let position = "";
        if (destinationDataClone.length == 1) {     
          if(viewModel.typeName == "ExternalWall" || viewModel.typeName == "Door" ||viewModel.typeName == "Roof"){
            position = "External/Internal";
          }else{
            position = "Top/Bottom";
          }
          destinationDataClone[0].position = position;
        } else {
          for (let i = 0; i < destinationDataClone.length; i++) {
            let upper = "";
            let down = "";
            if(viewModel.typeName == "ExternalWall" || viewModel.typeName == "Door" ||viewModel.typeName == "Roof"){
                upper = "External";
                down = "Internal";
                }else{
                    upper = "Top";
                    down = "Bottom";
                }
            if (i === 0) {
              destinationDataClone[i].position = upper;
            } else if (i === destinationDataClone.length - 1) {
              destinationDataClone[i].position = down;
            } else {
              destinationDataClone[i].position = "|";
            }
          }
        }
        return destinationDataClone;
      };

    const computeRLayerUp =  function(resU, thickness){
        return resU * thickness / 1000;
    };

    const computeRLayerDown = function(resD, thickness){
      return resD * thickness / 1000;
    };

    const computeRHeatFlowUp = function () {
      if(dataTable.length === 0) return 0;
      const rLayerUps = dataTable.map(r => computeRLayerUp(r.resU, r.thickness));
      const add = (a, b) => a + b;
      return rLayerUps.reduce(add);
    };

    const computeRHeatFlowDown = function () {
      const rLayerDowns = dataTable.map(r => computeRLayerDown(r.resD, r.thickness));
      const add = (a, b) => a + b;
      return rLayerDowns.reduce(add);
    };

    const computeTotalRHeatFlowUp = function () {
      if(dataTable.length == 0) return 0;
      return (computeRHeatFlowUp() + 0.16);
    };

    const computeTotalRHeatFlowDown = function () {
      if(dataTable.length == 0) return 0;
      return computeRHeatFlowDown() + 0.16;
    };

    const computeUHeatFlowUp = function () {
      if(dataTable.length == computeRHeatFlowUp()) return 0;
      return 1 / computeRHeatFlowUp();
    };

    const computeUHeatFlowDown = function () {
      if(dataTable.length == computeRHeatFlowDown()) return 0;
      return 1 / computeRHeatFlowDown();
    };

    const computeTotalUHeatFlowUp = function () {
      if(dataTable.length == computeTotalRHeatFlowUp()) return 0;
      return 1 / computeTotalRHeatFlowUp();
    };

    const computeTotalUHeatFlowDown = function () {
      if(dataTable.length == computeTotalRHeatFlowDown()) return 0;
      return 1 / computeTotalRHeatFlowDown();
    };

    const calculateAbsorptance = function(type){
      let absorptance = 0;
      if(type == "Dark"){
        absorptance = 85;
      }
      if(type == "Medium"){
        absorptance = 50;
      }
      if(type == "Light"){
        absorptance = 30;
      }
      return absorptance;
    }

    useEffect(()=>{   
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading construction", appStoreDispatch, (newViewModel)=>{
          let category= "Other";
          if(newViewModel.typeName == "ExternalWall" || newViewModel.typeName == "Door" ||newViewModel.typeName == "Roof"){
            category = "External";
          }
          if(newViewModel.typeName == "Floor" || newViewModel.typeName == "Ceiling" || newViewModel.typeName == "FloorCeiling" ||newViewModel.typeName == "InternalWall"){
            category = "Internal";
          }
          dispatchViewModel({...newViewModel, category: category});
          const positionedRows = calculatePosition(newViewModel.constructionLayers);
          setDataTable(positionedRows);
        }, setInit);
    },[]);

    useEffect(()=>{
      try {
        if(!init) return;
        const selectedColor = dataExtension.getSelectOptionByValue(viewModel.externalColourTypes, viewModel.externalColourTypeId);
        if (selectedColor !== undefined){
          const absorptance = calculateAbsorptance(selectedColor.text);
          if(absorptance > 0){
            dispatchViewModel({...viewModel, externalAbsorptance: absorptance});
          }
        }
      }catch{}
    }, [viewModel.externalColourTypeId]);

    useEffect(()=>{
      try {
        if(!init) return;
        const selectedColor = dataExtension.getSelectOptionByValue(viewModel.internalColourTypes, viewModel.internalColourTypeId);
        if (selectedColor !== undefined){
          const absorptance = calculateAbsorptance(selectedColor.text);
          if(absorptance > 0){
            dispatchViewModel({...viewModel, internalAbsorptance: absorptance});
          }
        }
      }catch{}
    }, [viewModel.internalColourTypeId]);

    useEffect(()=>{
      try {
        if(!init) return;
        const selectedColor = dataExtension.getSelectOptionByValue(viewModel.firstColourTypes, viewModel.firstColourTypeId);
        if (selectedColor !== undefined){
          const absorptance = calculateAbsorptance(selectedColor.text);
          if(absorptance > 0){
            dispatchViewModel({...viewModel, firstAbsorptance: absorptance});
          }
        }
      }catch{}
    }, [viewModel.firstColourTypeId]);

    useEffect(()=>{
      try {
        if(!init) return;
        const selectedColor = dataExtension.getSelectOptionByValue(viewModel.lastColourTypes, viewModel.lastColourTypeId);
        if(viewModel.lastAbsorptance !== undefined){
          const absorptance = calculateAbsorptance(selectedColor.text);
          if(absorptance > 0){
            dispatchViewModel({...viewModel, lastAbsorptance: absorptance});
          }   
        }
      }catch{}
    }, [viewModel.lastColourTypeId]);

    useEffect(()=>{
      try {
        if(!init) return;
        const selectedColor = dataExtension.getSelectOptionByValue(viewModel.frameColourTypes, viewModel.frameColourTypeId);
        const absorptance = calculateAbsorptance(selectedColor.text);
        if(absorptance > 0){
          dispatchViewModel({...viewModel, frameAbsorptance: absorptance});
        }
      }catch{}
    }, [viewModel.frameColourTypeId]);

    useEffect(()=>{   
      dispatchViewModel({...viewModel, constructionLayersJsonString: JSON.stringify(dataTable), constructionLayers: dataTable});
    },[dataTable]);

    return(<Fragment>
        {debug && <p>{JSON.stringify(viewModel)}</p>}
        <BridgeMaterialDetail 
          constructionType={viewModel.typeName}
          bridgeMaterials = {viewModel.bridgeMaterials}
          bridgeMaterialFrameTypes = {viewModel.bridgeMaterialFrameTypes}
          bridgeMaterialAirGapTypes = {viewModel.bridgeMaterialAirGapTypes}
          floorCeilingConstructionTypes = {viewModel.floorCeilingConstructionTypes}
          isOpen = {isOpen} 
          setIsOpen = {setIsOpen} 
          bridgeIndex = {bridgeIndexModal}
          setBridgeIndex = {setBridgeIndexModal} 
          bridgeViewModel = {bridgeViewModel} 
          setBridgeViewModel ={setBridgeViewModel}
          handleSave = {bridgeSubmitHandler}
          >
        </BridgeMaterialDetail>
        <BaseForm
            id="DesignVariationFrom"
            icon="pe-7s-safe"
            loading={loading}
            init={init}
            setLoading={setLoading}
            submitButton={{ postUrl: postUrl + viewModel.category, redirectUrl: redirectUrl}}
            cancelButton={{ redirectUrl: redirectUrl }}
            data={viewModel}
            validate ={formValidate}
            title = {viewModel.constructionTypeName + " construction"} 
            subTitle={pageProfile.getPageSubTitle(appStoreState)}
        >
            <div className="form-row row">
                  <div className="col-12">
                      <div className="position-relative form-group">
                      <label htmlFor="Name">Name *</label>
                          <BindedInput validate={formValidate} className="form-control valid" id="Name" name="Name" type="text" binding={[viewModel, "name", bindingViewModel]}></BindedInput>
                      </div>
                  </div>
              </div>
            {/* External Construction */}
            {viewModel.category == "External" && <Fragment>
              <div className="form-row row">
                  <div className="col-6">
                      <div className="position-relative form-group">
                          <label htmlFor="ExternalColourTypeId">External surface colour *</label>
                          <BindedSelect className="form-control" id="ExternalColourTypeId" name="ExternalColourTypeId" type="text" binding={[viewModel, "externalColourTypeId", bindingViewModel]} options="externalColourTypes"></BindedSelect>                
                      </div>
                  </div>
                  <div className="col-6">
                      <div className="position-relative form-group">
                          <label htmlFor="ExternalAbsorptance">External surface solar absorptance (%) *</label>           
                          <BindedInput className="form-control valid" id="ExternalAbsorptance" name="ExternalAbsorptance" type="number" validate={formValidate} binding={[viewModel, "externalAbsorptance", bindingViewModel]}></BindedInput>       
                      </div>
                  </div>
              </div>
              <div className="form-row row">
                  <div className="col-6">
                      <div className="position-relative form-group">
                          <label htmlFor="InternalColourTypeId">Internal surface colour *</label>           
                          <BindedSelect className="form-control" id="InternalColourTypeId" name="InternalColourTypeId" type="text"  binding={[viewModel, "internalColourTypeId", bindingViewModel]} options="internalColourTypes"></BindedSelect>                
                      </div>
                  </div>
                  <div className="col-6">
                      <div className="position-relative form-group">
                          <label htmlFor="InternalAbsorptance">Internal surface solar absorptance (%) *</label>           
                          <BindedInput className="form-control valid" id="InternalAbsorptance" name="InternalAbsorptance" type="number" validate={formValidate} binding={[viewModel, "internalAbsorptance", bindingViewModel]}></BindedInput>       
                      </div>
                  </div>
              </div>              
              </Fragment>}    
              {/* Internal Construction */}
              {viewModel.category == "Internal" && <Fragment>
              <div className="form-row row">
                  <div className="col-6">
                      <div className="position-relative form-group">
                          <label htmlFor="InternalConstructionFirstColourTypeId">Top surface colour *</label>           
                          <BindedSelect className="form-control" id="InternalConstructionFirstColourTypeId" name="InternalConstructionFirstColourTypeId" binding={[viewModel, "firstColourTypeId", bindingViewModel]} options="firstColourTypes"></BindedSelect>                
                      </div>
                  </div>
                  <div className="col-6">
                      <div className="position-relative form-group">
                          <label htmlFor="InternalConstructionFirstAbsorptance">Top surface solar absorptance (%) *</label>           
                          <BindedInput className="form-control valid" id="InternalConstructionFirstAbsorptance" name="InternalConstructionFirstAbsorptance" validate={formValidate} type="number" binding={[viewModel, "firstAbsorptance", bindingViewModel]}></BindedInput>       
                      </div>
                  </div>
              </div>
              <div className="form-row row">
                  <div className="col-6">
                      <div className="position-relative form-group">
                          <label htmlFor="InternalConstructionLastColourTypeId">Bottom surface colour *</label>           
                          <BindedSelect className="form-control" id="InternalConstructionLastColourTypeId" name="InternalConstructionLastColourTypeId" type="text"  binding={[viewModel, "lastColourTypeId", bindingViewModel]} options="lastColourTypes"></BindedSelect>                
                      </div>
                  </div>
                  <div className="col-6">
                      <div className="position-relative form-group">
                          <label htmlFor="InternalConstructionLastAbsorptance">Bottom surface solar absorptance (%) *</label>           
                          <BindedInput className="form-control valid" id="InternalConstructionLastAbsorptance" name="InternalConstructionLastAbsorptance" validate={formValidate} type="number" binding={[viewModel, "lastAbsorptance", bindingViewModel]}></BindedInput>       
                      </div>
                  </div>
              </div>   
              {viewModel.constructionTypeName == "Floor/Ceiling" && viewModel.simulationType == "Existing Home" &&
               <div className="form-row row">
                  <div className="col-6">
                      <div className="position-relative form-group">
                          <label htmlFor="ImpactOfUninsulatedTypeId">Ceiling insulation coverage *</label>           
                          <BindedSelect className="form-control" id="ImpactOfUninsulatedTypeId" name="ImpactOfUninsulatedTypeId" type="text"  binding={[viewModel, "impactOfUninsulatedTypeId", bindingViewModel]} options="impactOfUninsulatedTypes"></BindedSelect>                
                      </div>
                  </div>
              </div>                 
              }              
              </Fragment>}  
              {viewModel.category == "Glazed" && <Fragment>
              <div className="form-row row">
                  <div className="col-12">
                      <div className="position-relative form-group">
                          <label htmlFor="SystemCode">System Code *</label>           
                          <BindedInput className="form-control valid" style={{border:"none", backgroundColor:"white"}} validate={formValidate} disabled id="SystemCode" name="SystemCode" binding={[viewModel, "systemCode", bindingViewModel]}></BindedInput>                
                      </div>
                  </div>
              </div>
              <div className="form-row row">
                  <div className="col-6">
                      <div className="position-relative form-group">
                          <label htmlFor="GlazedConstructionFrameColourTypeId">Frame colour *</label>           
                          <BindedSelect className="form-control" id="GlazedConstructionFrameColourTypeId" name="GlazedConstructionFrameColourTypeId" validate={formValidate} binding={[viewModel, "frameColourTypeId", bindingViewModel]} options="frameColourTypes"></BindedSelect>                
                      </div>
                  </div>
                  <div className="col-6">
                      <div className="position-relative form-group">
                          <label htmlFor="GlazedConstructionFrameAbsorptance">Frame solar absorptance (%) *</label>           
                          <BindedInput className="form-control valid" id="GlazedConstructionFrameAbsorptance" name="GlazedConstructionFrameAbsorptance" validate={formValidate} type="number" binding={[viewModel, "frameAbsorptance", bindingViewModel]}></BindedInput>       
                      </div>
                  </div>
              </div>   
              <div className="form-row row">
                  <div className="col-6">
                      <div className="position-relative form-group">
                      <label htmlFor="UValue">U-value *</label>           
                          <BindedInput className="form-control valid" style={{border:"none", backgroundColor:"white"}} validate={formValidate} disabled id="UValue" name="UValue" binding={[viewModel, "uValue", bindingViewModel]}></BindedInput>  
                      </div>
                  </div>
                  <div className="col-6">
                  <div className="position-relative form-group">
                      <label htmlFor="SHGC">SHGC *</label>           
                          <BindedInput className="form-control valid" style={{border:"none", backgroundColor:"white"}} validate={formValidate} disabled id="SHGC" name="SHGC" binding={[viewModel, "shgc", bindingViewModel]}></BindedInput>  
                      </div>
                  </div>
              </div>             
              </Fragment>}  
              {/* Shared Construction Layer Number validation */}
              <div className="form-row row">
                    <div className="col-6">
                        <div className="position-relative form-group">                      
                          <BindedInput componentRef = {constructionLayers} validate={formValidate} className="form-control valid" id="ConstructionLayersJsonString" name="ConstructionLayersJsonString" type="hidden" binding={[viewModel, "constructionLayersJsonString", bindingViewModel]}></BindedInput>
                        </div>
                    </div>
              </div>          
        </BaseForm>
        {init === true &&
              <DragDropContext onDragEnd={(result) => OnDragEndOperation(result)} >
              <div className="app-main__inner">
              {debug && <p>{JSON.stringify(dataTable)}</p>}
                  <div className="row">
                      <div className="col-7">
                          <div id="ConstructionLayerAccordion">
                              <div className="main-card mb-3 card">
                                  <div className="card-header">
                                      <button type="button" className="text-left m-0 p-0 btn btn-block">
                                          <h5 className="m-0 p-0 card-title">{viewModel.constructionTypeName} construction layers<TooltipTemplate text={"Add new materials to the Construction Layers by clicking and dragging them across from the Materials library.\nReorder materials in the Construction Layers by dragging them to the desired position."} result={0} color="grey" /></h5>
                                      </button>
                                  </div>
                                  <div className="card-body">                        
                                      <DropDragDataTable
                                          columns={columns}
                                          id={tableId}
                                          data={dataTable}
                                          setData={setDataTable}
                                          noRecordsMessage="No layers"
                                      ></DropDragDataTable>
                                      {/* <div className="form-row row">
                                        <div className="col-6">
                                            <div className="position-relative row form-group">
                                              <label className="col-7 col-form-label">Total R (heat flow up) (m<sup>2</sup>K/W)</label>
                                              <div className="col-form-label col-5" id="TotalRHeatFlowUp">
                                              { dataTable.length > 0 && computeTotalRHeatFlowUp().toFixed(2)}
                                              </div>
                                              <label className="col-7 col-form-label">R (heat flow up) (m<sup>2</sup>K/W)</label>
                                              <div className="col-form-label col-5" id="RHeatFlowUp">
                                              { dataTable.length > 0 && computeRHeatFlowUp().toFixed(2)}
                                              </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="position-relative row form-group">
                                              <label className="col-8 col-form-label">Total R (heat flow down) (m<sup>2</sup>K/W)</label>
                                              <div className="col-form-label col-4" id="TotalRHeatFlowDown">
                                              { dataTable.length > 0 && computeTotalRHeatFlowDown().toFixed(2)}
                                              </div>
                                              <label className="col-8 col-form-label">R (heat flow down) (m<sup>2</sup>K/W)</label>
                                              <div className="col-form-label col-4" id="RHeatFlowDown">
                                              { dataTable.length > 0 && computeRHeatFlowDown().toFixed(2)}
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                      <div className="col-6">
                                          <div className="position-relative row form-group">
                                            <label className="col-7 col-form-label">Total U (heat flow up) (m<sup>2</sup>K/W)</label>
                                            <div className="col-form-label col-5" id="TotalUHeatFlowUp">
                                            { dataTable.length > 0 && computeTotalUHeatFlowUp().toFixed(2)}
                                            </div>
                                            <label className="col-7 col-form-label">U (heat flow up) (m<sup>2</sup>K/W)</label>
                                            <div className="col-form-label col-5" id="UHeatFlowUp">
                                            { dataTable.length > 0 && computeUHeatFlowUp().toFixed(2)}
                                            </div>
                                          </div>
                                      </div>
                                      <div className="col-6">
                                          <div className="position-relative row form-group">
                                            <label className="col-8 col-form-label">Total U (heat flow down) (m<sup>2</sup>K/W)</label>
                                            <div className="col-form-label col-4" id="TotalUHeatFlowDown">
                                            { dataTable.length > 0 && computeTotalUHeatFlowDown().toFixed(2)}
                                            </div>
                                            <label className="col-8 col-form-label">U (heat flow down) (m<sup>2</sup>K/W)</label>
                                            <div className="col-form-label col-4" id="UHeatFlowDown">
                                            { dataTable.length > 0 && computeUHeatFlowDown().toFixed(2)}
                                            </div>
                                          </div>
                                      </div>
                                    </div> */}
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-5">
                          <div id="ConstructionLayerAccordion">
                              <div className="main-card mb-3 card">
                                  <div className="card-header">
                                      <button type="button" className="text-left m-0 p-0 btn btn-block">
                                          <h5 className="m-0 p-0 card-title">Materials Library</h5>
                                      </button>
                                  </div>
                                  <div className="card-body">
                                  {viewModel.simulationType !== undefined && viewModel.simulationType !== null &&
                                    <DataTableFancyTree
                                    id={treeId}
                                    isDropDisabled={true}
                                    data={dataTree}
                                    setData={setDataTree}
                                    simulationType = {viewModel.simulationType}
                                    dataSource={{
                                        isAuth: true,
                                        url: "api/Construction/Accessor/GetMaterialsTree",
                                    }}
                                    ></DataTableFancyTree>
                                  
                                  }
                                 
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>       
              </div>    
            </DragDropContext>
        }
  
    </Fragment>);
}
export default connectAppStore(InternalExternalConstructionDetail);