import { connectAppStore } from "../../../../../Store/AppStore";
import PageDependencies from "../../../../../Feature/PageDependencies";
import ConditionerDataTable from "./ConditionerDataTable"

const HeatingModule = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        htmlToReactParse,
        tryCatchFinally,
        layoutManager,
        getData,
        guid,
        useReducerSmart,
        toastPrint,
        useParams,
        BindedInput,
        BindedSelect,
        useRef,
        BindedRadio,
        getAppStore,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        FormValidate,
        dataExtension,
        DropDragDataTable,
        OnDragEndOperation,
        DragDropContext
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const defaultViewModel = {
        ...defaultParams,
    };
    const [extraPostModel, setExtraPostModel] = useState({
        ...defaultParams
      });
    const buttonWidthRef = useRef();
    const [selectedIds, setSelectedIds] = useState({});
    const [buttonText, setButtonText] = useState("Add");
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/ExistingHome/CoolingModule/Accessor/Get";
    const validateMethodsAdd = {
        appliedZones: (value) => { 
            return FormValidate.validateTextRequired(value, "Applied zones", undefined, true);
        },
    };
    const formValidateAdd = new FormValidate(useState(validateMethodsAdd), useState(), useState(), useState(), false);
    const validateMethodsCalculate = {
        starRating: (value, newViewModel) => { 
                if(parseInt(newViewModel.conditionerTypeId) != 1){              
                    return FormValidate.validateNumberGreaterThan0(value, "Star rating");
                } 
                return "";
            },
        capacity: (value, newViewModel) => { 
                if(newViewModel.calculationModel !== "StarRatingOnly" && parseInt(newViewModel.conditionerTypeId) != 1){              
                    return FormValidate.validateNumberGreaterThan0(value, "Capacity");
                } 
                return "";
            },
        };
    const formValidateCalculate = new FormValidate(useState(validateMethodsCalculate), useState(), useState(), useState(), false);
    //conditioner table
    const [conditionerData, setConditionerData] = useState([]);
    //drag table
    const [allZonesData, setAllZonesData ] = useState([]);
    const [availableZonesData, setAvailableZonesData ] = useState([]);
    const [appliedZonesData, setAppliedZonesData ] = useState([]);
    const draggableTablesDic = {};
    const table1Id = "Cooling_Existing_AvailableZones_21d36a29-d6f0-4a6d-9b0e-2f1380241bb1";
    const table2Id = "Cooling_Existing_AppliedZones_21d36a29-d6f0-4a6d-9b0e-2f1380241bb1";
    draggableTablesDic[table1Id] = { data: availableZonesData, setData: setAvailableZonesData };
    draggableTablesDic[table2Id] = { data: appliedZonesData, setData: setAppliedZonesData };
    const columnsZone = [
        { name: "name", text: "Name", width: "100%" }
      ];
    //form
    const [canSubmit, setCanSubmit] = useState(false);
    const [reloadForm, setReloadForm] = useState("");
    const [pageLayout, setPageLayout] = useState({showCop: true, showHspf: true, disableCop: false, disabledHspf: true, showStarRating: false, showCapacity: false});

    const getAvailableZones = (viewModel) => {
        if(viewModel.existingConditionersExistingHome === undefined){
            return [];
        }
        const allZones = viewModel.allZones;
        let existingConditionedZones = dataExtension.selectMany(viewModel.existingConditionersExistingHome.map(c=>c.appliedZoneIds));
        existingConditionedZones = dataExtension.distinct(existingConditionedZones);
        let newAvailableZones = [];
        for(let i =0; i < allZones.length; i++){
            const zone = allZones[i];
            if(!existingConditionedZones.includes(zone.id)){
                newAvailableZones.push(zone)
            }
        }
        return newAvailableZones;
    }

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading cooling module", appStoreDispatch, (newViewModel)=>{
            const allZones = newViewModel.allZones;
            setAvailableZonesData(getAvailableZones(newViewModel));
            setAllZonesData(allZones);
        }, setInit);
    },[reloadForm]);

    useEffect(()=>{
        const newViewModel = {...viewModel, appliedZones: appliedZonesData};
        dispatchViewModel(newViewModel);
    }, [appliedZonesData]);

    useEffect(()=>{
        const timer = setTimeout(() => {
            tryCatchFinally.syncProcess(()=>{
                setCanSubmit(false);
                const selectedConditioner =  dataExtension.getSelectOptionByValue(viewModel.conditionerTypes, viewModel.conditionerTypeId);
                if(selectedConditioner !== undefined){
                    //default
                    if(parseInt(selectedConditioner.value) == 1){
                        setPageLayout({...pageLayout, showStarRating: false, showCapacity: false, showHspf: true, disabledHspf: true, showCop: true, disableCop: false});
                    }else if(parseInt(selectedConditioner.value) == 14){
                    //existing default
                        setPageLayout({...pageLayout, showStarRating: false, showCapacity: false, showHspf: true, disabledHspf: true, showCop: true, disableCop: true});
                    }else if(parseInt(selectedConditioner.value) == 15){
                    //existing air conditioning
                        if (viewModel.year >= 2019 && (viewModel.modeTypeId == 40 || viewModel.modeTypeId == 41)) {
                            setPageLayout({...pageLayout, showStarRating: false, showCapacity: false, showHspf: true, disabledHspf: true, showCop: false, disableCop: true});
                        }else{
                            setPageLayout({...pageLayout, showStarRating: false, showCapacity: false, showHspf: true, disabledHspf: true, showCop: true, disableCop: true});
                        }
                    }else if(parseInt(selectedConditioner.value) >= 10){
                    //evaporative
                        setPageLayout({...pageLayout, showStarRating: false, showCapacity: false, showHspf: false, showCop: true, disableCop: false});
                    }else{
                    //rest
                        setPageLayout({...pageLayout, showStarRating: true, showCapacity: true, showHspf: true, disabledHspf: false, showCop: true, disableCop: false});
                    }
                    //set eer
                    dispatchViewModel({...viewModel, eerOrCop: selectedConditioner.eerOrCop});
                }   
            });  
        }, 850);
        return () => {
            clearTimeout(timer);
          };
    }, [viewModel.conditionerTypeId, viewModel.starRating, viewModel.capacity, viewModel.calculationModel, viewModel.year]);

    const applyAllClick = () => {
        const newZones = dataExtension.distinctBy([...appliedZonesData, ...getAvailableZones(viewModel)],"id");
        setAvailableZonesData([]);
        setAppliedZonesData(newZones);
    }

    const resetClick = () =>{
        const newZones = dataExtension.distinctBy([...appliedZonesData, ...getAvailableZones(viewModel)],"id");
        setAvailableZonesData(newZones);
        setAppliedZonesData([]);
    }

    const calculationCOPHSPF = async ()=>{
        formValidateCalculate.setValidateVisibility(true);
        const validateResult = formValidateCalculate.checkValidateResult(viewModel);
        if(!validateResult) return;
        const calculateUrl = "api/ExistingHome/CoolingModule/Accessor/CalculateCoolerEerAndTcspf";
        setLoading({ isLoading: true, text: "Calculating" });
        tryCatchFinally.syncProcess(async()=>{
            const response = await getData.processPost(true, calculateUrl, viewModel);
            if(!response.state){
                toastPrint.printErrorMessage(response.message);
            }else{
                const newModel = response.viewModel;
                dispatchViewModel({...viewModel, eerOrCop: newModel.eerOrCop, tcspf: newModel.tcspf});
            }
        }, setLoading);
        setCanSubmit(true);
    }

    useEffect(()=>{
        tryCatchFinally.syncProcess(()=>{
            if(selectedIds !== undefined){
                const ids = selectedIds.selectedItemIds;
                if(ids !== undefined && ids.length === 1){
                    setButtonText("Update");
                    //load conditioner
                    const conditionerId = ids[0];
                    let conditioner = {};
                    for(let i = 0; i < viewModel.existingConditionersExistingHome.length; i++){
                        if(viewModel.existingConditionersExistingHome[i].id == conditionerId){
                            conditioner = viewModel.existingConditionersExistingHome[i];
                        }
                    }
                    const appliedZoneIds = conditioner.appliedZoneIds;
                    const newAvailableZonesData = [];
                    const newAppliedZonesData = [];
                    for(let i = 0; i< allZonesData.length; i++){
                        const zone = allZonesData[i];
                        let push = false;
                        for(let j = 0; j< appliedZoneIds.length; j++){
                            if(appliedZoneIds[j] == zone.id){
                                newAppliedZonesData.push(zone);
                                push = true;
                            }
                        }
                        if(!push){
                            newAvailableZonesData.push(zone);
                        }
                    }          
                    const loadModel = {...viewModel, 
                        conditionerTypeId: conditioner.index,
                        capacity: conditioner.capacity,
                        starRating: conditioner.starRating,
                        eerOrCop: conditioner.eer,
                        tcspf: conditioner.tcspf,
                        calculationModel: conditioner.starRatingCheck === true ? "StarRatingOnly" : "CapacityOnly",
                        id: conditioner.id,
                        appliedZones:newAppliedZonesData,
                        modeTypeId: conditioner.modeTypeId,
                        productTypeId: conditioner.productTypeId,
                        ratingPlateInput: conditioner.ratingPlateInput,
                        ratingPlateOutput: conditioner.ratingPlateOutput,
                        year: conditioner.year
                    };
                    dispatchViewModel(loadModel);
                    setAvailableZonesData(getAvailableZones(viewModel));
                    setAppliedZonesData(newAppliedZonesData);
                }else{
                    const d = new Date();
                    const year = d.getFullYear();
                    setButtonText("Add");
                    const loadModel = {...viewModel, 
                        capacity: 0,
                        starRating: 0,
                        tcspf: 0,
                        eerOrCop: 0,
                        year: year,
                        calculationModel:"StarRatingOnly",
                        id: guid.emptyGuid()
                    };
                    if(viewModel.coolingModeTypes !== undefined && viewModel.coolingModeTypes.length >= 1){
                        viewModel.modeTypeId = viewModel.coolingModeTypes[0].value;
                    }
                    if(viewModel.productTypes !== undefined && viewModel.productTypes.length >= 1){
                        viewModel.productTypeId = viewModel.productTypes[0].value;
                    }
                    dispatchViewModel(loadModel);
                    setAppliedZonesData([]);
                    setAvailableZonesData(getAvailableZones(viewModel));
                }
            }
        }); 
    }, [selectedIds]);

    const submit = ()=>{
        formValidateAdd.setValidateVisibility(true);
        const validateResult = formValidateAdd.checkValidateResult(viewModel);
        if(!validateResult) return;
        const postUrl = "api/ExistingHome/CoolingModule/Accessor/" + buttonText + "CoolingConditioner";
        setLoading({ isLoading: true, text: "Processing cooling device" });
        tryCatchFinally.syncProcess(async()=>{
            const response = await getData.processPost(true, postUrl, viewModel);
            toastPrint.printResponse(response);
            resetClick();
            setReloadForm(guid.newGuid());
        }, setLoading);
    }

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-angle-down-circle icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Cooling
                                <div className="page-title-subheading">
                                    {pageProfile.getPageSubTitle(appStoreState)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {init === true &&
                    <>
                        <div className="main-card mb-3 card">
                            <div className="card-header">
                                <button type="button" data-toggle="collapse" data-target="#AnnualEnergyConsumptionDiv" aria-expanded="true" aria-controls="AnnualEnergyConsumptionDiv" className="text-left m-0 p-0 btn btn-block">
                                    <h5 className="m-0 p-0 card-title">Annual Energy Consumption</h5>
                                </button>
                            </div>
                            <div data-parent="#AnnualEnergyConsumptionAccordion" id="AnnualEnergyConsumptionDiv" className="collapse show">
                                <div className="card-body">
                                    <div className="separator"></div>
                                    <div className="form-row row">
                                        <div className="col-3">
                                            <div className="position-relative form-group">
                                                <label>ZERL Zone: <span style={{color:"red"}}>{viewModel.zerlZone}</span></label>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="position-relative form-group">
                                                <label>Evaporative Cooler: <span style={{color:"red"}}>{viewModel.evaporativeCoolerSuitability}</span></label>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="position-relative form-group">
                                                <label>Electricity (kWh/yr): {parseFloat(viewModel.annualElectricityEnergy).toFixed(2)}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="main-card mb-3 card">
                            <div className="card-header">
                                <button type="button" data-toggle="collapse" data-target="#HeatingConditionersListDiv" aria-expanded="true" aria-controls="HeatingConditionersListDiv" className="text-left m-0 p-0 btn btn-block">
                                    <h5 className="m-0 p-0 card-title">Applied conditioners</h5>
                                </button>
                            </div>
                            <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                                <div className="card-body">
                                    {debug && <p>{JSON.stringify(conditionerData)}</p>}
                                    <ConditionerDataTable
                                        debug = {debug}
                                        data = {conditionerData}
                                        setData = {setConditionerData}
                                        extraPostModel = {extraPostModel}
                                        url="api/ExistingHome/CoolingModule/Accessor"
                                        conditionerType="Cooling"
                                        tableReload = {reloadForm}
                                        setTableReload = {setReloadForm}
                                        selectedIds = {selectedIds}
                                        setSelectedIds = {setSelectedIds}
                                    ></ConditionerDataTable>
                                </div>
                            </div>
                        </div>
                        <div className="main-card mb-3 card">
                <div className="card-header">
                    <button type="button" data-toggle="collapse" data-target="" aria-expanded="true" aria-controls="" className="text-left m-0 p-0 btn btn-block">
                        <h5 className="m-0 p-0 card-title">Cooling Conditioner</h5>
                    </button>
                </div>
                <div data-parent="#HeatingAccordion" id="HeatingCollapsibleDiv" className="collapse show">
                    <div className="card-body">
                        <div className="form-row row">
                            <div className="col-lg-6 col-6">
                                <div className="position-relative form-group pr-3">
                                    <BindedSelect
                                        className="form-control"
                                        id="ConditionerTypeId"
                                        name="ConditionerTypeId"
                                        binding={[viewModel, "conditionerTypeId", bindingViewModel]}
                                        options={"conditionerTypes"}
                                    ></BindedSelect>
                                </div>
                            </div>
                        </div>
                        <div className="form-row-separator"></div>
                        <div className="form-row row">
                            {pageLayout.showStarRating && 
                                <div className="col-12">
                                    <div className="position-relative form-group pr-3">
                                        <div className="form-row row">
                                            <div className="col-lg-2 col-2 form-check" style={{marginTop:"10px", marginLeft:"15px"}}>
                                                <BindedRadio
                                                    id="StarRatingOnly"
                                                    buttonValue = "StarRatingOnly"
                                                    buttonLabel = "Star Rating Only"
                                                    binding={[viewModel, "calculationModel", bindingViewModel]}
                                                ></BindedRadio>
                                            </div>
                                            <div className="col-lg-2 col-2">
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "starRating", bindingViewModel]}
                                                    id="StarRating"
                                                    name="StarRating"
                                                    type="number" 
                                                    step="0.5" 
                                                    validate = {formValidateCalculate}
                                                ></BindedInput>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {pageLayout.showCapacity &&
                                <div className="col-12">
                                    <div className="position-relative form-group pr-3">
                                        <div className="form-row row">
                                            <div style={{marginLeft:"15px"}} className="col-lg-2 col-2 form-check">
                                                <BindedRadio
                                                    id="CapacityOnly"
                                                    buttonValue = "CapacityOnly"
                                                    buttonLabel = "Capacity (KW)"
                                                    binding={[viewModel, "calculationModel", bindingViewModel]}
                                                ></BindedRadio>              
                                            </div>
                                            {viewModel.calculationModel === "CapacityOnly" &&
                                            <div className="col-lg-2 col-2">
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "capacity", bindingViewModel]}
                                                    id="Capacity"
                                                    name="Capacity"
                                                    type="number" 
                                                    step="0.5" 
                                                    validate = {formValidateCalculate}
                                                ></BindedInput>
                                            </div>
                                            }                              
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                        <div className="form-row row">
                            {(viewModel.conditionerTypeId == "15") &&
                            <Fragment>
                                <div className="col-2">
                                    <div className="position-relative form-group">
                                        <label htmlFor="Year">Year</label>
                                        <BindedInput
                                            disabled={viewModel.modeTypeId == 42}
                                            className="form-control"
                                            binding={[viewModel, "year", bindingViewModel]}
                                            id="Year"
                                            name="Year"
                                            type="number" 
                                            step="1" 
                                        ></BindedInput>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="position-relative form-group">
                                        <label htmlFor="EStarRating">Star Rating</label>
                                        <BindedInput
                                            disabled={viewModel.modeTypeId == 42}
                                            className="form-control"
                                            binding={[viewModel, "starRating", bindingViewModel]}
                                            id="EStarRating"
                                            name="EStarRating"
                                            type="number" 
                                            step="0.1" 
                                        ></BindedInput>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="ModeTypeId">Efficiency based on</label>
                                        <BindedSelect
                                            className="form-control"
                                            id="ModeTypeId"
                                            name="ModeTypeId"
                                            binding={[viewModel, "modeTypeId", bindingViewModel]}
                                            options={"coolingModeTypes"}
                                        ></BindedSelect>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="ProductTypeId">Type</label>
                                        <BindedSelect
                                            className="form-control"
                                            id="ProductTypeId"
                                            name="ProductTypeId"
                                            binding={[viewModel, "productTypeId", bindingViewModel]}
                                            options={"productTypes"}
                                        ></BindedSelect>
                                    </div>
                                </div>
                            </Fragment>
                            }
                        </div>
                        <div className="form-row row">
                            {( viewModel.modeTypeId == "42") &&
                                <Fragment>
                                    <div className="col-2">
                                        <div className="position-relative form-group">
                                            <label htmlFor="RatingPlateInput">Rating Plaet Input</label>
                                            <BindedInput
                                                className="form-control"
                                                binding={[viewModel, "ratingPlateInput", bindingViewModel]}
                                                id="RatingPlateInput"
                                                name="RatingPlateInput"
                                                type="number" 
                                                step="0.1" 
                                            ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="position-relative form-group">
                                            <label htmlFor="RatingPlateOutput">Rating Plate Output</label>
                                            <BindedInput
                                                className="form-control"
                                                binding={[viewModel, "ratingPlateOutput", bindingViewModel]}
                                                id="RatingPlateOutput"
                                                name="RatingPlateOutput"
                                                type="number" 
                                                step="0.1" 
                                            ></BindedInput>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </div>
                        <div className="form-row row">
                            {pageLayout.showCop && 
                                <div className="col-2">
                                    <div className="position-relative form-group">
                                        <label htmlFor="EerOrCop">EER</label>
                                        <BindedInput
                                            className="form-control"
                                            disabled ={pageLayout.disableCop}
                                            binding={[viewModel, "eerOrCop", bindingViewModel]}
                                            id="EerOrCop"
                                            name="EerOrCop"
                                            type="number" 
                                            step="0.1" 
                                        ></BindedInput>
                                    </div>
                                </div>
                            }
                            {pageLayout.showHspf && 
                                <div className="col-2">
                                    <div className="position-relative form-group">
                                        <label htmlFor="TCSPF" >TCSPF</label>
                                        <BindedInput
                                            className="form-control"
                                            disabled ={pageLayout.disabledHspf}
                                            binding={[viewModel, "tcspf", bindingViewModel]}
                                            id="TCSPF"
                                            name="TCSPF"
                                            type="number" 
                                            step="0.1" 
                                        ></BindedInput>
                                    </div>     
                                </div>
                            }
                            <div className="col-3">
                                <div className="position-relative form-group">
                                    <label style={{color:"red"}}> {!canSubmit && "Calculation EER or TCSPF before submiting" } {canSubmit && htmlToReactParse("&nbsp;") }</label>
                                    <a type="button" className="btn btn-primary form-control" onClick={calculationCOPHSPF}>Calculation EER/TCSPF</a>
                                </div>                   
                            </div>
                        </div>
                        <div className="divider"></div>         
                        <DragDropContext onDragEnd={(result) => OnDragEndOperation(result, draggableTablesDic)} >
                            <div className="form-row row">
                                <div className="col-4">
                                    <p>Available Zones</p> 
                                    <DropDragDataTable   
                                        style={{maxHeight:"300px", overflowY:"scroll"}}                   
                                        columns={columnsZone}
                                        id={table1Id}
                                        data={availableZonesData}
                                        setData={setAvailableZonesData}
                                        noRecordsMessage="No zones"
                                    ></DropDragDataTable>                          
                                </div>
                                <div className="col-3" align="center">
                                    <div className="row" display="inline-block">
                                        <p>Zones operation</p> 
                                        <p><button type="button" className="btn btn-primary" ref={buttonWidthRef} onClick={applyAllClick}>Apply All</button></p> 
                                        <p><button type="button" className="btn btn-primary" style={{width: buttonWidthRef.current === undefined ? -1 : buttonWidthRef.current.clientWidth}} onClick={resetClick}>Reset</button></p> 
                                        <BindedInput
                                            hidden
                                            className="form-control"
                                            binding={[viewModel, "appliedZones", bindingViewModel]}
                                            id="AppliedZones"
                                            name="AppliedZones"
                                            validate = {formValidateAdd}
                                        ></BindedInput>
                                    </div>                                   
                                </div>
                                <div className="col-4">
                                    <p>Applied Zones</p>
                                    <DropDragDataTable
                                        style={{maxHeight:"300px", overflowY:"scroll"}}        
                                        columns={columnsZone}
                                        id={table2Id}
                                        data={appliedZonesData}
                                        setData={setAppliedZonesData}
                                        noRecordsMessage="No zones"
                                    ></DropDragDataTable>   
                                    <button type="button" style={{marginTop:"10px"}} className="btn btn-primary" disabled={!canSubmit} onClick={submit}>{buttonText} appliance to applied zones</button>
                                </div>
                            </div>
                        </DragDropContext>                      
                    </div>
                </div>
            </div>
                    </>
                }
        </div>
    </Fragment>
    );
}
export default connectAppStore(HeatingModule);