import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
import { Checkbox } from 'primereact/checkbox';

const DesignVariations = (props)=>{
    const { 
        tryCatchFinally,
        layoutManager,
        getData,
        guid,
        toastPrint,
        useParams,
        getAppStore,
        useNavigate,
        useStateOperation,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        dataExtension,
        Fragment, 
        useEffect, 
        useState,
        useReducerSmart,
        ServerSideDataTable,
        ModalDatatableItem,
    } = PageDependencies();
    const nav = useNavigate();
    const projectId = useParams().projectId;
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const [modal, setModal] = useState({
        isOpen: false,
        title: "",
        message: "",
        comfirmButtonStyle: "",
        comfirmButtonText: "Comfirm",
        handleComfirm: () => {},
    });
    const [loading, setLoading] = useState({isLoading:false,text:""});
    const [extraPostModel, setExtraPostModel] = useState({
        projectId:projectId
    });
    const [data, setData] = useState([]);
    const [dvId, setDvId] = useState("");
    const tableId = "DesignVariation_5b29e5a8-fff1-49bc-bf3b-309a8573df85";
    const [tableReload, setTableReload] = useState("");
    const [selectedIds, setSelectedIds] = useState({selectedItemIds: []});
    const [viewModel, setViewModel] = useReducerSmart({
        projectId:projectId
    });
    const columns = [
        { name: "name", text: "Name", width: "25%" },
        { name: "description", text: "Description", width: "35%" },
        { name: "submittedDate", text: "Submitted Date", width: "25%", disableSorting:true },
        { name: "isBaseDesignVariation", text: "Base Design", width: "15%", generate: operationGenerate, disableSorting: true },
    ];
    function operationGenerate(model, cloumn, index, style, onColumnValueChangeEvent){
      return(
        <Checkbox
          checked = {model.isBaseDesignVariation}
          onChange={(e) => {
              if (e.checked === undefined) e.checked = false;
              setData((oldValue)=>{
                  const newData = [...oldValue];
                  const modelIndex = dataExtension.getIndexById(newData, model.id);
                  if(modelIndex === -1) return oldValue;
                  if(newData[modelIndex] === undefined) return oldValue;
                  for(let i = 0; i < newData.length; i++){
                    newData[i].isBaseDesignVariation = false;
                  }
                  if (newData.length === 1) {
                    newData[modelIndex].isBaseDesignVariation = true;
                    toastPrint.printErrorMessage("Must have a base design variation");
                  }
                  else{
                    newData[modelIndex].isBaseDesignVariation = e.checked;
                    setDvId(model.id);
                  }
                  return newData;
              });
          }}
          disabled = {model.isBaseDesignVariation}
        ></Checkbox>);
      }
    const contextMenu = {
        items: {
          new: {
            name: "New",
            icon: "add",
            url: "/DesignVariationAccessor/Detail/" + projectId + "/" + guid.emptyGuid(),
            defaultAction: true,
          },
          // active: {
          //   name: "Select as active",
          //   icon: "",
          //   url: "",
          //   defaultAction: false,
          //     disabled: function () {
          //     return dataExtension.getSelectedRowDataIds(data).length !== 1;
          //   },
          // },
          separator: "---------",
          edit: {
            name: "Edit",
            icon: "edit",
            url: "/DesignVariationAccessor/Detail/" + projectId + "/",
            defaultAction: true,
              disabled: function () {
              return dataExtension.getSelectedRowDataIds(data).length !== 1;
            },
          },
          duplicate: {
            name: "Duplicate",
            icon: "copy",
            url: "",
            defaultAction: false,
            disabled: function () {
              return dataExtension.getSelectedRowDataIds(data).length === 0;
            },
          },
          delete: {
            name: "Delete",
            icon: "delete",
            url: "",
            defaultAction: false,
            disabled: function () {
              return dataExtension.getSelectedRowDataIds(data).length === 0;
            },
          }
        },
        callBack: (
          contextMenuCallBackKey,
          contextMenuCallBackElement,
          contextMenuCallBackTableRoadData,
          localtion
        ) => {
            if (contextMenuCallBackKey === "active") {
                setModal({
                ...modal,
                isOpen: true,
                title: "Active design variations",
                message:
                    "Are you sure you want to activate the selected design variation?",
                comfirmButtonStyle: "primary",
                comfirmButtonText: "Confirm to active",
                handleComfirm: handleActiveComfirm,
                });
            }
          if (contextMenuCallBackKey === "delete") {
            setModal({
              ...modal,
              isOpen: true,
              title: "Delete design variations",
              message:
                "Are sure to delete " +
                dataExtension.getSelectedRowDataIds(data).length +
                " selected design variations?",
              comfirmButtonStyle: "danger",
              comfirmButtonText: "Confirm to delete",
              handleComfirm: handleDeleteComfirm,
            });
          }
          if (contextMenuCallBackKey === "duplicate") {
            setModal({
              ...modal,
              isOpen: true,
              title: "Duplicate design variations",
              message:
                "Are sure to duplicate " +
                dataExtension.getSelectedRowDataIds(data).length +
                " selected design variations?",
              comfirmButtonStyle: "primary",
              comfirmButtonText: "Confirm to duplicate",
              handleComfirm: handleDuplicatedComfirm,
            });
          }
        },
      };

    const handleActiveComfirm = async() =>{    
      await useStateOperation.asyncInvoke(setSelectedIds, async (newSelectedIds)=>{
        setLoading({isLoading:true,text:"Activing design variation"});
        const selectResponse = await getData.processPost(true, "api/DesignVariation/Accessor/Select", {...newSelectedIds, projectId: projectId}); 
        toastPrint.printResponse(selectResponse);
        setTableReload(guid.newGuid());
      }, setLoading);
    }

    const handleDeleteComfirm = async () => {
      await useStateOperation.asyncInvoke(setSelectedIds, async (newSelectedIds)=>{
        setLoading({isLoading:true,text:"Deleting design variations"});
        const selectResponse = await getData.processPost(true, "api/DesignVariation/Accessor/Delete", {...newSelectedIds, designVariationVersionName: viewModel.designVariationVersionName, projectId: projectId}); 
        toastPrint.printResponse(selectResponse);
        setTableReload(guid.newGuid());
      }, setLoading);
    }

    async function handleDuplicatedComfirm(){   
      await useStateOperation.asyncInvoke(setSelectedIds, async (newSelectedIds)=>{
        setLoading({isLoading:true,text:"Duplicating design variations"});
        const selectResponse = await getData.processPost(true, "api/DesignVariation/Accessor/Copy", {...newSelectedIds, designVariationVersionName: viewModel.designVariationVersionName, projectId: projectId} ); 
        toastPrint.printResponse(selectResponse);
        setTableReload(guid.newGuid());
      }, setLoading);
    }

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, "api/DefaultModel/Accessor/Get", viewModel, setViewModel, setLoading, "Loading data", appStoreDispatch);
    },[]);

    const updateBase = async ()=>{
      await tryCatchFinally.asyncProcess(async()=>{
        const postUrl = "api/DesignVariation/Accessor/UpdateBase";
        const response = await getData.processPost(true, postUrl, {...viewModel, designVariationId: dvId});
        toastPrint.printResponse(response);
      });
    }
    
    useEffect(()=>{
      if (dvId.length > 0 && dvId != guid.emptyGuid()) {
        updateBase();
      }
    },[dvId]);

    return(<Fragment>
        {layoutManager.Layout_GetDebugModel(props) && <p>Selected items: {JSON.stringify(selectedIds)}</p>}
      <ModalDatatableItem
        modal={modal}
        setModal={setModal}
      ></ModalDatatableItem>
      {loading.isLoading && <AppLoading active><div>{loading.text}</div></AppLoading>}
      <div className="app-main__inner">
        <div className="app-page-title">
            <div className="page-title-wrapper">
                <div className="page-title-heading">
                    <div className="page-title-icon">
                    <i className="pe-7s-science icon-gradient bg-tempting-azure">
                    </i>
                    </div>
                    <div>
                      Content - Design variations 
                    <div className="page-title-subheading">
                       {pageProfile.getPageSubTitle(appStoreState)}
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="main-card mb-3 card">
            <div className="card-body">
                {loading.isLoading && <AppLoading active><div>{loading.text}</div></AppLoading>}
                <ServerSideDataTable
                    id={tableId}
                    location="DesignVariation"
                    data={data}
                    setData={setData}
                    reload={tableReload}
                    columns={columns}
                    actionOnDoubleClickRow
                    allowSelect
                    allowSelectMultiple
                    dataSource={{ isAuth: true, url: "api/DesignVariation/Accessor" }}
                    contextMenu={contextMenu}
                    extraPostModel={extraPostModel}
                    setSelectedItemIds = {setSelectedIds}
                >
                </ServerSideDataTable>
            </div>
        </div>
      </div>
    </Fragment>);
}

export default connectAppStore(DesignVariations);