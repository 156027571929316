import { Fragment, useEffect, useState } from "react";
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";

const MultipleExternalScreensDetail = (props) =>{
    const { 
        stringCoding,
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        BaseForm,
        BindedInput,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const projectId = useParams().projectId;
    const designVariationId = useParams().designVariationId;
    const developmentId = useParams().developmentId;
    const zoneId = useParams().zoneId;
    const redirectUrl = stringCoding.decode(useParams().redirectUrlHex);
    if(zoneId === undefined) zoneId = emptyGuid;
    const selectedElementIds = stringCoding.decodeObject(useParams().jsonParamHex);
    const defaultViewModel = {
        developmentId: developmentId,
        projectId: projectId, 
        designVariationId: designVariationId,
        designVariationVersionName:"V001",
        zoneId: zoneId,
        selectedItemIds: selectedElementIds
      };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const postUrl = "api/Element/Accessor/UpdateMultipleVerticalShadingElements";
    const getUrl = "api/Element/Accessor/GetMultipleVerticalShadingElements";

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading external screens", appStoreDispatch);
    },[]);

    return(<Fragment>
        {debug && <p>{JSON.stringify(viewModel)}</p>}
        <BaseForm
           id="FloorCeilingFrom"
           icon="pe-7s-safe"
           loading={loading}
           setLoading={setLoading}
           submitButton= {{text:"Save and close", postUrl: postUrl, redirectUrl: redirectUrl }}
           cancelButton={{ redirectUrl: redirectUrl }}
           data={viewModel}
           title = "External screens detail"
           subTitle={pageProfile.getPageSubTitle(appStoreState)}
        >
            <div className="form-row row">
                <div className="col-6">
                    <div className="position-relative form-group">
                    <label htmlFor="ShadingName">Name *</label>
                        <BindedInput className="form-control valid" id="ShadingName" name="ShadingName" type="text" binding={[viewModel, "name", bindingViewModel]}></BindedInput>
                    </div>
                </div>
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="VerticalElementShadingVerticalShadingId">Available screens *</label>
                        <BindedSelect
                            disabled
                            className="form-control"
                            id="VerticalElementShadingVerticalShadingId"
                            name="VerticalElementShadingVerticalShadingId"
                            options="verticalShadings"
                            binding={[viewModel, "verticalShadingId", bindingViewModel]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-4">
                    <div className="position-relative form-group">
                        <label htmlFor="VerticalElementShadingHeight">Height (mm) *</label>           
                        <BindedInput disabled className="form-control valid" id="VerticalElementShadingHeight" name="VerticalElementShadingHeight" type="number" binding={[viewModel, "height", bindingViewModel]}></BindedInput>                
                    </div>
                </div>
                <div className="col-4">
                    <div className="position-relative form-group">
                        <label htmlFor="VerticalElementShadingWidth">Width (mm) *</label>           
                        <BindedInput disabled className="form-control" id="VerticalElementShadingWidth" name="VerticalElementShadingWidth" type="number" binding={[viewModel, "width", bindingViewModel]}></BindedInput>                
                    </div>
                </div>
                <div className="col-4">
                    <div className="position-relative form-group">
                        <label htmlFor="VerticalElementShadingDistance">Distance (mm) *</label>           
                        <BindedInput className="form-control valid" id="VerticalElementShadingDistance" name="VerticalElementShadingDistance" type="number" binding={[viewModel, "distance", bindingViewModel]}></BindedInput>       
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="VerticalElementShadingHorizontalOffset">Horizontal offset (mm) *</label>           
                        <BindedInput className="form-control valid" id="VerticalElementShadingHorizontalOffset" name="VerticalElementShadingHorizontalOffset" type="number" binding={[viewModel, "horizontalOffset", bindingViewModel]}></BindedInput>  
                    </div>
                </div>
                <div className="col-4">
                <div className="position-relative form-group">
                    <label htmlFor="VerticalElementShadingVerticalOffset">Vertical offset (mm) *</label>           
                        <BindedInput className="form-control valid" id="VerticalElementShadingVerticalOffset" name="VerticalElementShadingVerticalOffset" type="number" binding={[viewModel, "verticalOffset", bindingViewModel]}></BindedInput>  
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-12">
                    <label>Blocking factor (opaque = 100%)</label>
                </div>
                <div className="col-1">
                    <div className="position-relative form-group">
                        <label htmlFor="ShadingBlockingFactorPercentage0">Jan</label>           
                        <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage0}</label>
                    </div>
                </div>
                <div className="col-1">
                    <div className="position-relative form-group">
                        <label htmlFor="ShadingBlockingFactorPercentage1">Feb</label>           
                        <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage1}</label>
                    </div>
                </div>
                <div className="col-1">
                    <div className="position-relative form-group">
                        <label htmlFor="ShadingBlockingFactorPercentage2">Mar</label>           
                        <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage2}</label>
                    </div>
                </div>
                <div className="col-1">
                    <div className="position-relative form-group">
                        <label htmlFor="ShadingBlockingFactorPercentage3">Apr</label>           
                        <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage3}</label>
                    </div>
                </div>
                <div className="col-1">
                    <div className="position-relative form-group">
                        <label htmlFor="ShadingBlockingFactorPercentage4">May</label>           
                        <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage4}</label>
                    </div>
                </div>
                <div className="col-1">
                    <div className="position-relative form-group">
                        <label htmlFor="ShadingBlockingFactorPercentage5">Jun</label>           
                        <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage5}</label>
                    </div>
                </div>
                <div className="col-1">
                    <div className="position-relative form-group">
                        <label htmlFor="ShadingBlockingFactorPercentage6">Jul</label>           
                        <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage6}</label>
                    </div>
                </div>
                <div className="col-1">
                    <div className="position-relative form-group">
                        <label htmlFor="ShadingBlockingFactorPercentage7">Aug</label>           
                        <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage7}</label>
                    </div>
                </div>
                <div className="col-1">
                    <div className="position-relative form-group">
                        <label htmlFor="ShadingBlockingFactorPercentage8">Sep</label>           
                        <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage8}</label>
                    </div>
                </div>
                <div className="col-1">
                    <div className="position-relative form-group">
                        <label htmlFor="ShadingBlockingFactorPercentage9">Oct</label>           
                        <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage9}</label>
                    </div>
                </div>
                <div className="col-1">
                    <div className="position-relative form-group">
                        <label htmlFor="ShadingBlockingFactorPercentage10">Nov</label>           
                        <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage10}</label>
                    </div>
                </div>
                <div className="col-1">
                    <div className="position-relative form-group">
                        <label htmlFor="ShadingBlockingFactorPercentage11">Dec</label>           
                        <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage11}</label>
                    </div>
                </div>
            </div>
        </BaseForm>
    </Fragment>);
}
export default connectAppStore(MultipleExternalScreensDetail);