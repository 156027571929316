import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";

const OpaqueLouvresInElementsDataTable = (props) => {
    const {
        useParams,
        tryCatchFinally,
        stringCoding,
        layoutManager,
        pageProfile,
        getData,
        guid,
        toastPrint,
        Fragment, 
        useEffect, 
        useState,
        useLocation,
        useNavigate,
        dataExtension,
        ServerSideDataTable,
        ModalDatatableItem,
    } = PageDependencies();
    const emptyGuid = guid.emptyGuid()
    var tableId = props.id === undefined ? emptyGuid : props.id;
    const redirectPathHex = stringCoding.encode(useLocation().pathname);
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const {designVariationVersionName, projectId, designVariationId} = defaultParams;
    const [extraPostModel, setExtraPostModel] = useState({
      projectId: projectId,
      designVariationId: designVariationId,
      designVariationVersionName: designVariationVersionName,
      selectedItemIds: props.externalWallIds === undefined ? [] : props.externalWallIds
    });
    const externalWallId = props.externalWallIds === undefined ? emptyGuid : props.externalWallIds[0];
    const zoneId = emptyGuid;
    const [data, setData] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const getUrl = "api/Element/Accessor/GetOpaqueLouvresInElements";
    const [tableReload, setTableReload] = useState("");
    const [modal, setModal] = useState({
        isOpen: false,
        title: "",
        message: "",
        comfirmButtonStyle: "",
        comfirmButtonText: "Comfirm",
        handleComfirm: () => {},
    });

    const nav = useNavigate();
    const columns = [
        { name: "name", text: "Name", width: "25%" },
        { name: "constructionName", text: "Construction", width: "20%" },
        { name: "height", text: "Height (mm)", width: "15%" },
        { name: "width", text: "Width (mm)", width: "20%" },
        { name: "area", text: "Area (m<sup>2</sup>)", width: "20%" }
    ];

    const generateUrlParams = (elementId) => {
        return "/ElementAccessor/Detail/" + "OpaqueLouvre" + "/" + props.designVariationVersionName + "/" + props.developmentId + "/"+ props.projectId + "/" + props.designVariationId + "/" + zoneId + "/" + elementId + "/" + externalWallId + "/" + redirectPathHex;
    }

    const handleDeleteComfirm = async () => {
        await tryCatchFinally.asyncProcess(async ()=>{
          const selectedItemDic = dataExtension.getSelectedRowsDic(data, "elementType");
          setLoading({isLoading:true,text:"Deleting elements"});
          const selectResponse = await getData.processPost(
          true,
          "api/Element/Accessor/DeleteElements",
              {selectedItemDic: selectedItemDic, designVariationVersionName: designVariationVersionName, projectId: projectId, designVariationId: designVariationId}
          ); 
        toastPrint.printResponse(selectResponse);
        setTableReload(guid.newGuid());
        }, setLoading);
    }

    const handleDuplicatedComfirm = async () => {
        await tryCatchFinally.asyncProcess(async () => {
            const selectedItemDic = dataExtension.getSelectedRowsDic(data, "elementType");
            setLoading({ isLoading: true, text: "Duplicating elements" });
            const selectResponse = await getData.processPost(
                true,
                "api/Element/Accessor/CopyElements",
                { selectedItemDic: selectedItemDic, designVariationVersionName: props.designVariationVersionName, projectId: props.projectId, designVariationId: props.designVariationId, zoneId: zoneId, parentId: externalWallId }
            );
            toastPrint.printResponse(selectResponse);
            setTableReload(guid.newGuid());
        }, setLoading);
    }

    const actionOnDoubleClickRow = (model, newData) =>{
      nav(generateUrlParams(model.id));
    }

    const contextMenu = {
        items: {
          new: {
            name: "New",
            icon: "add",
            defaultAction: true,
            url: generateUrlParams(emptyGuid),
            isNavOption: true,
              disabled: function () {
              if(props.elementTypes === undefined || props.elementTypes.length !== 1) return true;
              if(props.elementTypes[0] != 1) return true;
              return props.externalWallIds.length !== 1;
            },
          },
          separator: "---------",
          edit: {
              name: "Edit",
              icon: "edit",
              defaultAction: false,
                disabled: function () {
                return dataExtension.getSelectedRowDataIds(data).length === 0;
              },
            },
            delete: {
              name: "Delete",
              icon: "delete",
              url: "",
              defaultAction: false,
              disabled: function () {
                return dataExtension.getSelectedRowDataIds(data).length === 0;
              },
            },
            duplicate: {
              name: "Duplicate",
              icon: "copy",
              url: "",
              defaultAction: false,
              disabled: function () {
                return dataExtension.getSelectedRowDataIds(data).length === 0;
              },
            }
        },
        callBack: (
          contextMenuCallBackKey,
          contextMenuCallBackElement,
          contextMenuCallBackTableRoadData,
          localtion
        ) => {   
          if (contextMenuCallBackKey === "edit") {
            const selectedElementIds = dataExtension.getSelectedRowDataIds(data);
            if(selectedElementIds.length === 1){
              nav(generateUrlParams(selectedElementIds[0]));
            }else{
              const urlParams = props.designVariationVersionName + "/" + props.developmentId + "/" + props.projectId + "/" + props.designVariationId + "/" + zoneId + "/" + stringCoding.encodeObject(selectedElementIds) + "/" + redirectPathHex;
              nav("/ElementAccessor/MultipleDetail/OpaqueLouvre/" + urlParams);
            }
            return;
         }
         if (contextMenuCallBackKey === "delete"){
            setModal({
              ...modal,
              isOpen: true,
              title: "Delete elements",
              message:
                "Are sure to delete " +
                dataExtension.getSelectedRowDataIds(data).length +
                " selected elments?",
              comfirmButtonStyle: "danger",
              comfirmButtonText: "Confirm to delete",
              handleComfirm: handleDeleteComfirm,
            });
         }
         if (contextMenuCallBackKey === "duplicate") {
            setModal({
                ...modal,
                isOpen: true,
                title: "Duplicate elements",
                message:
                    "Are sure to duplicate " +
                    dataExtension.getSelectedRowDataIds(data).length +
                    " selected elements?",
                comfirmButtonStyle: "primary",
                comfirmButtonText: "Confirm to duplicate",
                handleComfirm: handleDuplicatedComfirm,
            });
         }
        },
      };

    useEffect(()=>{
      if(props.externalWallIds === undefined) return;
      setExtraPostModel(oldModel=>{
          const newModel = {
              ...oldModel, 
              selectedItemIds: props.externalWallIds
          }
          return newModel;
      });
    },[props.externalWallIds]);

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(dataExtension.getSelectedRowDataIds(data))}</p>}
            {debug && <p>{JSON.stringify(extraPostModel)}</p>}
            {debug && <p>redirect Url: {stringCoding.decode(redirectPathHex)}</p>}
            <ModalDatatableItem
            modal={modal}
            setModal={setModal}
            ></ModalDatatableItem>
            <ServerSideDataTable
                id={tableId}
                location="Elements"
                data={data}
                setData={setData}
                reload={tableReload}
                columns={columns}
                actionOnDoubleClickRow = {actionOnDoubleClickRow}
                allowSelect
                allowSelectMultiple
                dataSource={{ isAuth: true, url: getUrl }}
                contextMenu={contextMenu}
                extraPostModel={extraPostModel}
                setSelectedItemIds = {setSelectedIds}
            ></ServerSideDataTable>
        </Fragment>
    );
}
export default connectAppStore(OpaqueLouvresInElementsDataTable);