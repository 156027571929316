
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";
import ConstructionElementDataTable from "../../../Accessor/Construction/ConstructionElementDataTable";
import WindowDataTable from "../WindowDataTable";
import DoorDataTable from "../DoorDataTable";
import OpaqueLouvreDataTable from "../OpaqueLouvreDataTable";
import ExternalScreenDataTable from "../ExternalScreenDataTable";
import InfiltrationPenetrationDataTable from "../InfiltrationPenetrationDataTable"
import objectPropertyValueGetSet from "../../../../Function/ObjectPropertyValueGetSet";

const ExternalWallDetail = (props) => {
    const {
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        useLocation,
        useFromDefaultValue,
        BaseFormNoHeader,
        BindedInput,
        BindedCheckBox,
        BindedSelect,
        useEffectWithPrevious,
        getAppStore,
        pageProfile,
        loaModelFromApi,
        FormValidate,
        dataExtension,
        cookiesManager,
        BindedInputNumber,
        htmlToReactParse,
        Fragment, useEffect, useState
    } = PageDependencies();
    const formId = "ExternalWallDetail";
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const currentUrl = useLocation().pathname;
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [reloadForm, setReloadForm] = useState("");
    let {designVariationVersionName, developmentId, projectId, designVariationId, elementId, zoneId, redirectUrlHex} = defaultParams;
    if(zoneId === undefined) zoneId = emptyGuid;
    const defaultViewModel = {
        ...defaultParams,
        id:emptyGuid,
        zoneId: zoneId,
        selectedItemIds: [elementId]
    };
    const [extraPostModelForConstruction, setExtraPostModelForConstruction] = useState({
        developmentId: developmentId,
        projectId: projectId,
        designVariationId: designVariationId,
        designVariationVersionName: designVariationVersionName,
        constructionType:"ExternalWall"
    });
    const [tableReloadForConstruction, setTableReloadForConstruction] = useState("");
    const defaultCookiesObject =cookiesManager.getCookies(formId, {elementTableType: "Doors"}).elementTableType;
    const [elementTableType, setElementTableType] = useState(defaultCookiesObject);
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const postUrl = "api/Element/Accessor/UpdateExternalWall";
    const getUrl = "api/Element/Accessor/GetExternalWall";
    const validateMethods = {
        name:(value)=>{return FormValidate.validateTextRequired(value, "External wall name");},
        constructionId:(value)=>{ return value === emptyGuid ? "Must select a construction." : "";},
        length: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Length");},
        height: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Height");},
        openingArea: (value) => { return FormValidate.validateNumberGreaterThanOrEqual0(value, "OpeningArea");},
        azimuth: (value)=>{return FormValidate.validateNumberBetween(value, "Azimuth", 0, 360);},
        baseHeight: (value) => { return FormValidate.validateNumberGreaterThanOrEqual0(value, "Base height");}
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);
    const [init, setInit] = useState(false);

    useFromDefaultValue("ExternalWallDefaultValue_f4c4706e-dfa7-4414-97b1-9f444ab40112", viewModel, dispatchViewModel, ["constructionId", "height", "baseHeight"], [emptyGuid, 0, 0],
        (v)=>{
            //update cooike condition
            return v.constructionId != emptyGuid || v.height > 0 || v.baseHeight > 0;
        },
        (v)=>{
            //update view model condition
            return v.elementId == guid.emptyGuid() && v.constructionId == emptyGuid && v.height == 0 && v.baseHeight == 0;
    },);

    useEffect(() => {
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading external wall", appStoreDispatch, undefined, setInit);
    },[reloadForm]);

    useEffect(()=>{
        if(init){
            dispatchViewModel({...viewModel, elementId: elementId, id: elementId, selectedItemIds: [elementId]},()=>{
                setReloadForm(guid.newGuid());
            });
        }
    },[elementId]);

    const calculateAzimuth = (value) =>{
        if(value === undefined ) value = 0;
        if(value === null ) value = 0;
        if(value === "" ) value = 0;
        value = Number(value);
        const directions = ["North", "Northeast", "East", "Southeast", "South", "Southwest", "West", "Northwest"];
        const index = Math.round(value / 45) % 8;
        return directions[index];
    }

    const calculateArea = (length, height) => {
        if (length === undefined || length === "" || length === null) length = 0;
        if (height === undefined || height === "" || height === null) height = 0;
        length = Number(length);
        height = Number(height);
        return ((length * height) / 1e6).toFixed(2);
    };

    const generateStayUrl = (submitResponse) => {
        let redirectUrl = currentUrl;
        if (elementId === emptyGuid) {
            //ElementAccessor/Detail/ExternalWall/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:redirectUrlHex
            redirectUrl =  "/ElementAccessor/Detail/ExternalWall/" + designVariationVersionName + "/" + developmentId + "/" + projectId + "/" + designVariationId + "/" + zoneId + "/" + submitResponse.elementId + "/" + redirectUrlHex;
        }
        return redirectUrl;
    }

    const handleTagClick = (elementTableType) => {
        setElementTableType(elementTableType);
        cookiesManager.setCookies(formId, { elementTableType: elementTableType });
    }

    const generateTagClass = (elementType) => {
        let className = elementType === elementTableType ? "active" : "";
        className = "nav-link show " + className;
        return className;
    }

    const getBlockingFactors = (blockingFactor) => {
        const value = objectPropertyValueGetSet.getPropertyValue(viewModel, blockingFactor);
        return value;
    }

    useEffect(()=>{
        const newViewModel = {...viewModel};
        if(newViewModel.ignoreLeft){
            newViewModel.leftWingWallProjection = 0;
            newViewModel.leftWingWallHorizontalOffset = 0;
            newViewModel.leftWingWallVerticalOffset = 0;
        }
        if(newViewModel.ignoreRight){
            newViewModel.rightWingWallProjection = 0;
            newViewModel.rightWingWallHorizontalOffset = 0;
            newViewModel.rightWingWallVerticalOffset = 0;
        }
        dispatchViewModel(newViewModel);
    }, [viewModel.hasWingWalls, viewModel.ignoreLeft, viewModel.ignoreRight])

    useEffect(()=>{
        const newViewModel = {...viewModel};
        if(parseInt(newViewModel.leftWingWallProjection) > 0 && parseInt(newViewModel.rightWingWallProjection) > 0){
            newViewModel.isWallInCourtyard = true;
        }
        dispatchViewModel(newViewModel);
    }, [viewModel.leftWingWallProjection, viewModel.rightWingWallProjection])

    useEffectWithPrevious((oldValue)=>{
        if(oldValue === undefined || oldValue[0] === undefined) return;
        if(viewModel.fixedShadingId != emptyGuid){
            const selected = dataExtension.getSelectOptionByValue(viewModel.fixedShadings, viewModel.fixedShadingId);
            const newModel = {... viewModel};
            newModel.horizontalShadingElement.eaveWidth = 0;
            newModel.horizontalShadingElement.eaveLength = 0;
            newModel.horizontalShadingElement.eaveVerticalOffset = 0;
            newModel.horizontalShadingElement.eaveHorizontalOffset = 0;
            newModel.horizontalShadingElement.eaveProjectionTypeId = 2;
            newModel.horizontalShadingElement.otherFixedShadingWidth = 0
            newModel.horizontalShadingElement.otherFixedShadingLength = 0;
            newModel.horizontalShadingElement.otherFixedShadingVerticalOffset = 0;
            newModel.horizontalShadingElement.otherFixedShadingHorizontalOffset = 0;
            newModel.horizontalShadingElement.otherFixedShadingProjectionTypeId = 2;
            //eave
            if(selected.eaveWidth !== undefined && selected.eaveWidth != null && parseInt(selected.eaveWidth) > 0){
                newModel.horizontalShadingElement.eaveWidth = selected.eaveWidth;
            }
            if(selected.eaveLength !== undefined && selected.eaveLength != null && parseInt(selected.eaveLength) > 0){
                newModel.horizontalShadingElement.eaveLength = selected.eaveLength;
            }
            if(selected.eaveVerticalOffset !== undefined && selected.eaveVerticalOffset != null && parseInt(selected.eaveVerticalOffset) > -999999999999){
                newModel.horizontalShadingElement.eaveVerticalOffset = selected.eaveVerticalOffset;
            }
            if(selected.eaveHorizontalOffset !== undefined && selected.eaveHorizontalOffset != null && parseInt(selected.eaveHorizontalOffset) > -999999999999){
                newModel.horizontalShadingElement.eaveHorizontalOffset = selected.eaveHorizontalOffset;
            }
            if(selected.eaveProjectionTypeId !== undefined && selected.eaveProjectionTypeId != null && parseInt(selected.eaveProjectionTypeId) > -999999999){
                newModel.horizontalShadingElement.eaveProjectionTypeId = selected.eaveProjectionTypeId;
            }
            //pergola
            if(selected.otherFixedShadingWidth !== undefined && selected.otherFixedShadingWidth != null && parseInt(selected.otherFixedShadingWidth) > 0){
                newModel.horizontalShadingElement.otherFixedShadingWidth = selected.otherFixedShadingWidth;
            }
            if(selected.otherFixedShadingLength !== undefined && selected.otherFixedShadingLength != null && parseInt(selected.otherFixedShadingLength) > 0){
                newModel.horizontalShadingElement.otherFixedShadingLength = selected.otherFixedShadingLength;
            }
            if(selected.otherFixedShadingVerticalOffset !== undefined && selected.otherFixedShadingVerticalOffset != null && parseInt(selected.otherFixedShadingVerticalOffset) > -999999999999){
                newModel.horizontalShadingElement.otherFixedShadingVerticalOffset = selected.otherFixedShadingVerticalOffset;
            }
            if(selected.otherFixedShadingHorizontalOffset !== undefined && selected.otherFixedShadingHorizontalOffset != null && parseInt(selected.otherFixedShadingHorizontalOffset) > -999999999999){
                newModel.horizontalShadingElement.otherFixedShadingHorizontalOffset = selected.otherFixedShadingHorizontalOffset;
            }
            if(selected.otherFixedShadingProjectionTypeId !== undefined && selected.otherFixedShadingProjectionTypeId != null && parseInt(selected.otherFixedShadingProjectionTypeId) > -999999999){
                newModel.horizontalShadingElement.otherFixedShadingProjectionTypeId = selected.otherFixedShadingProjectionTypeId;
            }
            dispatchViewModel();
        }
    },[viewModel.fixedShadingId]);

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-safe icon-gradient bg-tempting-azure"></i>
                            </div>
                            <div>
                                External wall
                                <div className="page-title-subheading">
                                    {pageProfile.getPageSubTitle(appStoreState)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-lg-12">
                        <div className="main-card mb-3 card">
                            <BaseFormNoHeader
                                id={formId}
                                submitButton={{ postUrl: postUrl, redirectUrl: viewModel.redirectUrl, text: "Save and close" }}
                                submitButtonAndStay={{ text: "Save", postUrl: postUrl, redirectMethod: (submitResponse) => { return generateStayUrl(submitResponse) } }}
                                cancelButton={{ postUrl: postUrl, redirectUrl: viewModel.redirectUrl }}
                                validate={formValidate}
                                setReload={setReloadForm}
                                loading={loading}
                                init={init}
                                setLoading={setLoading}
                                data={viewModel}
                                cardTitle={"External Wall in " + viewModel.zoneName}
                            >
                                <div className="form-row row">
                                    <div className="col-6">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ExternalWallName">Name *</label>
                                            <BindedInput
                                                className="form-control"
                                                id="ExternalWallName"
                                                name="ExternalWallName"
                                                type="text"
                                                validate={formValidate}
                                                binding={[viewModel, "name", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ZoneId">Zone *</label>
                                            <BindedSelect
                                                className="form-control"
                                                id="ZoneId"
                                                name="ZoneId"
                                                options="zones"
                                                binding={[viewModel, "zoneId", bindingViewModel]}
                                            ></BindedSelect>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="col-12">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ExternalWallConstructionId">
                                                Construction *
                                            </label>
                                            <BindedSelect
                                                className="form-control"
                                                id="ExternalWallConstructionId"
                                                name="ExternalWallConstructionId"
                                                options="constructions"
                                                validate={formValidate}
                                                binding={[
                                                    viewModel,
                                                    "constructionId",
                                                    bindingViewModel,
                                                ]}
                                            ></BindedSelect>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="col-3">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ExternalWallLength">Length (mm) *</label>
                                            <BindedInput
                                                className="form-control"
                                                id="ExternalWallLength"
                                                name="ExternalWallLength"
                                                type="number"
                                                validate={formValidate}
                                                binding={[viewModel, "length", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ExternalWallHeight">Height (mm) *</label>
                                            <BindedInput
                                                className="form-control"
                                                id="ExternalWallHeight"
                                                name="ExternalWallHeight"
                                                type="number"
                                                validate={formValidate}
                                                binding={[viewModel, "height", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ExternalWallAzimuth">
                                                Azimuth{" "}
                                                <span>
                                                    (
                                                    {viewModel.azimuth === "" ||
                                                        viewModel.azimuth === null ||
                                                        viewModel.azimuth === undefined
                                                        ? "0"
                                                        : viewModel.azimuth}
                                                </span>
                                                <sup>o</sup>{" "}
                                                <span>{calculateAzimuth(viewModel.azimuth)})</span>
                                            </label>
                                            <BindedInput
                                                className="form-control"
                                                min="0"
                                                max="360"
                                                id="ExternalWallAzimuth"
                                                name="ExternalWallAzimuth"
                                                type="number"
                                                validate={formValidate}
                                                binding={[viewModel, "azimuth", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ExternalWallGrossArea">
                                                Area (m<sup>2</sup>) *
                                            </label>
                                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}>{calculateArea(viewModel.length, viewModel.height)}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="col-12">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ExternalWallFixedShadingId">
                                                Fixed shade
                                            </label>
                                            <BindedSelect
                                                className="form-control"
                                                id="ExternalWallFixedShadingId"
                                                name="ExternalWallFixedShadingId"
                                                options="fixedShadings"
                                                binding={[
                                                    viewModel,
                                                    "fixedShadingId",
                                                    bindingViewModel,
                                                ]}
                                            ></BindedSelect>
                                        </div>
                                    </div>
                                </div>

                                {viewModel.fixedShadingId !== emptyGuid && viewModel.simulationType === "New Home" && <Fragment>
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <h6>Eave</h6>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveLength">Length (mm)</label>
                                                <BindedInput
                                                    className="form-control"
                                                    id="ExternalWallFixedShading_HorizontalShadingEaveLength"
                                                    name="ExternalWallFixedShading_HorizontalShadingEaveLength"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.eaveLength", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveWidth">Width (mm)</label>
                                                <BindedInput
                                                    className="form-control valid"
                                                    id="ExternalWallFixedShading_HorizontalShadingEaveWidth"
                                                    name="ExternalWallFixedShading_HorizontalShadingEaveWidth"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.eaveWidth", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveProjectionTypeId">Projection</label>
                                                <BindedSelect
                                                    className="form-control"
                                                    id="ExternalWallFixedShading_HorizontalShadingEaveProjectionTypeId"
                                                    name="ExternalWallFixedShading_HorizontalShadingEaveProjectionTypeId"
                                                    options="horizontalShadingElement.eaveProjectionTypes"
                                                    binding={[viewModel, "horizontalShadingElement.eaveProjectionTypeId", bindingViewModel]}
                                                ></BindedSelect>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveVerticalOffset">Vertical offset (mm)</label>
                                                <BindedInput
                                                    className="form-control"
                                                    id="ExternalWallFixedShading_HorizontalShadingEaveVerticalOffset"
                                                    name="ExternalWallFixedShading_HorizontalShadingEaveVerticalOffset"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.eaveVerticalOffset", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveHorizontalOffset">Horizontal offset (mm)</label>
                                                <BindedInput
                                                    className="form-control valid"
                                                    id="ExternalWallFixedShading_HorizontalShadingEaveHorizontalOffset"
                                                    name="ExternalWallFixedShading_HorizontalShadingEaveHorizontalOffset"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.eaveHorizontalOffset", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <h6>Other fixed shading</h6>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingLength">Length (mm)</label>
                                                <BindedInput
                                                    className="form-control"
                                                    id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingLength"
                                                    name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingLength"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.otherFixedShadingLength", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingWidth">Width (mm)</label>
                                                <BindedInput
                                                    className="form-control valid"
                                                    id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingWidth"
                                                    name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingWidth"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.otherFixedShadingWidth", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingProjectionTypeId">Projection</label>
                                                <BindedSelect
                                                    className="form-control"
                                                    id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingProjectionTypeId"
                                                    name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingProjectionTypeId"
                                                    options="horizontalShadingElement.eaveProjectionTypes"
                                                    binding={[viewModel, "horizontalShadingElement.otherFixedShadingProjectionTypeId", bindingViewModel]}
                                                ></BindedSelect>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingVerticalOffset">Vertical offset (mm)</label>
                                                <BindedInput
                                                    className="form-control"
                                                    id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingVerticalOffset"
                                                    name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingVerticalOffset"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.otherFixedShadingVerticalOffset", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingHorizontalOffset">Horizontal offset (mm)</label>
                                                <BindedInput
                                                    className="form-control valid"
                                                    id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingHorizontalOffset"
                                                    name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingHorizontalOffset"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.otherFixedShadingHorizontalOffset", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <label>Blocking factor (opaque = 100%)</label>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage0">Jan</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage0")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage1">Feb</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage1")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage2">Mar</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage2")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage3">Apr</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage3")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage4">May</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage4")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage5">Jun</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage5")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage6">Jul</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage6")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage7">Aug</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage7")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage8">Sep</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage8")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage9">Oct</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage9")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage10">Nov</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage10")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage11">Dec</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage11")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row"></div>
                                </Fragment>}
                                {viewModel.fixedShadingId !== emptyGuid && viewModel.simulationType !== "New Home" &&<Fragment>
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <h6>Eave</h6>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveLength">Eave Length (mm)</label>
                                                <BindedInput
                                                    disabled
                                                    className="form-control"
                                                    id="ExternalWallFixedShading_HorizontalShadingEaveLength"
                                                    name="ExternalWallFixedShading_HorizontalShadingEaveLength"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.eaveLength", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveWidth">Eave Width (mm)</label>
                                                <BindedInput
                                                    disabled
                                                    className="form-control valid"
                                                    id="ExternalWallFixedShading_HorizontalShadingEaveWidth"
                                                    name="ExternalWallFixedShading_HorizontalShadingEaveWidth"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.eaveWidth", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveProjectionTypeId">Projection</label>
                                                <BindedSelect
                                                    disabled
                                                    className="form-control"
                                                    id="ExternalWallFixedShading_HorizontalShadingEaveProjectionTypeId"
                                                    name="ExternalWallFixedShading_HorizontalShadingEaveProjectionTypeId"
                                                    options="horizontalShadingElement.eaveProjectionTypes"
                                                    binding={[viewModel, "horizontalShadingElement.eaveProjectionTypeId", bindingViewModel]}
                                                ></BindedSelect>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveVerticalOffset">Eave Vertical offset (mm)</label>
                                                <BindedInput
                                                    disabled
                                                    className="form-control"
                                                    id="ExternalWallFixedShading_HorizontalShadingEaveVerticalOffset"
                                                    name="ExternalWallFixedShading_HorizontalShadingEaveVerticalOffset"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.eaveVerticalOffset", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveHorizontalOffset">Eave Horizontal offset (mm)</label>
                                                <BindedInput
                                                    disabled
                                                    className="form-control valid"
                                                    id="ExternalWallFixedShading_HorizontalShadingEaveHorizontalOffset"
                                                    name="ExternalWallFixedShading_HorizontalShadingEaveHorizontalOffset"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.eaveHorizontalOffset", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <h6>Other fixed shading</h6>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingLength">Overhang Length (mm)</label>
                                                <BindedInput
                                                    disabled
                                                    className="form-control"
                                                    id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingLength"
                                                    name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingLength"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.otherFixedShadingLength", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingWidth">Overhang Width (mm)</label>
                                                <BindedInput
                                                    disabled
                                                    className="form-control valid"
                                                    id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingWidth"
                                                    name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingWidth"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.otherFixedShadingWidth", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingProjectionTypeId">Projection</label>
                                                <BindedSelect
                                                    disabled
                                                    className="form-control"
                                                    id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingProjectionTypeId"
                                                    name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingProjectionTypeId"
                                                    options="horizontalShadingElement.eaveProjectionTypes"
                                                    binding={[viewModel, "horizontalShadingElement.otherFixedShadingProjectionTypeId", bindingViewModel]}
                                                ></BindedSelect>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingVerticalOffset">Overhang Vertical offset (mm)</label>
                                                <BindedInput
                                                    disabled
                                                    className="form-control"
                                                    id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingVerticalOffset"
                                                    name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingVerticalOffset"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.otherFixedShadingVerticalOffset", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingHorizontalOffset">Overhang Horizontal offset (mm)</label>
                                                <BindedInput
                                                    disabled
                                                    className="form-control valid"
                                                    id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingHorizontalOffset"
                                                    name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingHorizontalOffset"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.otherFixedShadingHorizontalOffset", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="form-row row">
                                        <div className="col-12">
                                            <label>Blocking factor (opaque = 100%)</label>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage0">Jan</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage0")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage1">Feb</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage1")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage2">Mar</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage2")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage3">Apr</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage3")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage4">May</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage4")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage5">Jun</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage5")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage6">Jul</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage6")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage7">Aug</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage7")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage8">Sep</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage8")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage9">Oct</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage9")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage10">Nov</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage10")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage11">Dec</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage11")}</label>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="form-row row"></div>
                                </Fragment>}
                                <div className="form-row row">
                                    <div className="col-3">
                                        <div className="position-relative form-group">
                                            <BindedInputNumber inputId="ExternalWallOpeningArea" label={htmlToReactParse("Opening area (m<sup>2</sup>) *")} min={0} minFractionDigits={0} maxFractionDigits={2} binding={[viewModel, "openingArea", bindingViewModel]} validate = {formValidate}/>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ExternalWallOpeningTypeId">
                                                Opening type *
                                            </label>
                                            <BindedSelect
                                                className="form-control"
                                                id="ExternalWallOpeningTypeId"
                                                name="ExternalWallOpeningTypeId"
                                                options="openingTypes"
                                                validate={formValidate}
                                                binding={[viewModel, "openingTypeId", bindingViewModel]}
                                            ></BindedSelect>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="custom-checkbox custom-control">
                                        <BindedCheckBox
                                            id="ExternalWallHasCompositeWall"
                                            name="ExternalWallHasCompositeWall"
                                            className="custom-control-input"
                                            binding={[
                                                viewModel,
                                                "hasCompositeWall",
                                                bindingViewModel,
                                            ]}
                                        ></BindedCheckBox>
                                        <label
                                            style={{ marginLeft: "5px" }}
                                            htmlFor="ExternalWallHasCompositeWall"
                                            className="custom-control-label"
                                        >
                                            Composite wall
                                        </label>
                                    </div>
                                    {viewModel.hasCompositeWall && (
                                        <Fragment>
                                            <div className="col-3">
                                                <div className="position-relative form-group">
                                                    <label htmlFor="ExternalWallAboveWallId">
                                                        Wall above
                                                    </label>
                                                    <BindedSelect
                                                        className="form-control"
                                                        id="ExternalWallAboveWallId"
                                                        name="ExternalWallAboveWallId"
                                                        options="aboveWalls"
                                                        validate={formValidate}
                                                        binding={[
                                                            viewModel,
                                                            "aboveWallId",
                                                            bindingViewModel,
                                                        ]}
                                                    ></BindedSelect>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="position-relative form-group">
                                                    <label htmlFor="ExternalWallBelowWallId">
                                                        Wall below
                                                    </label>
                                                    <BindedSelect
                                                        className="form-control"
                                                        id="ExternalWallBelowWallId"
                                                        name="ExternalWallBelowWallId"
                                                        options="belowWalls"
                                                        validate={formValidate}
                                                        binding={[
                                                            viewModel,
                                                            "belowWallId",
                                                            bindingViewModel,
                                                        ]}
                                                    ></BindedSelect>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                                <div className="form-row row">
                                    <div className="col-2">
                                        <div className="custom-checkbox custom-control">
                                            <BindedCheckBox
                                                id="ExternalWallHasWingWalls"
                                                name="ExternalWallHasWingWalls"
                                                className="custom-control-input"
                                                binding={[
                                                    viewModel,
                                                    "hasWingWalls",
                                                    bindingViewModel,
                                                ]}
                                            ></BindedCheckBox>
                                            <label
                                                style={{ marginLeft: "5px" }}
                                                htmlFor="ExternalWallHasWingWalls"
                                                className="custom-control-label"
                                            >
                                                Wing walls
                                            </label>
                                        </div>
                                    </div>
                                    {viewModel.hasWingWalls && viewModel.simulationType === "Existing Home" &&
                                        <>
                                            <div className="col-2">
                                                <div className="custom-checkbox custom-control">
                                                    <BindedCheckBox
                                                        id="IgnoreLeft"
                                                        name="IgnoreLeft"
                                                        className="custom-control-input"
                                                        binding={[
                                                            viewModel,
                                                            "ignoreLeft",
                                                            bindingViewModel,
                                                        ]}
                                                    ></BindedCheckBox>
                                                    <label
                                                        style={{ marginLeft: "5px" }}
                                                        htmlFor="IgnoreLeft"
                                                        className="custom-control-label"
                                                    >
                                                        Ignore Left
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="custom-checkbox custom-control">
                                                    <BindedCheckBox
                                                        id="IgnoreRight"
                                                        name="IgnoreRight"
                                                        className="custom-control-input"
                                                        binding={[
                                                            viewModel,
                                                            "ignoreRight",
                                                            bindingViewModel,
                                                        ]}
                                                    ></BindedCheckBox>
                                                    <label
                                                        style={{ marginLeft: "5px" }}
                                                        htmlFor="IgnoreRight"
                                                        className="custom-control-label"
                                                    >
                                                        Ignore Right
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <p>Wing walls may be ignored if:                                                                         </p>
                                                <p>-The depth of the wing wall is less than or equal to 2 metres                                         </p>
                                                <p>-The wing wall is on the southern side of the wall/window                                             </p>
                                                <p>-The top of the wing wall is less than 0.6metres or more below the top of the wall/window             </p>
                                                <p>-The horizontal offset of the wing wall is greater than the depth of the wing wall                    </p>
                                            </div>
                                        </>                                                          
                                    }
                                    <div className="col-3">
                                        <div className="custom-checkbox custom-control">
                                            <BindedCheckBox
                                                disabled = {parseInt(viewModel.leftWingWallProjection) > 0 && parseInt(viewModel.rightWingWallProjection) > 0}
                                                id="IsWallInCourtyard"
                                                name="IsWallInCourtyard"
                                                className="custom-control-input"
                                                binding={[
                                                    viewModel,
                                                    "isWallInCourtyard",
                                                    bindingViewModel,
                                                ]}
                                            ></BindedCheckBox>
                                            <label
                                                style={{ marginLeft: "5px" }}
                                                htmlFor="IsWallInCourtyard"
                                                className="custom-control-label"
                                            >
                                                Wall is part of courtyard
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {viewModel.hasWingWalls && <Fragment>
                                    <div className="form-row row">
                                        <div id="ExternalWallWingWallsDiv" className="pl-4">
                                            <div>
                                                <h6>Left wing wall</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallLeftWingWallProjection">
                                                    Projection (mm)
                                                </label>
                                                <BindedInput
                                                    className="form-control"
                                                    min="0"
                                                    id="ExternalWallLeftWingWallProjection"
                                                    name="ExternalWallLeftWingWallProjection"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "leftWingWallProjection", bindingViewModel]}
                                                    disabled={viewModel.ignoreLeft}
                                                ></BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallLeftWingWallHorizontalOffset">
                                                    Horizontal offset (mm)
                                                </label>
                                                <BindedInput
                                                    className="form-control"
                                                    min="0"
                                                    id="ExternalWallLeftWingWallHorizontalOffset"
                                                    name="ExternalWallLeftWingWallHorizontalOffset"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "leftWingWallHorizontalOffset", bindingViewModel]}
                                                    disabled={viewModel.ignoreLeft}
                                                ></BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallLeftWingWallVerticalOffset">
                                                    Vertical offset (mm)
                                                </label>
                                                <BindedInput
                                                    className="form-control"
                                                    min="0"
                                                    id="ExternalWallLeftWingWallVerticalOffset"
                                                    name="ExternalWallLeftWingWallVerticalOffset"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "leftWingWallVerticalOffset", bindingViewModel]}
                                                    disabled={viewModel.ignoreLeft}
                                                ></BindedInput>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div id="ExternalWallWingWallsDiv" className="pl-4">
                                            <div>
                                                <h6 style={{ marginTop: "5px" }}>Right wing wall</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallRightWingWallProjection">
                                                    Projection (mm)
                                                </label>
                                                <BindedInput
                                                    className="form-control"
                                                    min="0"
                                                    id="ExternalWallRightWingWallProjection"
                                                    name="ExternalWallRightWingWallProjection"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "rightWingWallProjection", bindingViewModel]}
                                                    disabled={viewModel.ignoreRight}
                                                ></BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallLeftWingWallHorizontalOffset">
                                                    Horizontal offset (mm)
                                                </label>
                                                <BindedInput
                                                    className="form-control"
                                                    min="0"
                                                    id="ExternalWallLeftWingWallHorizontalOffset"
                                                    name="ExternalWallRightWingWallHorizontalOffset"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "rightWingWallHorizontalOffset", bindingViewModel]}
                                                    disabled={viewModel.ignoreRight}
                                                ></BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallRightWingWallVerticalOffset">
                                                    Vertical offset (mm)
                                                </label>
                                                <BindedInput
                                                    className="form-control"
                                                    min="0"
                                                    id="ExternalWallRightWingWallVerticalOffset"
                                                    name="ExternalWallRightWingWallVerticalOffset"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "rightWingWallVerticalOffset", bindingViewModel]}
                                                    disabled={viewModel.ignoreRight}
                                                ></BindedInput>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>}
                                <div className="form-row row">
                                    <div className="col-3">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ExternalWallBaseHeight">
                                                Base height (mm) *
                                            </label>
                                            <BindedInput
                                                className="form-control"
                                                min="0"
                                                id="ExternalWallBaseHeight"
                                                name="ExternalWallBaseHeight"
                                                type="number"
                                                validate={formValidate}
                                                binding={[viewModel, "baseHeight", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                </div>
                            </BaseFormNoHeader>
                        </div>
                    </div>
                    {init === true && <>
                        <div className="col-xl-6 col-lg-12">
                        <div className="row">
                            <div className="main-card mb-3 card">
                                <div className="card-header">
                                    <button type="button" data-toggle="collapse" data-target="#ConstructionsCollapsibleDiv" aria-expanded="true" aria-controls="ConstructionsCollapsibleDiv" className="text-left m-0 p-0 btn btn-block">
                                        <h5 className="m-0 p-0 card-title">External Wall Constructions</h5>
                                    </button>
                                </div>
                                <div data-parent="#ConstructionsAccordion" id="ConstructionsCollapsibleDiv" className="collapse show">
                                    <div className="card-body">
                                        <div className="separator"></div>
                                        <ConstructionElementDataTable
                                            tableId={"ExternalWallElement-b578d85b-8369-4b83-87e1-887dd8514ae3"}
                                            tableReload={tableReloadForConstruction}
                                            setTableReload={setTableReloadForConstruction}
                                            extraPostModel={extraPostModelForConstruction}
                                            constructionType="ExternalWall"
                                            redirectUrl={currentUrl}
                                            debug={debug}
                                        >
                                        </ConstructionElementDataTable>
                                    </div>
                                </div>
                            </div>
                            {elementId !== emptyGuid &&
                                <div className="main-card mb-3 card">
                                    <div className="card-header">
                                        <ul className="nav nav-justified">
                                            <li className="nav-item" onClick={() => handleTagClick("Doors")}><a className={generateTagClass("Doors")}>Doors</a></li>
                                            <li className="nav-item" onClick={() => handleTagClick("Windows")}><a className={generateTagClass("Windows")}>Windows</a></li>
                                            <li className="nav-item" onClick={() => handleTagClick("OpaqueLouvres")}><a className={generateTagClass("OpaqueLouvres")}>Opaque louvres</a></li>
                                            <li className="nav-item" onClick={() => handleTagClick("ExternalScreens")}><a className={generateTagClass("ExternalScreens")}>External screens</a></li>
                                            <li className="nav-item" onClick={() => handleTagClick("InfiltrationPenetrations")}><a className={generateTagClass("InfiltrationPenetrations")}>Infiltration penetrations</a></li>
                                        </ul>
                                    </div>
                                    <div data-parent="#ConstructionsAccordion" id="ConstructionsCollapsibleDiv" className="collapse show">
                                        <div className="card-body">
                                            <div className="separator"></div>
                                            {elementTableType === 'Doors' &&
                                                <DoorDataTable
                                                    id="DoorTable_802977eb-8fe0-452e-a186-49d6ac0d8028"
                                                    externalWallId={elementId}
                                                    designVariationVersionName={designVariationVersionName}
                                                    developmentId={developmentId}
                                                    projectId={projectId}
                                                    designVariationId={designVariationId}
                                                    zoneId={zoneId}
                                                ></DoorDataTable>
                                            }
                                            {elementTableType === 'OpaqueLouvres' &&
                                                <OpaqueLouvreDataTable
                                                    id="OpaqueLouvre_4c48bc2e-ed4c-4424-929a-d47c01f1f247"
                                                    externalWallId={elementId}
                                                    designVariationVersionName={designVariationVersionName}
                                                    developmentId={developmentId}
                                                    projectId={projectId}
                                                    designVariationId={designVariationId}
                                                    zoneId={zoneId}
                                                ></OpaqueLouvreDataTable>
                                            }
                                            {elementTableType === 'ExternalScreens' &&
                                                <ExternalScreenDataTable
                                                    id="ExternalScreen_3d0c1c73-1f6b-403e-943e-13b7321f7521"
                                                    externalWallId={elementId}
                                                    designVariationVersionName={designVariationVersionName}
                                                    developmentId={developmentId}
                                                    projectId={projectId}
                                                    designVariationId={designVariationId}
                                                    zoneId={zoneId}
                                                ></ExternalScreenDataTable>
                                            }
                                            {elementTableType === 'InfiltrationPenetrations' &&
                                                <InfiltrationPenetrationDataTable
                                                    id="InfiltrationPenetration_5d9253a7-75f7-4296-a28f-32c9d0faee09"
                                                    elementType="ExternalWall"
                                                    elementId={elementId}
                                                    designVariationVersionName={designVariationVersionName}
                                                    developmentId={developmentId}
                                                    projectId={projectId}
                                                    designVariationId={designVariationId}
                                                    zoneId={zoneId}
                                                ></InfiltrationPenetrationDataTable>
                                            }
                                            {elementTableType === 'Windows' &&
                                                <WindowDataTable
                                                    id="Windows_406f14ca-b0d2-4a93-9dbd-93fd9a52c55a"
                                                    externalWallId={elementId}
                                                    designVariationVersionName={designVariationVersionName}
                                                    developmentId={developmentId}
                                                    projectId={projectId}
                                                    designVariationId={designVariationId}
                                                    zoneId={zoneId}
                                                ></WindowDataTable>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    </>}        
                </div>
            </div>
        </Fragment>
    );
}
export default connectAppStore(ExternalWallDetail);
