import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const BridgeMaterialDetail = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        layoutManager,
        useReducerSmart,
        BindedInput,
        BindedCheckBox,
        BindedSelect,
        BindedInputNumber,
        FormValidate,
        useEffectWithPrevious
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const constructionType = props.constructionType === undefined ? "" : props.constructionType;
    const bridgeMaterials = props.bridgeMaterials === undefined ? [] : props.bridgeMaterials;
    const bridgeMaterialFrameTypes = props.bridgeMaterialFrameTypes === undefined ? [] : props.bridgeMaterialFrameTypes;
    const bridgeMaterialAirGapTypes = props.bridgeMaterialAirGapTypes === undefined ? [] : props.bridgeMaterialAirGapTypes;
    const floorCeilingConstructionTypes = props.floorCeilingConstructionTypes === undefined ? [] : props.floorCeilingConstructionTypes;
    let defaultViewModel = props.bridgeViewModel === undefined ? {} : props.bridgeViewModel;
    defaultViewModel = {...defaultViewModel, bridgeMaterials: bridgeMaterials, bridgeMaterialFrameTypes: bridgeMaterialFrameTypes, bridgeMaterialAirGapTypes: bridgeMaterialAirGapTypes, floorCeilingConstructionTypes: floorCeilingConstructionTypes};
    let [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel, false);
    const quickSelectOptions = [{value:"0", text:""}, {value:"1", text:"140x45"}, {value:"2", text:"90x45"}];
    const [quickSelectViewModel, dispatchQuickSelectViewModel, bindingQuickSelectViewViewModel] = useReducerSmart({studSelectId: '0', noggingSelectId: '0', quickSelectOptions: quickSelectOptions});
    let [isOpen, setIsOpen] = useState(false);
    isOpen = props.isOpen === undefined ? isOpen : props.isOpen;
    setIsOpen = props.setIsOpen === undefined ? setIsOpen : props.setIsOpen;
    let [bridgeIndex, setBridgeIndex] = useState(1);
    bridgeIndex = props.bridgeIndex === undefined ? bridgeIndex : props.bridgeIndex;
    setBridgeIndex = props.setBridgeIndex === undefined ? setBridgeIndex : props.setBridgeIndex;
    const validateMethods = {
        baseMetalThickness:(value)=>{ return FormValidate.validateNumberGreaterThan0(value, "Base Metal Thickness"); },
        floorCeilingConstructionTypeId:(value)=>{ 
            if((constructionType.includes("Floor") || constructionType.includes("Ceiling")) && parseInt(value) < 0)
            {
                return "Select a floor ceiling type."
            }
            return "";
        },
        airGapThickness: (value, n)=>{
            if( n.bridgeMaterialIndex >= 918 && n.bridgeMaterialIndex <= 920){
                return FormValidate.validateNumberGreaterThan0(value, "Air Gap Thickness");
            }
            return "";
        },
        airGapWidth: (value, n)=>{
            if( n.bridgeMaterialIndex >= 918 && n.bridgeMaterialIndex <= 920){
                return FormValidate.validateNumberGreaterThan0(value, "Air Gap Width");
            }
            return "";
        },
        thermalBreak: (value, n)=>{
            if( n.hasThermalBreakApplied){
                return FormValidate.validateNumberGreaterThan0(value, "R-Value");
            }
            return "";
        },
        noggingDepth: (value)=>{ return FormValidate.validateNumberGreaterThan0(value, "Nogging Depth"); },
        noggingSpacing: (value, n)=>{ 
            if(parseInt(value) == 0 && parseInt(n.studSpacing) == 0){
                return "one of spacing must be greater than 0";
            }
            return "";
        },
        noggingWidth: (value)=>{ return FormValidate.validateNumberGreaterThan0(value, "Nogging Width"); },
        studDepth: (value)=>{ return FormValidate.validateNumberGreaterThan0(value, "Stud Depth"); },
        studSpacing: (value, n)=>{ 
            if(parseInt(value) == 0 && parseInt(n.noggingSpacing) == 0){
                return "one of spacing must be greater than 0";
            }
            return "";
        },
        studWidth: (value)=>{ return FormValidate.validateNumberGreaterThan0(value, "Stud Width"); },
      };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);
    let handleSave = props.handleSave === undefined ? (vm, va, bi, li)=>{} : props.handleSave;

    useEffect(()=>{
        dispatchViewModel(defaultViewModel);
    },[props.bridgeViewModel]);

    useEffect(()=>{
        let newFrameId = 2;
        if(parseInt(viewModel.bridgeMaterialIndex) == 45){
            newFrameId = 1;
        }
        const newModel = {...viewModel, bridgeMaterialFrameTypeId: newFrameId};
        dispatchViewModel(newModel);
    }, [viewModel.bridgeMaterialIndex]);

    useEffect(()=>{
        let fraction = 0;
        if(viewModel.isCalculateFraction){
            if(viewModel.studSpacing != 0 && viewModel.noggingSpacing != 0){
                fraction = viewModel.studWidth / viewModel.studSpacing + viewModel.noggingWidth/viewModel.noggingSpacing - (viewModel.studWidth * viewModel.noggingWidth)/(viewModel.studSpacing * viewModel.noggingSpacing);
            }
            if(viewModel.studSpacing != 0 && viewModel.noggingSpacing == 0){
                fraction = viewModel.studWidth / viewModel.studSpacing;
            }
            if(viewModel.studSpacing == 0 && viewModel.noggingSpacing != 0){
                fraction = viewModel.noggingWidth / viewModel.noggingSpacing;
            }
        }
        dispatchViewModel({...viewModel, fraction: fraction.toFixed(4)});
    },[viewModel.isCalculateFraction]);


    useEffect(()=>{
        if(viewModel.hasThermalBreakApplied && viewModel.thermalBreak == 0){
            dispatchViewModel({...viewModel, thermalBreak: 0.2});
        }
        if(viewModel.hasThermalBreakApplied === false){
            dispatchViewModel({...viewModel, thermalBreak: 0});
        }
    },[viewModel.hasThermalBreakApplied]);

    useEffectWithPrevious((oldDependency)=>{
        if(oldDependency === undefined) return;
        if(oldDependency[0] === undefined) return;
        let d = 0;
        let w = 0;
        if(quickSelectViewModel.studSelectId == "1"){
            d = 140;
            w = 45;
        }
        if(quickSelectViewModel.studSelectId == "2"){
            d = 90;
            w = 45;
        }
        if(d > 0 && w > 0){
            dispatchViewModel({...viewModel, studDepth: d, studWidth: w});
            if(quickSelectViewModel.studSelectId != "0"){
                dispatchQuickSelectViewModel({...quickSelectViewModel, studSelectId: 0});
            }
        }
    }, [quickSelectViewModel.studSelectId]);

    useEffectWithPrevious((oldDependency)=>{
        if(oldDependency === undefined) return;
        if(oldDependency[0] === undefined) return;
        let d = 0;
        let w = 0;
        if(quickSelectViewModel.noggingSelectId == "1"){
            d = 140;
            w = 45;
        }
        if(quickSelectViewModel.noggingSelectId == "2"){
            d = 90;
            w = 45;
        }
        if(d > 0 && w > 0){
            dispatchViewModel({...viewModel, noggingDepth: d, noggingWidth: w});
            if(quickSelectViewModel.noggingSelectId != "0" && quickSelectViewModel.noggingSelectId != 0){
                dispatchQuickSelectViewModel({...quickSelectViewModel, noggingSelectId: 0});
            }
        }
    }, [quickSelectViewModel.noggingSelectId]);

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            <span className="d-inline-block mb-4 me-4">
                <Modal isOpen={isOpen} className={props.className} centered={true}>
                    <ModalHeader>Bridge {bridgeIndex}</ModalHeader>
                    <ModalBody>
                    {(constructionType.includes("Floor") || constructionType.includes("Ceiling")) &&
                        <div className="row">
                            <div className="col-12">
                                <div className="position-relative form-group">
                                    <label htmlFor="FloorCeilingConstructionTypeId">Floor or Ceiling</label>           
                                    <BindedSelect style={{marginBottom:"10px"}} className="form-control" id="FloorCeilingConstructionTypeId" name="FloorCeilingConstructionTypeId" validate={formValidate} binding={[viewModel, "floorCeilingConstructionTypeId", bindingViewModel]} options="floorCeilingConstructionTypes"></BindedSelect>                
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-12">
                            <div className="position-relative form-group">
                                <label htmlFor="BridgeMaterialFrameTypeId">Frame</label>           
                                <BindedSelect style={{marginBottom:"10px"}} className="form-control" id="BridgeMaterialFrameTypeId" name="BridgeMaterialFrameTypeId" validate={formValidate} binding={[viewModel, "bridgeMaterialFrameTypeId", bindingViewModel]} options="bridgeMaterialFrameTypes" disabled></BindedSelect>                
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="position-relative form-group">
                                <label htmlFor="BridgeMaterial">Bridge Material *</label>           
                                <BindedSelect style={{marginBottom:"10px"}} className="form-control" id="BridgeMaterial" name="BridgeMaterial" validate={formValidate} binding={[viewModel, "bridgeMaterialIndex", bindingViewModel]} options="bridgeMaterials"></BindedSelect>                
                            </div>
                        </div>
                    </div>
                    {(viewModel.bridgeMaterialIndex >= 918 && viewModel.bridgeMaterialIndex <= 920) &&
                        <div className="row">
                            <div className="col-6">
                                <div className="position-relative form-group">
                                    <label htmlFor="AirGapThickness">Air Gap Thickness *</label>           
                                    <BindedInput className="form-control valid" id="AirGapThickness" name="AirGapThickness" type="number" validate={formValidate} binding={[viewModel, "airGapThickness", bindingViewModel]}></BindedInput>       
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="position-relative form-group">
                                    <label htmlFor="AirGapWidth">Air Gap Width *</label>           
                                    <BindedInput className="form-control valid" id="AirGapWidth" name="AirGapWidth" type="number" validate={formValidate} binding={[viewModel, "airGapWidth", bindingViewModel]}></BindedInput>       
                                </div>
                            </div>
                        </div>  
                    }
                    <div className="row">                
                        <div className="col-6">
                            <div className="position-relative form-group">
                                <label htmlFor="BaseMetalThickness">Base Metal Thickness *</label>           
                                <BindedInput style={{marginBottom:"10px"}} className="form-control valid" id="BaseMetalThickness" name="BaseMetalThickness" type="number" step={0.1} validate={formValidate} binding={[viewModel, "baseMetalThickness", bindingViewModel]}></BindedInput>       
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="position-relative form-group">
                                <label htmlFor="AirGapTypeId">Air Gap Type *</label>           
                                <BindedSelect style={{marginBottom:"10px"}} className="form-control" id="AirGapTypeId" name="AirGapTypeId" validate={formValidate} binding={[viewModel, "airGapTypeId", bindingViewModel]} options="bridgeMaterialAirGapTypes"></BindedSelect>                
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">   
                            <div className="custom-checkbox custom-control">
                                <BindedCheckBox
                                    id="HasThermalBreakApplied" 
                                    name="HasThermalBreakApplied"
                                    className="custom-control-input"
                                    binding={[viewModel, "hasThermalBreakApplied", bindingViewModel]}
                                ></BindedCheckBox>
                                <label style={{marginLeft:"5px"}} htmlFor="HasThermalBreakApplied" className="custom-control-label">Thermal Break Applied</label>                          
                            </div>
                        </div>
                        {viewModel.hasThermalBreakApplied && 
                            <div className="col-6">
                                <div className="position-relative form-group">
                                    <BindedInputNumber inputId="ThermalBreak" label="R-Value" min={0} max={1} minFractionDigits={0} maxFractionDigits={1} step={0.1} binding={[viewModel, "thermalBreak", bindingViewModel]} validate = {formValidate}/>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className="col-6">   
                            <div className="custom-checkbox custom-control">
                                <BindedCheckBox
                                    id="IsCalculateFraction" 
                                    name="IsCalculateFraction"
                                    className="custom-control-input"
                                    binding={[viewModel, "isCalculateFraction", bindingViewModel]}
                                ></BindedCheckBox>
                                <label style={{marginLeft:"5px"}} htmlFor="IsCalculateFraction" className="custom-control-label">Calculate Fraction</label>                          
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="position-relative form-group">
                                <label htmlFor="Fraction">Fraction *</label>           
                                <BindedInput className="form-control valid" style={{marginBottom: "10px"}} id="Fraction" name="Fraction" type="number" validate={formValidate} binding={[viewModel, "fraction", bindingViewModel] } disabled ={viewModel.isCalculateFraction}></BindedInput>       
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <h5>Stud</h5>
                        </div>
                        <div className="col-6">
                            <h5>Nogging</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">   
                            <div className="position-relative form-group">
                                <label htmlFor="StudSelectId">Depth x Width Qiuck Select</label>           
                                <BindedSelect style={{marginBottom:"10px"}} className="form-control" id="StudSelectId" name="StudSelectId" validate={formValidate} binding={[quickSelectViewModel, "studSelectId", bindingQuickSelectViewViewModel]} options="quickSelectOptions" disabled = {viewModel.bridgeMaterialFrameTypeId == 1}></BindedSelect>                
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="position-relative form-group">
                                <label htmlFor="NoggingSelectId">Depth x Width Qiuck Select</label>           
                                <BindedSelect style={{marginBottom:"10px"}} className="form-control" id="NoggingSelectId" name="NoggingSelectId" validate={formValidate} binding={[quickSelectViewModel, "noggingSelectId", bindingQuickSelectViewViewModel]} options="quickSelectOptions" disabled = {viewModel.bridgeMaterialFrameTypeId == 1}></BindedSelect>                
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">   
                            <div className="position-relative form-group">
                                <label htmlFor="StudDepth">Stud Depth (mm)</label>           
                                <BindedInput className="form-control valid" style={{marginBottom: "10px"}} id="StudDepth" name="StudDepth" type="number" step="1" validate={formValidate} binding={[viewModel, "studDepth", bindingViewModel] }></BindedInput>       
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="position-relative form-group">
                                <label htmlFor="NoggingDepth">Nogging Depth (mm)</label>           
                                <BindedInput className="form-control valid" style={{marginBottom: "10px"}} id="NoggingDepth" name="NoggingDepth" type="number" step="1" validate={formValidate} binding={[viewModel, "noggingDepth", bindingViewModel] }></BindedInput>       
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">   
                            <div className="position-relative form-group">
                                <label htmlFor="StudWidth">Stud Width (mm)</label>           
                                <BindedInput className="form-control valid" style={{marginBottom: "10px"}} id="StudWidth" name="StudWidth" type="number" step="1" validate={formValidate} binding={[viewModel, "studWidth", bindingViewModel] }></BindedInput>       
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="position-relative form-group">
                                <label htmlFor="NoggingWidth">Nogging Width (mm)</label>           
                                <BindedInput className="form-control valid" style={{marginBottom: "10px"}} id="NoggingWidth" name="NoggingWidth" type="number" step="1" validate={formValidate} binding={[viewModel, "noggingWidth", bindingViewModel] }></BindedInput>       
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">   
                            <div className="position-relative form-group">
                                <label htmlFor="StudSpacing">Stud Spacing (mm)</label>           
                                <BindedInput className="form-control valid" style={{marginBottom: "10px"}} id="StudSpacing" name="StudSpacing" type="number" step="1" validate={formValidate} binding={[viewModel, "studSpacing", bindingViewModel] }></BindedInput>       
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="position-relative form-group">
                                <label htmlFor="NoggingSpacing">Nogging Spacing (mm)</label>           
                                <BindedInput className="form-control valid" style={{marginBottom: "10px"}} id="NoggingSpacing" name="NoggingSpacing" type="number" step="1" validate={formValidate} binding={[viewModel, "noggingSpacing", bindingViewModel] }></BindedInput>       
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">   
                            <div className="position-relative form-group">
                                <label htmlFor="StudFlangeWidth">Flange Width (mm)</label>           
                                <BindedInput className="form-control valid" style={{marginBottom: "10px"}} id="StudFlangeWidth" name="StudFlangeWidth" type="number" step="1" validate={formValidate} binding={[viewModel, "studFlangeWidth", bindingViewModel] } disabled></BindedInput>       
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="position-relative form-group">
                                <label htmlFor="NoggingFlangeWidth">Flange Width (mm)</label>           
                                <BindedInput className="form-control valid" style={{marginBottom: "10px"}} id="NoggingFlangeWidth" name="NoggingFlangeWidth" type="number" step="1" validate={formValidate} binding={[viewModel, "noggingFlangeWidth", bindingViewModel] } disabled></BindedInput>       
                            </div>
                        </div>
                    </div>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={()=>{setIsOpen(false);}} type='button' className='btn btn-secondary' data-dismiss='modal'>Close</button>
                        <button onClick={()=>handleSave(viewModel, formValidate, bridgeIndex)} type='button' className='btn btn-primary'>Save</button>
                    </ModalFooter>
                </Modal>
            </span>
        </Fragment>
    );
}
export default connectAppStore(BridgeMaterialDetail);