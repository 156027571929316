import { Fragment, useEffect, useState } from "react";
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";

const MultipleWindowsDetail = (props) =>{
    const { 
        stringCoding,
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        BaseForm,
        BindedInput,
        BindedTriStateCheckBox,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const projectId = useParams().projectId;
    const designVariationId = useParams().designVariationId;
    const developmentId = useParams().developmentId;
    const zoneId = useParams().zoneId;
    const redirectUrl = stringCoding.decode(useParams().redirectUrlHex);
    if(zoneId === undefined) zoneId = emptyGuid;
    const selectedElementIds = stringCoding.decodeObject(useParams().jsonParamHex);
    const defaultViewModel = {
        developmentId: developmentId,
        projectId: projectId, 
        designVariationId: designVariationId,
        designVariationVersionName:"V001",
        zoneId: zoneId,
        selectedItemIds: selectedElementIds
      };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const postUrl = "api/Element/Accessor/UpdateMultipleWindows";
    const getUrl = "api/Element/Accessor/GetMultipleWindows";

    useEffect(()=>{   
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading windows", appStoreDispatch);
    },[]);

    return(<Fragment>
        {debug && <p>{JSON.stringify(viewModel)}</p>}
        <BaseForm
           id="FloorCeilingFrom"
           icon="pe-7s-safe"
           loading={loading}
           setLoading={setLoading}
           submitButton= {{text:"Save and close", postUrl: postUrl, redirectUrl: redirectUrl }}
           cancelButton={{ redirectUrl: redirectUrl }}
           data={viewModel}
           title = "Windows detail"
           subTitle={pageProfile.getPageSubTitle(appStoreState)}
        >
            <div className="form-row row">
                <div className="col-12">
                    <div className="position-relative form-group">
                    <label htmlFor="WindowName">Name *</label>
                    <BindedInput
                        className="form-control"
                        id="WindowName"
                        name="WindowName"
                        type="text"
                        binding={[viewModel, "name", bindingViewModel]}
                    ></BindedInput>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-12">
                    <div className="position-relative form-group">
                        <label htmlFor="WindowConstructionId">
                            Construction *
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="WindowConstructionId"
                            name="WindowConstructionId"
                            options="constructions"
                            binding={[
                            viewModel,
                            "constructionId",
                            bindingViewModel,
                            ]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
            <div className="col-4">
                <div className="position-relative form-group">
                    <label htmlFor="WindowTypeId">
                            Type *
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="WindowTypeId"
                            name="WindowTypeId"
                            options="windowTypes"
                            binding={[
                            viewModel,
                            "windowTypeId",
                            bindingViewModel,
                            ]}
                        ></BindedSelect>
                </div>
            </div>
            <div className="col-4">
                <div className="position-relative form-group">
                    <label htmlFor="WindowIndoorCoveringTypeId">
                        Indoor covering type *
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="WindowIndoorCoveringTypeId"
                            name="WindowIndoorCoveringTypeId"
                            options="indoorCoveringTypes"
                            binding={[
                            viewModel,
                            "indoorCoveringTypeId",
                            bindingViewModel,
                            ]}
                        ></BindedSelect>
                </div>
            </div>
            <div className="col-4">
                <div className="position-relative form-group">
                    <label htmlFor="WindowOutdoorCoveringTypeId">
                        Outdoor covering Type *
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="WindowOutdoorCoveringTypeId"
                            name="WindowOutdoorCoveringTypeId"
                            options="outdoorCoveringTypes"
                            binding={[
                            viewModel,
                            "outdoorCoveringTypeId",
                            bindingViewModel,
                            ]}
                        ></BindedSelect>
                </div>
            </div>                
            </div>
            <div className="form-row row">
                <div className="col-12">
                    <div className="position-relative form-group">
                    <label htmlFor="ExternalWallFixedShadingId">
                        Fixed shading
                    </label>
                    <BindedSelect
                        className="form-control"
                        id="ExternalWallFixedShadingId"
                        name="ExternalWallFixedShadingId"
                        options="fixedShadings"
                        binding={[
                        viewModel,
                        "fixedShadingId",
                        bindingViewModel,
                        ]}
                    ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="ExternalWallLength">Length (mm) *</label>
                    <BindedInput
                        className="form-control"
                        id="ExternalWallLength"
                        name="ExternalWallLength"
                        type="number"
                        min="0"
                        binding={[viewModel, "length", bindingViewModel]}
                    ></BindedInput>
                    </div>
                </div>
                <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="ExternalWallHeight">Height (mm) *</label>
                    <BindedInput
                        className="form-control"
                        id="ExternalWallHeight"
                        name="ExternalWallHeight"
                        type="number"
                        min="0"
                        binding={[viewModel, "height", bindingViewModel]}
                    ></BindedInput>
                    </div>
                </div>
                {/* <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="ExternalWallGrossArea">
                        Area (m<sup>2</sup>) *
                    </label>
                    <label className="form-control" style={{ border: "none", backgroundColor: "white" }}>{calculateArea(viewModel.length, viewModel.height)}</label>
                    </div>
                </div> */}
            </div>
            <div className="form-row row">
                <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="WindowHeadHeight">Head height (mm) *</label>
                    <BindedInput
                        className="form-control"
                        id="WindowHeadHeight"
                        name="WindowHeadHeight"
                        type="number"
                        min="0"
                        binding={[viewModel, "headHeight", bindingViewModel]}
                    ></BindedInput>
                    </div>
                </div>
                {/* <div className="col-4">
                    <div className="position-relative form-group">
                    <label>
                        Height from floor (mm)
                    </label>
                    <label className="form-control" style={{ border: "none", backgroundColor: "white" }}>{calculateHeightFromFloor()}</label>
                    </div>
                </div> */}
            </div>
            <div className="form-row row">
                <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="WindowHorizontalOffset">
                        Horizontal offset (mm) *
                    </label>
                    <BindedInput
                        className="form-control"
                        min="0"
                        id="WindowHorizontalOffset"
                        name="WindowHorizontalOffset"
                        type="number"
                        binding={[viewModel, "horizontalOffset", bindingViewModel]}
                    ></BindedInput>
                    </div>
                </div>
                <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="WindowOpenablePercent">
                        Openable (%) *
                    </label>
                    <BindedInput                                 
                        className="form-control"
                        min="0"
                        max="100"
                        id="WindowOpenablePercent"
                        name="WindowOpenablePercent"
                        type="number"
                        binding={[viewModel, "openablePercent", bindingViewModel]}
                    ></BindedInput>
                    </div>
                </div>     
            </div>
            <div className="form-row row">
                <div className="custom-checkbox custom-control">
                    <BindedTriStateCheckBox
                        disabled={viewModel.dwellingType === "New"}
                        id="WindowHasInsectScreens"
                        name="WindowHasInsectScreens"
                        className="custom-control-input"
                        binding={[viewModel, "hasInsectScreens", bindingViewModel]}
                    ></BindedTriStateCheckBox>
                    <label
                    style={{ marginLeft: "5px" }}
                    htmlFor="WindowHasInsectScreens"
                    className="custom-control-label"
                    >
                    Insect screens
                    </label>
                </div>
            </div>
            <div className="form-row row">
                <div className="custom-checkbox custom-control">
                    <BindedTriStateCheckBox
                        id="WindowIsWeatherstripped"
                        name="WindowIsWeatherstripped"
                        className="custom-control-input"
                        binding={[viewModel, "isWeatherstripped", bindingViewModel]}
                    ></BindedTriStateCheckBox>
                    <label
                    style={{ marginLeft: "5px" }}
                    htmlFor="WindowIsWeatherstripped"
                    className="custom-control-label"
                    >
                    Weatherstripped
                    </label>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="WindowGapSizeTypeId">
                        Gap size
                    </label>
                    <BindedSelect
                        disabled={viewModel.isWeatherstripped===true||viewModel.isWeatherstripped===null}
                        className="form-control"
                        id="WindowGapSizeTypeId"
                        name="WindowGapSizeTypeId"
                        options="gapSizeTypes"
                        binding={[
                        viewModel,
                        "gapSizeTypeId",
                        bindingViewModel,
                        ]}
                    ></BindedSelect>
                    </div>
                </div>
            </div>
            {viewModel.simulationType === "Existing Home" &&
                                    <>
                                        <div className="form-row row">
                                            <div className="col-6">
                                                <div className="custom-checkbox custom-control">
                                                    <BindedTriStateCheckBox
                                                        id="IsExistingIndoorCoveringPresent"
                                                        name="IsExistingIndoorCoveringPresent"
                                                        className="custom-control-input"
                                                        binding={[viewModel, "isExistingIndoorCoveringPresent", bindingViewModel]}
                                                    ></BindedTriStateCheckBox>
                                                    <label                      
                                                    style={{ marginLeft: "5px" }}
                                                    htmlFor="IsExistingIndoorCoveringPresent"
                                                    className="custom-control-label"
                                                    >
                                                    Indoor Window Covering Characteristics
                                                    </label>
                                                </div>
                                            </div>
                                        </div>                                     
                                                                                             
                                    </>
                                }   

                                         {viewModel.isExistingIndoorCoveringPresent === true &&
                                            <>
                                                <div className="form-row row">
                                                    <div className="col-6">
                                                        <div className="position-relative form-group">
                                                            <label htmlFor="ExistingWindowStreetSideAppearanceTypeId">Outside appearance (colour)</label>
                                                            <BindedSelect
                                                                className="form-control"
                                                                id="ExistingWindowStreetSideAppearanceTypeId"
                                                                name="ExistingWindowStreetSideAppearanceTypeId"
                                                                options="existingWindowStreetSideAppearanceTypes"
                                                                binding={[viewModel, "existingWindowStreetSideAppearanceTypeId", bindingViewModel]}
                                                            ></BindedSelect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row row">
                                                    <div className="col-6">
                                                        <div className="position-relative form-group">
                                                            <label htmlFor="ExistingWindowLightPassingThroughTypeId">Light transmittance</label>
                                                            <BindedSelect
                                                                className="form-control"
                                                                id="ExistingWindowLightPassingThroughTypeId"
                                                                name="ExistingWindowLightPassingThroughTypeId"
                                                                options="existingWindowLightPassingThroughTypes"
                                                                binding={[viewModel, "existingWindowLightPassingThroughTypeId", bindingViewModel]}
                                                            ></BindedSelect>
                                                        </div>
                                                    </div>
                                                </div>
                                                 <div className="form-row row">                                                                                                               
                                                    <div className="col-6">
                                                        <div className="position-relative form-group">
                                                        <label htmlFor="ExistingWindowInsulationAirflowTypeId">Insulation value + Window covering fit</label>
                                                        <BindedSelect
                                                            className="form-control"
                                                            id="ExistingWindowInsulationAirflowTypeId"
                                                            name="ExistingWindowInsulationAirflowTypeId"
                                                            options="existingWindowInsulationAirflowTypes"
                                                            binding={[viewModel, "existingWindowInsulationAirflowTypeId", bindingViewModel]}
                                                        ></BindedSelect>
                                                        </div>
                                                    </div>
                                                </div>   
                                            </>                              
                                        }      
        </BaseForm>
    </Fragment>);
}
export default connectAppStore(MultipleWindowsDetail);