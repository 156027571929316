import { connectAppStore } from "../../../../../Store/AppStore";
import PageDependencies from "../../../../../Feature/PageDependencies";
import TooltipTemplate from "../../../../../Layout/AppNav/TooltipTemplate";

const SolarPvModule = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        tryCatchFinally,
        layoutManager,
        getData,
        cookiesManager,
        guid,
        useReducerSmart,
        toastPrint,
        BindedInput,
        BindedCheckBox,
        BindedSelect,
        getAppStore,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        FormValidate
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const defaultViewModel = {
        ...defaultParams,
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/ExistingHome/SolarPvModule/Accessor/Get";
    const [reloadForm, setReloadForm] = useState("");
    const formId = "SolarPVOrientationDetail";
    const [elementTableType, setElementTableType] = useState("Orientation1");
    const validateMethods = {
        numberOfOrientations:(value)=>{ return FormValidate.validateNumberBetween(value, "Number of Orientations", 0, 3);}, 
        totalCapacityInverters:(value)=>{ return FormValidate.validateNumberGreaterThanOrEqual0(value, "Capacity inverters");},
        networkLimit:(value)=>{ return FormValidate.validateNumberGreaterThanOrEqual0(value, "Network limit");},
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(),false);

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Solar PV", appStoreDispatch, undefined, setInit);
    },[reloadForm]);

    useEffect(()=>{
        if(init){
            if (viewModel.numberOfOrientations >= 1){
                handleTagClick("Orientation" + viewModel.numberOfOrientations.toString());
            }
        }
    }, [viewModel.numberOfOrientations]);

    useEffect(()=>{
        if(!viewModel.batteryTypeAvailability){
            if (["1", "2", "5", "6", "7"].includes(String(viewModel.batteryUnNumberId))) {
                dispatchViewModel({...viewModel, batteryTypeId: 1});
            }else{
                dispatchViewModel({...viewModel, batteryTypeId: 2});
            }
        }
    }, [viewModel.batteryUnNumberId, viewModel.batteryTypeAvailability]);

    const submit = ()=>{
        formValidate.setValidateVisibility(true);
        const validateResult = formValidate.checkValidateResult(viewModel);
        if(!validateResult) return;
        const postUrl = "api/ExistingHome/SolarPvModule/Accessor/UpdateSolarPv";
        setLoading({ isLoading: true, text: "Updating Solar PV" });
        tryCatchFinally.syncProcess(async()=>{
            const response = await getData.processPost(true, postUrl, viewModel);
            toastPrint.printResponse(response);
            setReloadForm(guid.newGuid());
        }, setLoading);
    }

    const handleTagClick = (elementTableType) => {
        setElementTableType(elementTableType);
        cookiesManager.setCookies(formId, { elementTableType: elementTableType });
    }

    const generateTagClass = (elementType) => {
        let className = elementType === elementTableType ? "active" : "";
        className = "nav-link show " + className;
        return className;
    }

    const calculateDegradationFactor = (ageIndex) => {
        ageIndex = Number(ageIndex);
        if (ageIndex == 1) {
            return 1;
        }else if (ageIndex == 2){
            return 0.95;
        }else if (ageIndex == 3){
            return 0.9;
        }else if (ageIndex == 4){
            return 0.85;
        }
        return 0;
    };

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-sun icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Solar PV
                                <div className="page-title-subheading">
                                    {pageProfile.getPageSubTitle(appStoreState)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {init === true &&
                    <>
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#AnnualEnergyConsumptionDiv" aria-expanded="true" aria-controls="AnnualEnergyConsumptionDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Annual PV Generation</h5>
                        </button>
                    </div>
                    <div data-parent="#AnnualEnergyConsumptionAccordion" id="AnnualEnergyConsumptionDiv" className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            <div className="form-row row">
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label>Electricity Generation (kWh/yr): {parseFloat(viewModel.annualElectricityGenerationEnergy).toFixed(2)}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#InstallationAgeDiv" aria-expanded="true" aria-controls="InstallationAgeDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">PV System Age</h5>
                        </button>
                    </div>
                    <div data-parent="#InstallationAge" id="InstallationAgeDiv" className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            <div className="form-row row">
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="InstallationYear">Installation Year</label>
                                        <BindedInput
                                            className="form-control"
                                            id="InstallationYear"
                                            name="InstallationYear"
                                            type="number"
                                            validate={formValidate}
                                            binding={[viewModel, "installationYear", bindingViewModel]}
                                        ></BindedInput>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="InstallationAge">Age *</label>
                                        <BindedSelect
                                            className="form-control"
                                            id="InstallationAge"
                                            name="InstallationAge"
                                            options="installationAgeTypes"
                                            validate={formValidate}
                                            binding={[viewModel, "installationAgeId", bindingViewModel]}
                                        ></BindedSelect>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="DegradationFactor">PV Degradation Factor </label>
                                        <label className="form-control" style={{ border: "none"}}>{calculateDegradationFactor(viewModel.installationAgeId)}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#HeatingConditionersListDiv" aria-expanded="true" aria-controls="HeatingConditionersListDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Solar PV Configuration</h5>
                        </button>
                    </div>
                    <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            <div className="form-row row">
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="form-row row">
                                    <div className="col-4">
                                        <div className="custom-checkbox custom-control">
                                            <BindedCheckBox
                                                id="IsPvSystemOperating"
                                                name="IsPvSystemOperating"
                                                className="custom-control-input"
                                                binding={[
                                                    viewModel,
                                                    "isPvSystemOperating",
                                                    bindingViewModel,
                                                ]}
                                            ></BindedCheckBox>
                                            <label
                                                style={{ marginLeft: "5px" }}
                                                htmlFor="IsPvSystemOperating"
                                                className="custom-control-label"
                                            >
                                                Is Pv System Operating
                                            </label>
                                        </div>
                                    </div>
                                </div>                
                                    <div className="form-row row">
                                        <div className="col-lg-7 col-7">
                                            <label htmlFor="NumberOfOrientations">Number Of Orientations *</label>
                                        </div>
                                        <div className="col-lg-3 col-3">
                                            <BindedInput
                                                className="form-control"
                                                id="NumberOfOrientations"
                                                name="NumberOfOrientations"
                                                min="0"
                                                max="3"
                                                type="number"
                                                validate={formValidate}
                                                binding={[viewModel, "numberOfOrientations", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-lg-7 col-7">
                                            <label htmlFor="SoilingLosses">Soiling Losses </label>
                                        </div>
                                        <div className="col-lg-3 col-3">
                                            <BindedInput
                                                disabled
                                                className="form-control"
                                                id="SoilingLosses"
                                                name="SoilingLosses"
                                                type="number"
                                                binding={[viewModel, "soilingLosses", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-lg-7 col-7">
                                            <label htmlFor="DcWiringLosses">Dc Wiring Losses </label>
                                        </div>
                                        <div className="col-lg-3 col-3">
                                            <BindedInput
                                                disabled
                                                className="form-control"
                                                id="DcWiringLosses"
                                                name="DcWiringLosses"
                                                type="number"
                                                binding={[viewModel, "dcWiringLosses", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-lg-7 col-7">
                                            <label htmlFor="ConversionLosses">Conversion Losses </label>
                                        </div>
                                        <div className="col-lg-3 col-3">
                                            <BindedInput
                                                disabled
                                                className="form-control"
                                                id="ConversionLosses"
                                                name="ConversionLosses"
                                                type="number"
                                                binding={[viewModel, "conversionLosses", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-lg-7 col-7">
                                            <label htmlFor="StandardAmountOfRadiationUsed">Standard amount of radiation used </label>
                                        </div>
                                        <div className="col-lg-3 col-3">
                                            <BindedInput
                                                disabled
                                                className="form-control"
                                                id="StandardAmountOfRadiationUsed"
                                                name="StandardAmountOfRadiationUsed"
                                                type="number"
                                                binding={[viewModel, "standardAmountOfRadiationUsed", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-lg-7 col-7">
                                            <label htmlFor="TimeZone">Time Zone </label>
                                        </div>
                                        <div className="col-lg-3 col-3">
                                            <BindedInput
                                                disabled
                                                className="form-control"
                                                id="TimeZone"
                                                name="TimeZone"
                                                type="number"
                                                binding={[viewModel, "timeZone", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <label htmlFor="TotalCapacityInverters">Total Capacity of Inverters (kW)</label>
                                        <div className="col-lg-7 col-7">
                                            <div className="position-relative form-group">
                                                <BindedSelect
                                                    className="form-control"
                                                    id="InverterCapacityMethod"
                                                    name="InverterCapacityMethod"
                                                    options="inverterCapacityMethodTypes"
                                                    validate={formValidate}
                                                    binding={[viewModel, "inverterCapacityMethodId", bindingViewModel]}
                                                ></BindedSelect>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-3">
                                            <BindedInput
                                                disabled={ viewModel.inverterCapacityMethodId==2}
                                                className="form-control"
                                                id="TotalCapacityInverters"
                                                name="TotalCapacityInverters"
                                                type="number"
                                                max="999"
                                                min="0"
                                                validate={formValidate}
                                                binding={[viewModel, "totalCapacityInverters", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12" id="Oriententions">
                                {viewModel.numberOfOrientations > 0 &&
                                    <div id="AdditionalCards" className="main-card mb-3 card">
                                        <div className="card-header">
                                            <ul className="nav nav-justified">
                                                <li className="nav-item" onClick={() => handleTagClick("Orientation1")}><a className={generateTagClass("Orientation1")}>Orientation 1</a></li>
                                                {viewModel.numberOfOrientations > 1 &&
                                                <li className="nav-item" onClick={() => handleTagClick("Orientation2")}><a className={generateTagClass("Orientation2")}>Orientation 2</a></li>}
                                                {viewModel.numberOfOrientations > 2 &&
                                                <li className="nav-item" onClick={() => handleTagClick("Orientation3")}><a className={generateTagClass("Orientation3")}>Orientation 3</a></li>}
                                            </ul>
                                        </div>
                                        <div data-parent="#ConstructionsAccordion" id="ConstructionsCollapsibleDiv" className="collapse show">
                                            <div className="card-body">
                                                <div className="separator"></div>
                                                {elementTableType === 'Orientation1' && <Fragment>
                                                    <div className="col-lg-12 col-12">
                                                        <div className="position-relative form-group">
                                                            <div className="form-row row">
                                                                <label id="CollectorSlope1Label" htmlFor="CollectorSlope1">PV Collector Slope</label>
                                                                <div className="col-lg-9 col-9">
                                                                    {/* <div className="col-6">
                                                                        <div className="position-relative form-group"> */}
                                                                            <BindedSelect
                                                                                className="form-control"
                                                                                id="SlopeMethodId1"
                                                                                name="SlopeMethodId1"
                                                                                options="slopeMethodTypes"
                                                                                validate={formValidate}
                                                                                binding={[viewModel, "slopeMethodId1", bindingViewModel]}
                                                                            ></BindedSelect>
                                                                        {/* </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="position-relative form-group"> */}
                                                                            <BindedSelect
                                                                                disabled={ viewModel.slopeMethodId1==1}
                                                                                className="form-control"
                                                                                id="SlopeSimpleAssessmentId1"
                                                                                name="SlopeSimpleAssessmentId1"
                                                                                options="slopeSimpleAssessmentTypes"
                                                                                validate={formValidate}
                                                                                binding={[viewModel, "slopeSimpleAssessmentId1", bindingViewModel]}
                                                                            ></BindedSelect>
                                                                        {/* </div>
                                                                    </div> */}
                                                                </div>
                                                                <div className="col-lg-3 col-3">
                                                                    <BindedInput
                                                                        disabled={ viewModel.slopeMethodId1==2}
                                                                        className="form-control"
                                                                        id="CollectorSlope1"
                                                                        name="CollectorSlope1"
                                                                        type="number"
                                                                        binding={[viewModel, "collectorSlope1", bindingViewModel]}
                                                                    ></BindedInput>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-12">
                                                        <div className="position-relative form-group">
                                                            <div className="form-row row">
                                                                <label id="Size1Label" htmlFor="Size1">Methods to Identify PV Size</label>
                                                                <div className="col-lg-9 col-9">
                                                                    <BindedSelect
                                                                        className="form-control"
                                                                        id="CapacityModeId1"
                                                                        name="CapacityModeId1"
                                                                        options="capacityModeTypes"
                                                                        validate={formValidate}
                                                                        binding={[viewModel, "capacityModeId1", bindingViewModel]}
                                                                    ></BindedSelect>
                                                                    <label id="CapacityModuleNumber1" htmlFor="CapacityModuleNumber1">Number of Module</label>
                                                                    <BindedInput
                                                                        disabled={ viewModel.capacityModeId1==1  || viewModel.capacityModeId1==4}
                                                                        className="form-control"
                                                                        id="CapacityModuleNumber1"
                                                                        name="CapacityModuleNumber1"
                                                                        type="number"
                                                                        binding={[viewModel, "capacityModuleNumber1", bindingViewModel]}
                                                                    ></BindedInput>
                                                                    <label id="CapacityModuleRating1" htmlFor="CapacityModuleRating1">Module Rating (W)</label>
                                                                    <BindedInput
                                                                        disabled={viewModel.capacityModeId1!=2}
                                                                        className="form-control"
                                                                        id="CapacityModuleRating1"
                                                                        name="CapacityModuleRating1"
                                                                        type="number"
                                                                        binding={[viewModel, "capacityModuleRating1", bindingViewModel]}
                                                                    ></BindedInput>
                                                                    <label id="CapacityStringInverterCapacity1" htmlFor="CapacityStringInverterCapacity1">String Inverter Capacity (kW)</label>
                                                                    <BindedInput
                                                                        disabled={viewModel.capacityModeId1!=3}
                                                                        className="form-control"
                                                                        id="CapacityStringInverterCapacity1"
                                                                        name="CapacityStringInverterCapacity1"
                                                                        type="number"
                                                                        binding={[viewModel, "capacityStringInverterCapacity1", bindingViewModel]}
                                                                    ></BindedInput>
                                                                    <label id="CapacityArrayArea1" htmlFor="CapacityArrayArea1">Array Area</label>
                                                                    <BindedInput
                                                                        disabled={ viewModel.capacityModeId1!=4}
                                                                        className="form-control"
                                                                        id="CapacityArrayArea1"
                                                                        name="CapacityArrayArea1"
                                                                        type="number"
                                                                        binding={[viewModel, "capacityArrayArea1", bindingViewModel]}
                                                                    ></BindedInput>
                                                                </div>
                                                                <div className="col-lg-3 col-3">
                                                                    <BindedInput
                                                                        disabled={viewModel.capacityModeId1!=1}
                                                                        className="form-control"
                                                                        id="Size1"
                                                                        name="Size1"
                                                                        type="number"
                                                                        binding={[viewModel, "size1", bindingViewModel]}
                                                                    ></BindedInput>
                                                                    <label id="CapacityActualValue1" htmlFor="CapacityActualValue1">Actual Capacity</label>
                                                                    <BindedInput
                                                                        disabled
                                                                        className="form-control"
                                                                        id="CapacityActualValue1"
                                                                        name="CapacityActualValue1"
                                                                        type="number"
                                                                        binding={[viewModel, "capacityActualValue1", bindingViewModel]}
                                                                    ></BindedInput>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-12">
                                                        <div className="position-relative form-group">
                                                            <div className="form-row row">
                                                                <label id="AzimuthAngle1Label" htmlFor="AzimuthAngle1">PV Collector Surface Azimuth Angle</label>
                                                                <div className="col-lg-9 col-9">
                                                                    <BindedSelect
                                                                        className="form-control"
                                                                        id="OrientationMethodId1"
                                                                        name="OrientationMethodId1"
                                                                        options="orientationMethodTypes"
                                                                        validate={formValidate}
                                                                        binding={[viewModel, "orientationMethodId1", bindingViewModel]}
                                                                    ></BindedSelect>
                                                                    <BindedSelect
                                                                        disabled={ viewModel.orientationMethodId1!=2}
                                                                        className="form-control"
                                                                        id="OrientationSimpleAssessmentId1"
                                                                        name="OrientationSimpleAssessmentId1"
                                                                        options="orientationSimpleAssessmentTypes"
                                                                        validate={formValidate}
                                                                        binding={[viewModel, "orientationSimpleAssessmentId1", bindingViewModel]}
                                                                    ></BindedSelect>
                                                                </div>
                                                                <div className="col-lg-3 col-3">
                                                                    <BindedInput
                                                                        disabled={viewModel.orientationMethodId1==2}
                                                                        className="form-control"
                                                                        id="AzimuthAngle1"
                                                                        name="AzimuthAngle1"
                                                                        type="number"
                                                                        binding={[viewModel, "azimuthAngle1", bindingViewModel]}
                                                                    ></BindedInput>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-12">
                                                        <div className="position-relative form-group">
                                                            <div className="form-row row">
                                                                <div className="col-lg-9 col-9">
                                                                    <label id="DiffuseReflectance1Label" htmlFor="DiffuseReflectance1">Diffuse Reflectance</label>
                                                                </div>
                                                                <div className="col-lg-3 col-3">
                                                                    <BindedInput
                                                                        disabled
                                                                        className="form-control"
                                                                        id="DiffuseReflectance1"
                                                                        name="DiffuseReflectance1"
                                                                        type="number"
                                                                        binding={[viewModel, "diffuseReflectance1", bindingViewModel]}
                                                                    ></BindedInput>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </Fragment>
                                                }
                                                {elementTableType === 'Orientation2' && <Fragment>
                                                    <div className="col-lg-12 col-12">
                                                        <div className="position-relative form-group">
                                                            <div className="form-row row">
                                                                <label id="CollectorSlope2Label" htmlFor="CollectorSlope2">PV Collector Slope</label>
                                                                <div className="col-lg-9 col-9">
                                                                    {/* <div className="col-6"> */}
                                                                        {/* <div className="position-relative form-group"> */}
                                                                            <BindedSelect
                                                                                className="form-control"
                                                                                id="SlopeMethodId2"
                                                                                name="SlopeMethodId2"
                                                                                options="slopeMethodTypes"
                                                                                validate={formValidate}
                                                                                binding={[viewModel, "slopeMethodId2", bindingViewModel]}
                                                                            ></BindedSelect>
                                                                        {/* </div> */}
                                                                    {/* </div>
                                                                    <div className="col-6"> */}
                                                                        {/* <div className="position-relative form-group"> */}
                                                                            <BindedSelect
                                                                                className="form-control"
                                                                                id="SlopeSimpleAssessmentId2"
                                                                                name="SlopeSimpleAssessmentId2"
                                                                                options="slopeSimpleAssessmentTypes"
                                                                                validate={formValidate}
                                                                                binding={[viewModel, "slopeSimpleAssessmentId2", bindingViewModel]}
                                                                            ></BindedSelect>
                                                                        {/* </div> */}
                                                                    {/* </div> */}
                                                                </div>
                                                                <div className="col-lg-3 col-3">
                                                                    <BindedInput
                                                                        disabled={viewModel.slopeMethodId2==2}
                                                                        className="form-control"
                                                                        id="CollectorSlope2"
                                                                        name="CollectorSlope2"
                                                                        type="number"
                                                                        binding={[viewModel, "collectorSlope2", bindingViewModel]}
                                                                    ></BindedInput>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-12">
                                                        <div className="position-relative form-group">
                                                            <div className="form-row row">
                                                                <label id="Size2Label" htmlFor="Size2">Methods to Identify PV Size</label>
                                                                <div className="col-lg-9 col-9">
                                                                    <BindedSelect
                                                                        className="form-control"
                                                                        id="CapacityModeId2"
                                                                        name="CapacityModeId2"
                                                                        options="capacityModeTypes"
                                                                        validate={formValidate}
                                                                        binding={[viewModel, "capacityModeId2", bindingViewModel]}
                                                                    ></BindedSelect>
                                                                    <label id="CapacityModuleNumber2" htmlFor="CapacityModuleNumber2">Number of Module</label>
                                                                    <BindedInput
                                                                        disabled={viewModel.capacityModeId2==1 ||  viewModel.capacityModeId2==4}
                                                                        className="form-control"
                                                                        id="CapacityModuleNumber2"
                                                                        name="CapacityModuleNumber2"
                                                                        type="number"
                                                                        binding={[viewModel, "capacityModuleNumber2", bindingViewModel]}
                                                                    ></BindedInput>
                                                                    <label id="CapacityModuleRating2" htmlFor="CapacityModuleRating2">Module Rating (W)</label>
                                                                    <BindedInput
                                                                        disabled={viewModel.capacityModeId2!=2}
                                                                        className="form-control"
                                                                        id="CapacityModuleRating2"
                                                                        name="CapacityModuleRating2"
                                                                        type="number"
                                                                        binding={[viewModel, "capacityModuleRating2", bindingViewModel]}
                                                                    ></BindedInput>
                                                                    <label id="CapacityStringInverterCapacity2" htmlFor="CapacityStringInverterCapacity2">String Inverter Capacity (kW)</label>
                                                                    <BindedInput
                                                                        disabled={viewModel.capacityModeId2!=3}
                                                                        className="form-control"
                                                                        id="CapacityStringInverterCapacity2"
                                                                        name="CapacityStringInverterCapacity2"
                                                                        type="number"
                                                                        binding={[viewModel, "capacityStringInverterCapacity2", bindingViewModel]}
                                                                    ></BindedInput>
                                                                    <label id="CapacityArrayArea2" htmlFor="CapacityArrayArea2">Array Area</label>
                                                                    <BindedInput
                                                                        disabled={viewModel.capacityModeId2!=4}
                                                                        className="form-control"
                                                                        id="CapacityArrayArea2"
                                                                        name="CapacityArrayArea2"
                                                                        type="number"
                                                                        binding={[viewModel, "capacityArrayArea2", bindingViewModel]}
                                                                    ></BindedInput>
                                                                </div>
                                                                <div className="col-lg-3 col-3">
                                                                    <BindedInput
                                                                        disabled={ viewModel.capacityModeId2!=1}
                                                                        className="form-control"
                                                                        id="Size1"
                                                                        name="Size1"
                                                                        type="number"
                                                                        binding={[viewModel, "size2", bindingViewModel]}
                                                                    ></BindedInput>
                                                                    <label id="CapacityActualValue2" htmlFor="CapacityActualValue2">Actual Capacity</label>
                                                                    <BindedInput
                                                                        disabled
                                                                        className="form-control"
                                                                        id="CapacityActualValue2"
                                                                        name="CapacityActualValue2"
                                                                        type="number"
                                                                        binding={[viewModel, "capacityActualValue2", bindingViewModel]}
                                                                    ></BindedInput>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-12">
                                                        <div className="position-relative form-group">
                                                            <div className="form-row row">
                                                                <div className="col-lg-9 col-9">
                                                                    <label id="AzimuthAngle2Label" htmlFor="AzimuthAngle2">PV Collector Surface Azimuth Angle</label>
                                                                </div>
                                                                <div className="col-lg-3 col-3">
                                                                    <BindedInput
                                                                        className="form-control"
                                                                        id="AzimuthAngle2"
                                                                        name="AzimuthAngle2"
                                                                        type="number"
                                                                        binding={[viewModel, "azimuthAngle2", bindingViewModel]}
                                                                    ></BindedInput>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-12">
                                                        <div className="position-relative form-group">
                                                            <div className="form-row row">
                                                                <div className="col-lg-9 col-9">
                                                                    <label id="DiffuseReflectance2Label" htmlFor="DiffuseReflectance2">Diffuse Reflectance</label>
                                                                </div>
                                                                <div className="col-lg-3 col-3">
                                                                    <BindedInput
                                                                        disabled
                                                                        className="form-control"
                                                                        id="DiffuseReflectance2"
                                                                        name="DiffuseReflectance2"
                                                                        type="number"
                                                                        binding={[viewModel, "diffuseReflectance2", bindingViewModel]}
                                                                    ></BindedInput>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </Fragment>
                                                }
                                                {elementTableType === 'Orientation3' && <Fragment>
                                                    <div className="col-lg-12 col-12">
                                                        <div className="position-relative form-group">
                                                            <div className="form-row row">
                                                                <label id="CollectorSlope3Label" htmlFor="CollectorSlope3">PV Collector Slope</label>
                                                                <div className="col-lg-9 col-9">
                                                                    {/* <div className="col-6"> */}
                                                                        {/* <div className="position-relative form-group"> */}
                                                                            <BindedSelect
                                                                                className="form-control"
                                                                                id="SlopeMethodId3"
                                                                                name="SlopeMethodId3"
                                                                                options="slopeMethodTypes"
                                                                                validate={formValidate}
                                                                                binding={[viewModel, "slopeMethodId3", bindingViewModel]}
                                                                            ></BindedSelect>
                                                                        {/* </div> */}
                                                                    {/* </div>
                                                                    <div className="col-6"> */}
                                                                        {/* <div className="position-relative form-group"> */}
                                                                            <BindedSelect
                                                                                className="form-control"
                                                                                id="SlopeSimpleAssessmentId3"
                                                                                name="SlopeSimpleAssessmentId3"
                                                                                options="slopeSimpleAssessmentTypes"
                                                                                validate={formValidate}
                                                                                binding={[viewModel, "slopeSimpleAssessmentId3", bindingViewModel]}
                                                                            ></BindedSelect>
                                                                        {/* </div> */}
                                                                    {/* </div> */}
                                                                </div>
                                                                <div className="col-lg-3 col-3">
                                                                    <BindedInput
                                                                        disabled={ viewModel.slopeMethodId3==2}
                                                                        className="form-control"
                                                                        id="CollectorSlope3"
                                                                        name="CollectorSlope3"
                                                                        type="number"
                                                                        binding={[viewModel, "collectorSlope3", bindingViewModel]}
                                                                    ></BindedInput>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-12">
                                                        <div className="position-relative form-group">
                                                            <div className="form-row row">
                                                                <label id="Size3Label" htmlFor="Size3">Methods to Identify PV Size</label>
                                                                <div className="col-lg-9 col-9">
                                                                    <BindedSelect
                                                                        className="form-control"
                                                                        id="CapacityModeId3"
                                                                        name="CapacityModeId3"
                                                                        options="capacityModeTypes"
                                                                        validate={formValidate}
                                                                        binding={[viewModel, "capacityModeId3", bindingViewModel]}
                                                                    ></BindedSelect>
                                                                    <label id="CapacityModuleNumber3" htmlFor="CapacityModuleNumber3">Number of Module</label>
                                                                    <BindedInput
                                                                        disabled={viewModel.capacityModeId3==1 || viewModel.capacityModeId3==4}
                                                                        className="form-control"
                                                                        id="CapacityModuleNumber3"
                                                                        name="CapacityModuleNumber3"
                                                                        type="number"
                                                                        binding={[viewModel, "capacityModuleNumber3", bindingViewModel]}
                                                                    ></BindedInput>
                                                                    <label id="CapacityModuleRating3" htmlFor="CapacityModuleRating3">Module Rating (W)</label>
                                                                    <BindedInput
                                                                        disabled={ viewModel.capacityModeId3!=2}
                                                                        className="form-control"
                                                                        id="CapacityModuleRating3"
                                                                        name="CapacityModuleRating3"
                                                                        type="number"
                                                                        binding={[viewModel, "capacityModuleRating3", bindingViewModel]}
                                                                    ></BindedInput>
                                                                    <label id="CapacityStringInverterCapacity3" htmlFor="CapacityStringInverterCapacity3">String Inverter Capacity (kW)</label>
                                                                    <BindedInput
                                                                        disabled={ viewModel.capacityModeId3!=3}
                                                                        className="form-control"
                                                                        id="CapacityStringInverterCapacity3"
                                                                        name="CapacityStringInverterCapacity3"
                                                                        type="number"
                                                                        binding={[viewModel, "capacityStringInverterCapacity3", bindingViewModel]}
                                                                    ></BindedInput>
                                                                    <label id="CapacityArrayArea3" htmlFor="CapacityArrayArea3">Array Area</label>
                                                                    <BindedInput
                                                                        disabled={viewModel.capacityModeId3!=4}
                                                                        className="form-control"
                                                                        id="CapacityArrayArea3"
                                                                        name="CapacityArrayArea3"
                                                                        type="number"
                                                                        binding={[viewModel, "capacityArrayArea3", bindingViewModel]}
                                                                    ></BindedInput>
                                                                </div>
                                                                <div className="col-lg-3 col-3">
                                                                    <BindedInput
                                                                        disabled={ viewModel.capacityModeId3!=1}
                                                                        className="form-control"
                                                                        id="Size3"
                                                                        name="Size3"
                                                                        type="number"
                                                                        binding={[viewModel, "size3", bindingViewModel]}
                                                                    ></BindedInput>
                                                                    <label id="CapacityActualValue3" htmlFor="CapacityActualValue3">Actual Capacity</label>
                                                                    <BindedInput
                                                                        disabled
                                                                        className="form-control"
                                                                        id="CapacityActualValue3"
                                                                        name="CapacityActualValue3"
                                                                        type="number"
                                                                        binding={[viewModel, "capacityActualValue3", bindingViewModel]}
                                                                    ></BindedInput>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-12">
                                                        <div className="position-relative form-group">
                                                            <div className="form-row row">
                                                                <div className="col-lg-9 col-9">
                                                                    <label id="AzimuthAngle3Label" htmlFor="AzimuthAngle3">PV Collector Surface Azimuth Angle</label>
                                                                </div>
                                                                <div className="col-lg-3 col-3">
                                                                    <BindedInput
                                                                        className="form-control"
                                                                        id="AzimuthAngle3"
                                                                        name="AzimuthAngle3"
                                                                        type="number"
                                                                        binding={[viewModel, "azimuthAngle3", bindingViewModel]}
                                                                    ></BindedInput>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-12">
                                                        <div className="position-relative form-group">
                                                            <div className="form-row row">
                                                                <div className="col-lg-9 col-9">
                                                                    <label id="DiffuseReflectance3Label" htmlFor="DiffuseReflectance3">Diffuse Reflectance</label>
                                                                </div>
                                                                <div className="col-lg-3 col-3">
                                                                    <BindedInput
                                                                        disabled
                                                                        className="form-control"
                                                                        id="DiffuseReflectance3"
                                                                        name="DiffuseReflectance3"
                                                                        type="number"
                                                                        binding={[viewModel, "diffuseReflectance3", bindingViewModel]}
                                                                    ></BindedInput>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </Fragment>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#HeatingConditionersListDiv" aria-expanded="true" aria-controls="HeatingConditionersListDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Battery Configuration</h5>
                        </button>
                    </div>
                    <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            <div className="form-row row">
                                <div className="col-4">
                                    <div className="custom-checkbox custom-control">
                                        <BindedCheckBox
                                            id="BatteryPresent"
                                            name="BatteryPresent"
                                            className="custom-control-input"
                                            binding={[
                                                viewModel,
                                                "batteryPresent",
                                                bindingViewModel,
                                            ]}
                                        ></BindedCheckBox>
                                        <label
                                            style={{ marginLeft: "5px" }}
                                            htmlFor="BatteryPresent"
                                            className="custom-control-label"
                                        >
                                            Battery Present
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row row">
                                <div className="col-4">
                                    <div className="custom-checkbox custom-control">
                                        <BindedCheckBox
                                            disabled={!viewModel.batteryPresent}
                                            id="BatteryTypeAvailability"
                                            name="BatteryTypeAvailability"
                                            className="custom-control-input"
                                            binding={[
                                                viewModel,
                                                "batteryTypeAvailability",
                                                bindingViewModel,
                                            ]}
                                        ></BindedCheckBox>
                                        <label
                                            style={{ marginLeft: "5px" }}
                                            htmlFor="BatteryTypeAvailability"
                                            className="custom-control-label"
                                        >
                                            Battery Type Available<TooltipTemplate text="If battery type is accessiable in documentation, pleaes check the box and select battery type, otherwise uncheck it to select UN number." result={0} color="grey" />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-4"></div>
                                <div className="col-4">
                                    <div className="custom-checkbox custom-control">
                                        <BindedCheckBox
                                            disabled={!viewModel.batteryPresent}
                                            id="BatteryCapacityAvailability"
                                            name="BatteryCapacityAvailability"
                                            className="custom-control-input"
                                            binding={[
                                                viewModel,
                                                "batteryCapacityAvailability",
                                                bindingViewModel,
                                            ]}
                                        ></BindedCheckBox>
                                        <label
                                            style={{ marginLeft: "5px" }}
                                            htmlFor="BatteryCapacityAvailability"
                                            className="custom-control-label"
                                        >
                                            Battery Capacity Assessing Documentation Available
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row row">
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="BatteryType">Battery Type *</label>
                                        <BindedSelect
                                            disabled={!(viewModel.batteryTypeAvailability&&viewModel.batteryPresent)}
                                            className="form-control"
                                            id="BatteryType"
                                            name="BatteryType"
                                            options="batteryTypes"
                                            validate={formValidate}
                                            binding={[viewModel, "batteryTypeId", bindingViewModel]}
                                        ></BindedSelect>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="BatteryUnNumberId">UN Number<TooltipTemplate text="By selecting UN number, corresponding battery type will be found." result={0} color="grey" /> </label>
                                        <BindedSelect
                                            disabled={viewModel.batteryTypeAvailability||!viewModel.batteryPresent}
                                            className="form-control"
                                            id="BatteryUnNumberId"
                                            name="BatteryUnNumberId"
                                            options="batteryUnNumberTypes"
                                            validate={formValidate}
                                            binding={[viewModel, "batteryUnNumberId", bindingViewModel]}
                                        ></BindedSelect>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="BatteryCapacity">Battery Capacity (kWh) </label>
                                        <BindedInput
                                            disabled={!viewModel.batteryCapacityAvailability||!viewModel.batteryPresent}
                                            className="form-control"
                                            id="BatteryCapacity"
                                            name="BatteryCapacity"
                                            type="number"
                                            binding={[viewModel, "batteryCapacity", bindingViewModel]}
                                        ></BindedInput>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#HeatingConditionersListDiv" aria-expanded="true" aria-controls="HeatingConditionersListDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Export NetWork Limit</h5>
                        </button>
                    </div>
                    <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            <div className="form-row row">
                                <div className="col-3">
                                    <div className="custom-checkbox custom-control">
                                        <BindedCheckBox
                                            id="ExportLimitAvailability"
                                            name="ExportLimitAvailability"
                                            className="custom-control-input"
                                            binding={[
                                                viewModel,
                                                "exportLimitAvailability",
                                                bindingViewModel,
                                            ]}
                                        ></BindedCheckBox>
                                        <label
                                            style={{ marginLeft: "5px" }}
                                            htmlFor="ExportLimitAvailability"
                                            className="custom-control-label"
                                        >
                                            Network Limit Available from the Document
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row row">
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="NetworkLimit">Networking Limit (kW)</label>
                                        <BindedInput
                                            disabled={!viewModel.exportLimitAvailability}
                                            className="form-control"
                                            id="NetworkLimit"
                                            name="NetworkLimit"
                                            type="number"
                                            validate={formValidate}
                                            binding={[viewModel, "networkLimit", bindingViewModel]}
                                        ></BindedInput>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="ExportLimitScenarioId">Export Limit Scenario</label>
                                        <BindedSelect
                                            disabled={viewModel.exportLimitAvailability}
                                            className="form-control"
                                            id="ExportLimitScenarioId"
                                            name="ExportLimitScenarioId"
                                            options="exportLimitScenarioTypes"
                                            validate={formValidate}
                                            binding={[viewModel, "exportLimitScenarioId", bindingViewModel]}
                                        ></BindedSelect>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="ExportLimitMultiNumber">Multi Phase Number</label>
                                        <BindedInput
                                            disabled={viewModel.exportLimitAvailability===true || (viewModel.exportLimitScenarioId != 3)}
                                            className="form-control"
                                            id="ExportLimitMultiNumber"
                                            name="ExportLimitMultiNumber"
                                            type="number"
                                            validate={formValidate}
                                            binding={[viewModel, "exportLimitMultiNumber", bindingViewModel]}
                                        ></BindedInput>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row-separator"></div>
                            <div className="form-row row"></div>
                            <div className="form-row row">
                                <div className="col-12">
                                    <button type="button" style={{marginTop:"10px"}} className="btn btn-primary" onClick={submit}>Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    </>
                }
           
            </div>
        </Fragment>
    );
}
export default connectAppStore(SolarPvModule);