
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";
import ConstructionElementDataTable from "../../../Accessor/Construction/ConstructionElementDataTable";

const RoofWindowDetail = (props) => {
    const {
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        useLocation,
        BaseFormNoHeader,
        BindedInput,
        BindedCheckBox,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
        FormValidate,
        Fragment, useEffect, useState 
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const currentUrl = useLocation().pathname;
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const roofId = defaultParams.parentId;
    const [init, setInit] = useState(false);
    const [reloadForm, setReloadForm] = useState("");
    const { elementId } = defaultParams;
    let { designVariationVersionName, developmentId, projectId, designVariationId, zoneId, parentId, redirectUrlHex } = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
        selectedItemIds: [elementId]
    };
    const [extraPostModelForConstruction, setExtraPostModelForConstruction] = useState({
        developmentId: developmentId,
        projectId: projectId,
        designVariationId: designVariationId,
        designVariationVersionName: designVariationVersionName,
        constructionType: "RoofWindow"
    });
    const [tableReloadForConstruction, setTableReloadForConstruction] = useState("");
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);

    //change model get url here if not using default model
    const getUrl = "api/Element/Accessor/InitializeRoofWindow";
    //add model post url here
    const postUrl = "api/Element/Accessor/UpdateRoofWindow";
    const validateMethods = {
        //add property validate here
        name: (value) => { return FormValidate.validateTextRequired(value, "Name"); },
        constructionId: (value) => { return value === emptyGuid ? "Must select a construction." : ""; },
        indoorShadingTypeId: (value) => { return value === emptyGuid ? "Must select an Indoor Shading Type." : ""; },
        outdoorShadingPercentTypeId: (value) => { return value === emptyGuid ? "Must select an Outdoor Shading Percent Type." : ""; },
        openingArea: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Area"); },
        grossArea: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Gross Area"); },
        pitch: (value) => { return FormValidate.validateNumberBetween(value, "Pitch", 0, 359); }
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(() => {
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading data", appStoreDispatch, undefined, setInit);
    }, []);

    useEffect(() => {
        if (init) {
            dispatchViewModel({ ...viewModel, elementId: elementId, id: elementId, selectedItemIds: [elementId] }, ()=>{
                setReloadForm(guid.newGuid());
            });
        }
    }, [elementId]);

    const generateStayUrl = (submitResponse) => {
        let redirectUrl = currentUrl;
        if (elementId === emptyGuid) {
            //ElementAccessor/Detail/RoofWindow/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:parentId/:redirectUrlHex
            redirectUrl =  "/ElementAccessor/Detail/RoofWindow/" + designVariationVersionName + "/" + developmentId +"/"+projectId+"/"+designVariationId+"/"+zoneId+"/"+submitResponse.elementId +"/"+parentId+"/"+redirectUrlHex;
        }
        return redirectUrl;
    }

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {debug && <p>{JSON.stringify(roofId)}</p>}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-safe icon-gradient bg-tempting-azure"></i>
                            </div>
                            <div>
                                Roof Window
                                <div className="page-title-subheading">
                                    {pageProfile.getPageSubTitle(appStoreState)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-lg-12">
                        <div className="main-card mb-3 card">
                            <BaseFormNoHeader
                                id="RoofDetail"
                                submitButton={{ postUrl: postUrl, redirectUrl: viewModel.redirectUrl, text: "Save and close" }}
                                submitButtonAndStay={{ text: "Save", postUrl: postUrl, redirectMethod: (submitResponse) => { return generateStayUrl(submitResponse) } }}
                                cancelButton={{ postUrl: postUrl, redirectUrl: viewModel.redirectUrl }}
                                validate={formValidate}
                                setReload={setReloadForm}
                                loading={loading}
                                setInit={setInit}
                                setLoading={setLoading}
                                data={viewModel}
                                cardTitle="Roof Window"
                            >
                                <div className="form-row row">
                                    <div className="col-6">
                                        <div className="position-relative form-group">
                                            <label htmlFor="RoofWindowName">Name *</label>
                                            <BindedInput
                                                className="form-control"
                                                id="RoofWindowName"
                                                name="RoofWindowName"
                                                type="text"
                                                validate={formValidate}
                                                binding={[viewModel, "name", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="position-relative form-group">
                                            <label htmlFor="RoofWindowConstructionId">
                                                Construction *
                                            </label>
                                            <BindedSelect
                                                className="form-control"
                                                id="RoofWindowConstructionId"
                                                name="RoofWindowConstructionId"
                                                options="constructions"
                                                validate={formValidate}
                                                binding={[
                                                    viewModel,
                                                    "constructionId",
                                                    bindingViewModel,
                                                ]}
                                            ></BindedSelect>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="col-6">
                                        <div className="position-relative form-group">
                                            <label htmlFor="IndoorShadingTypeId">
                                                Indoor Shading *
                                            </label>
                                            <BindedSelect
                                                className="form-control"
                                                id="IndoorShadingTypeId"
                                                name="IndoorShadingTypeId"
                                                options="indoorShadingTypes"
                                                validate={formValidate}
                                                binding={[viewModel, "indoorShadingTypeId", bindingViewModel]}
                                            ></BindedSelect>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="position-relative form-group">
                                            <label htmlFor="OutdoorShadingPercentTypeId">
                                                Outdoor *
                                            </label>
                                            <BindedSelect
                                                className="form-control"
                                                id="OutdoorShadingPercentTypeId"
                                                name="OutdoorShadingPercentTypeId"
                                                options="outdoorShadingPercentTypes"
                                                validate={formValidate}
                                                binding={[
                                                    viewModel,
                                                    "outdoorShadingPercentTypeId",
                                                    bindingViewModel,
                                                ]}
                                            ></BindedSelect>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="col-4">
                                        <div className="position-relative form-group">
                                            <label htmlFor="OpenablePercent">Openable (%) *</label>
                                            <BindedInput
                                                className="form-control"
                                                id="OpenablePercent"
                                                name="OpenablePercent"
                                                type="number"
                                                min="0"
                                                validate={formValidate}
                                                binding={[viewModel, "openablePercent", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="position-relative form-group">
                                            <label htmlFor="GrossArea">Area (m<sup>2</sup>) *</label>
                                            <BindedInput
                                                className="form-control"
                                                id="GrossArea"
                                                name="GrossArea"
                                                type="number"
                                                min="0"
                                                validate={formValidate}
                                                binding={[viewModel, "grossArea", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="position-relative form-group">
                                            <label htmlFor="Pitch">Pitch (degree) *</label>
                                            <BindedInput
                                                className="form-control"
                                                id="Pitch"
                                                name="Pitch"
                                                type="number"
                                                min="0"
                                                validate={formValidate}
                                                binding={[viewModel, "pitch", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="custom-checkbox custom-control">
                                        <BindedCheckBox
                                            disabled = {pageProfile.getModuleTypes(appStoreState).filter(e => e.simulationType === 'Existing Home').length > 0}
                                            id="HasInsectScreens"
                                            name="HasInsectScreens"
                                            className="custom-control-input"
                                            binding={[
                                                viewModel,
                                                "hasInsectScreens",
                                                bindingViewModel,
                                            ]}
                                        ></BindedCheckBox>
                                        <label
                                            style={{ marginLeft: "5px" }}
                                            htmlFor="HasInsectScreens"
                                            className="custom-control-label"
                                        >
                                            Insect screens
                                        </label>
                                    </div>
                                </div>
                            </BaseFormNoHeader>
                        </div>
                    </div>
                    {init === true &&
                    <div className="col-xl-6 col-lg-12">
                        <div className="main-card mb-3 card">
                            <div className="card-header">
                                <button type="button" data-toggle="collapse" data-target="#ConstructionsCollapsibleDiv" aria-expanded="true" aria-controls="ConstructionsCollapsibleDiv" className="text-left m-0 p-0 btn btn-block">
                                    <h5 className="m-0 p-0 card-title">Roof Window Constructions</h5>
                                </button>
                            </div>
                            <div data-parent="#ConstructionsAccordion" id="ConstructionsCollapsibleDiv" className="collapse show">
                                <div className="card-body">
                                    <div className="separator"></div>
                                    <ConstructionElementDataTable
                                        tableId={"RoofWindowConstructionElement-84078b3f-c84a-45f1-8060-774b099358f4"}
                                        tableReload={tableReloadForConstruction}
                                        setTableReload={setTableReloadForConstruction}
                                        extraPostModel={extraPostModelForConstruction}
                                        constructionType="RoofWindow"
                                        redirectUrl={currentUrl}
                                        debug={debug}
                                    >
                                    </ConstructionElementDataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                
                </div>
            </div>
        </Fragment>
    );
}
export default connectAppStore(RoofWindowDetail);