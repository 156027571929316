import TooltipTemplate from "../../../../Layout/AppNav/TooltipTemplate";
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";
import ConstructionElementDataTable from "../../../Accessor/Construction/ConstructionElementDataTable";
import objectPropertyValueGetSet from "../../../../Function/ObjectPropertyValueGetSet";
const WindowDetail = (props) => {
    const {
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        useLocation,
        BaseFormNoHeader,
        BindedInput,
        BindedCheckBox,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
        dataExtension,
        FormValidate,
        useEffectWithPrevious,
        Fragment, useEffect, useState
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const currentUrl = useLocation().pathname;
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const [init, setInit] = useState(false);
    const [reloadForm, setReloadForm] = useState("");
    const { elementId } = defaultParams;
    let { designVariationVersionName, developmentId, projectId, designVariationId, zoneId, parentId, redirectUrlHex } = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
        selectedItemIds: [elementId]
    };
    const [extraPostModelForConstruction, setExtraPostModelForConstruction] = useState({
        developmentId: developmentId,
        projectId: projectId,
        designVariationId: designVariationId,
        designVariationVersionName: designVariationVersionName,
        constructionType:"Window"
    });
    const [tableReloadForConstruction, setTableReloadForConstruction] = useState("");
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
 
    //change model get url here if not using default model
    const getUrl = "api/Element/Accessor/GetWindow";
    //add model post url here
    const postUrl = "api/Element/Accessor/UpdateWindow";
    const validateMethods = {
        //add property validate here
        name:(value)=>{return FormValidate.validateTextRequired(value, "Window name");},
        constructionId:(value)=>{ return value === emptyGuid ? "Must select a construction." : "";},
        length: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Length");},
        height: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Height");},
        windowTypeId:(value)=>{ return value == "-999" ? "Must select a window type." : "";},
        indoorCoveringTypeId:(value)=>{ return value == "-999" ? "Must select a indoor covering type." : "";},
        outdoorCoveringTypeId:(value)=>{ return value == "-999" ? "Must select a outdoor covering type." : "";},
        gapSizeTypeId: (value, newViewModel) => { return ((!newViewModel.isWeatherstripped) && value<1) ? "Must select a valid gap size" : ""},
        headHeight: (value, newViewModel)=>{ return value-newViewModel.height<0? "Window height must be <= Head height" : ""}
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        if(init===false) setInit(true);
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading data", appStoreDispatch, undefined, setInit);
    },[]);

    useEffect(()=>{  
        if(init){
          dispatchViewModel({...viewModel, elementId: elementId, id: elementId, selectedItemIds: [elementId]},()=>{
            setReloadForm(guid.newGuid());
          });
        } 
      },[elementId]);

    useEffectWithPrevious((oldState) => {
        if(oldState === undefined) return;
        const prevModeTypeId = oldState[0];
        if(prevModeTypeId !== undefined && viewModel.isWeatherstripped !== undefined){
            if (viewModel.dwellingType === "New" && viewModel.isWeatherstripped) {
                dispatchViewModel({...viewModel, gapSizeTypeId: 1});
            }
            else if (viewModel.dwellingType === "New" && !viewModel.isWeatherstripped) {
                dispatchViewModel({...viewModel, gapSizeTypeId: 2});
            }
        }
    },[viewModel.isWeatherstripped]);
    
    useEffectWithPrevious((oldValue)=>{
        if(oldValue === undefined || oldValue[0] === undefined) return;
        if(viewModel.fixedShadingId != emptyGuid){
            const selected = dataExtension.getSelectOptionByValue(viewModel.fixedShadings, viewModel.fixedShadingId);
            const newModel = {... viewModel};
            if(newModel.horizontalShadingElement === undefined || newModel.horizontalShadingElement === null){
                newModel.horizontalShadingElement = {};
            }
            newModel.horizontalShadingElement.eaveWidth = 0;
            newModel.horizontalShadingElement.eaveLength = 0;
            newModel.horizontalShadingElement.eaveVerticalOffset = 0;
            newModel.horizontalShadingElement.eaveHorizontalOffset = 0;
            newModel.horizontalShadingElement.eaveProjectionTypeId = 2;
            newModel.horizontalShadingElement.otherFixedShadingWidth = 0
            newModel.horizontalShadingElement.otherFixedShadingLength = 0;
            newModel.horizontalShadingElement.otherFixedShadingVerticalOffset = 0;
            newModel.horizontalShadingElement.otherFixedShadingHorizontalOffset = 0;
            newModel.horizontalShadingElement.otherFixedShadingProjectionTypeId = 2;
            newModel.horizontalShadingElement.eaveProjectionTypes = [{text:"Length", value: 1}, {text:"Width", value: 2}];
            newModel.horizontalShadingElement.otherFixedShadingProjectionTypes = [{text:"Length", value: 1}, {text:"Width", value: 2}];
            //eave
            if(selected.eaveWidth !== undefined && selected.eaveWidth != null && parseInt(selected.eaveWidth) > 0){
                newModel.horizontalShadingElement.eaveWidth = selected.eaveWidth;
            }
            if(selected.eaveLength !== undefined && selected.eaveLength != null && parseInt(selected.eaveLength) > 0){
                newModel.horizontalShadingElement.eaveLength = selected.eaveLength;
            }
            if(selected.eaveVerticalOffset !== undefined && selected.eaveVerticalOffset != null && parseInt(selected.eaveVerticalOffset) > -999999999999){
                newModel.horizontalShadingElement.eaveVerticalOffset = selected.eaveVerticalOffset;
            }
            if(selected.eaveHorizontalOffset !== undefined && selected.eaveHorizontalOffset != null && parseInt(selected.eaveHorizontalOffset) > -999999999999){
                newModel.horizontalShadingElement.eaveHorizontalOffset = selected.eaveHorizontalOffset;
            }
            if(selected.eaveProjectionTypeId !== undefined && selected.eaveProjectionTypeId != null && parseInt(selected.eaveProjectionTypeId) > -999999999){
                newModel.horizontalShadingElement.eaveProjectionTypeId = selected.eaveProjectionTypeId;
            }
            //pergola
            if(selected.otherFixedShadingWidth !== undefined && selected.otherFixedShadingWidth != null && parseInt(selected.otherFixedShadingWidth) > 0){
                newModel.horizontalShadingElement.otherFixedShadingWidth = selected.otherFixedShadingWidth;
            }
            if(selected.otherFixedShadingLength !== undefined && selected.otherFixedShadingLength != null && parseInt(selected.otherFixedShadingLength) > 0){
                newModel.horizontalShadingElement.otherFixedShadingLength = selected.otherFixedShadingLength;
            }
            if(selected.otherFixedShadingVerticalOffset !== undefined && selected.otherFixedShadingVerticalOffset != null && parseInt(selected.otherFixedShadingVerticalOffset) > -999999999999){
                newModel.horizontalShadingElement.otherFixedShadingVerticalOffset = selected.otherFixedShadingVerticalOffset;
            }
            if(selected.otherFixedShadingHorizontalOffset !== undefined && selected.otherFixedShadingHorizontalOffset != null && parseInt(selected.otherFixedShadingHorizontalOffset) > -999999999999){
                newModel.horizontalShadingElement.otherFixedShadingHorizontalOffset = selected.otherFixedShadingHorizontalOffset;
            }
            if(selected.otherFixedShadingProjectionTypeId !== undefined && selected.otherFixedShadingProjectionTypeId != null && parseInt(selected.otherFixedShadingProjectionTypeId) > -999999999){
                newModel.horizontalShadingElement.otherFixedShadingProjectionTypeId = selected.otherFixedShadingProjectionTypeId;
            }
            dispatchViewModel(newModel);
        }
    },[viewModel.fixedShadingId]);

    const generateStayUrl = (submitResponse)=>{
        let redirectUrl = currentUrl;
        if(elementId === emptyGuid){
            //"ElementAccessor/Detail/Window/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:parentId/:redirectUrlHex"
            redirectUrl =  "/ElementAccessor/Detail/Window/" + designVariationVersionName + "/" + developmentId +"/"+projectId+"/"+designVariationId+"/"+zoneId+"/"+submitResponse.elementId+"/"  +parentId+ "/" + redirectUrlHex;
        }
        return redirectUrl;
    }

    const calculateArea = (length, height) => {
        if (length === undefined || length === "" || length === null) length = 0;
        if (height === undefined || height === "" || height === null) height = 0;
        length = Number(length);
        height = Number(height);
        return ((length * height) / 1e6).toFixed(2);
      };

    const calculateHeightFromFloor = () => {
        const windowsHeight = viewModel.height === ''  || viewModel.height === undefined ? 0 : viewModel.height;
        const windowsHeadHeight = viewModel.headHeight === '' || viewModel.headHeight === undefined ? 0 : viewModel.headHeight;
        return windowsHeadHeight - windowsHeight;
    }

    const getBlockingFactors = (blockingFactor) => {
        const value = objectPropertyValueGetSet.getPropertyValue(viewModel, blockingFactor);
        return value;
    }

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                            <i className="pe-7s-safe icon-gradient bg-tempting-azure">
                            </i>
                            </div>
                            <div>
                            Window
                            <div className="page-title-subheading">
                                {pageProfile.getPageSubTitle(appStoreState)}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-lg-12">
                        <div className="main-card mb-3 card">
                            <div className="card-body">
                            <BaseFormNoHeader
                                id="ExternalWallDetail"
                                submitButton={{ postUrl: postUrl, redirectUrl: viewModel.redirectUrl, text: "Save and close"}}
                                submitButtonAndStay={{ text:"Save", postUrl: postUrl, redirectMethod: (submitResponse)=>{return generateStayUrl(submitResponse)}}}
                                cancelButton={{ postUrl: postUrl, redirectUrl: viewModel.redirectUrl }}
                                validate={formValidate}
                                setReload = {setReloadForm}
                                loading={loading}
                                init={init}
                                setLoading={setLoading}
                                data={viewModel}
                                cardTitle={"Window in external wall " + viewModel.externalWallName + " (zone " + viewModel.zoneName + ")" }
                            >
                                <div className="form-row row">
                                    <div className="col-12">
                                        <div className="position-relative form-group">
                                        <label htmlFor="WindowName">Name *</label>
                                        <BindedInput
                                            className="form-control"
                                            id="WindowName"
                                            name="WindowName"
                                            type="text"
                                            validate={formValidate}
                                            binding={[viewModel, "name", bindingViewModel]}
                                        ></BindedInput>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="col-12">
                                        <div className="position-relative form-group">
                                            <label htmlFor="WindowConstructionId">
                                                Construction *
                                            </label>
                                            <BindedSelect
                                                className="form-control"
                                                id="WindowConstructionId"
                                                name="WindowConstructionId"
                                                options="constructions"
                                                validate={formValidate}
                                                binding={[
                                                viewModel,
                                                "constructionId",
                                                bindingViewModel,
                                                ]}
                                            ></BindedSelect>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="WindowTypeId">
                                                Type *
                                            </label>
                                            <BindedSelect
                                                className="form-control"
                                                id="WindowTypeId"
                                                name="WindowTypeId"
                                                options="windowTypes"
                                                validate={formValidate}
                                                binding={[
                                                viewModel,
                                                "windowTypeId",
                                                bindingViewModel,
                                                ]}
                                            ></BindedSelect>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="WindowIndoorCoveringTypeId">
                                            Indoor covering type *
                                            </label>
                                            <BindedSelect
                                                disabled ={viewModel.simulationType!=="Existing Home"}
                                                className="form-control"
                                                id="WindowIndoorCoveringTypeId"
                                                name="WindowIndoorCoveringTypeId"
                                                options="indoorCoveringTypes"
                                                validate={formValidate}
                                                binding={[
                                                viewModel,
                                                "indoorCoveringTypeId",
                                                bindingViewModel,
                                                ]}
                                            ></BindedSelect>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="WindowOutdoorCoveringTypeId">
                                            Outdoor covering *
                                            </label>
                                            <BindedSelect
                                                className="form-control"
                                                id="WindowOutdoorCoveringTypeId"
                                                name="WindowOutdoorCoveringTypeId"
                                                options="outdoorCoveringTypes"
                                                validate={formValidate}
                                                binding={[
                                                viewModel,
                                                "outdoorCoveringTypeId",
                                                bindingViewModel,
                                                ]}
                                            ></BindedSelect>
                                    </div>
                                </div>                
                                </div>
                                <div className="form-row row">
                                    <div className="col-12">
                                        <div className="position-relative form-group">
                                        <label htmlFor="ExternalWallFixedShadingId">
                                            Fixed shading
                                        </label>
                                        <BindedSelect
                                            className="form-control"
                                            id="ExternalWallFixedShadingId"
                                            name="ExternalWallFixedShadingId"
                                            options="fixedShadings"
                                            binding={[
                                            viewModel,
                                            "fixedShadingId",
                                            bindingViewModel,
                                            ]}
                                        ></BindedSelect>
                                        </div>
                                    </div>
                                </div>

                                {viewModel.fixedShadingId !== emptyGuid && <Fragment>
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <h6>Eave</h6>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveLength">Length (mm)</label>
                                                <BindedInput
                                                    className="form-control"
                                                    id="ExternalWallFixedShading_HorizontalShadingEaveLength"
                                                    name="ExternalWallFixedShading_HorizontalShadingEaveLength"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.eaveLength", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveWidth">Width (mm)</label>
                                                <BindedInput
                                                    className="form-control valid"
                                                    id="ExternalWallFixedShading_HorizontalShadingEaveWidth"
                                                    name="ExternalWallFixedShading_HorizontalShadingEaveWidth"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.eaveWidth", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveProjectionTypeId">Projection</label>
                                                <BindedSelect
                                                    className="form-control"
                                                    id="ExternalWallFixedShading_HorizontalShadingEaveProjectionTypeId"
                                                    name="ExternalWallFixedShading_HorizontalShadingEaveProjectionTypeId"
                                                    options="horizontalShadingElement.eaveProjectionTypes"
                                                    binding={[viewModel, "horizontalShadingElement.eaveProjectionTypeId", bindingViewModel]}
                                                ></BindedSelect>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveVerticalOffset">Vertical offset (mm)</label>
                                                <BindedInput
                                                    className="form-control"
                                                    id="ExternalWallFixedShading_HorizontalShadingEaveVerticalOffset"
                                                    name="ExternalWallFixedShading_HorizontalShadingEaveVerticalOffset"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.eaveVerticalOffset", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveHorizontalOffset">Horizontal offset (mm)</label>
                                                <BindedInput
                                                    className="form-control valid"
                                                    id="ExternalWallFixedShading_HorizontalShadingEaveHorizontalOffset"
                                                    name="ExternalWallFixedShading_HorizontalShadingEaveHorizontalOffset"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.eaveHorizontalOffset", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <h6>Other fixed shading</h6>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingLength">Length (mm)</label>
                                                <BindedInput
                                                    className="form-control"
                                                    id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingLength"
                                                    name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingLength"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.otherFixedShadingLength", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingWidth">Width (mm)</label>
                                                <BindedInput
                                                    className="form-control valid"
                                                    id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingWidth"
                                                    name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingWidth"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.otherFixedShadingWidth", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingProjectionTypeId">Projection</label>
                                                <BindedSelect
                                                    className="form-control"
                                                    id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingProjectionTypeId"
                                                    name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingProjectionTypeId"
                                                    options="horizontalShadingElement.eaveProjectionTypes"
                                                    binding={[viewModel, "horizontalShadingElement.otherFixedShadingProjectionTypeId", bindingViewModel]}
                                                ></BindedSelect>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingVerticalOffset">Vertical offset (mm)</label>
                                                <BindedInput
                                                    className="form-control"
                                                    id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingVerticalOffset"
                                                    name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingVerticalOffset"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.otherFixedShadingVerticalOffset", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingHorizontalOffset">Horizontal offset (mm)</label>
                                                <BindedInput
                                                    className="form-control valid"
                                                    id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingHorizontalOffset"
                                                    name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingHorizontalOffset"
                                                    type="number"
                                                    validate={formValidate}
                                                    binding={[viewModel, "horizontalShadingElement.otherFixedShadingHorizontalOffset", bindingViewModel]}>
                                                </BindedInput>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <label>Blocking factor (opaque = 100%)</label>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage0">Jan</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage0")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage1">Feb</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage1")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage2">Mar</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage2")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage3">Apr</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage3")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage4">May</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage4")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage5">Jun</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage5")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage6">Jul</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage6")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage7">Aug</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage7")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage8">Sep</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage8")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage9">Oct</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage9")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage10">Nov</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage10")}</label>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="position-relative form-group">
                                                <label htmlFor="ShadingBlockingFactorPercentage11">Dec</label>
                                                <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage11")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row"></div>
                                </Fragment>}
                                <div className="form-row row">
                                    <div className="col-4">
                                        <div className="position-relative form-group">
                                        <label htmlFor="ExternalWallLength">Width (mm) *</label>
                                        <BindedInput
                                            className="form-control"
                                            id="ExternalWallLength"
                                            name="ExternalWallLength"
                                            type="number"
                                            min="0"
                                            validate={formValidate}
                                            binding={[viewModel, "length", bindingViewModel]}
                                        ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="position-relative form-group">
                                        <label htmlFor="ExternalWallHeight">Height (mm) *</label>
                                        <BindedInput
                                            className="form-control"
                                            id="ExternalWallHeight"
                                            name="ExternalWallHeight"
                                            type="number"
                                            min="0"
                                            validate={formValidate}
                                            binding={[viewModel, "height", bindingViewModel]}
                                        ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="position-relative form-group">
                                        <label htmlFor="ExternalWallGrossArea">
                                            Area (m<sup>2</sup>) *
                                        </label>
                                        <label className="form-control" style={{ border: "none", backgroundColor: "white" }}>{calculateArea(viewModel.length, viewModel.height)}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="col-4">
                                        <div className="position-relative form-group">
                                        <label htmlFor="WindowHeadHeight">Head height (mm) *</label>
                                        <BindedInput
                                            className="form-control"
                                            id="WindowHeadHeight"
                                            name="WindowHeadHeight"
                                            type="number"
                                            min="0"
                                            validate={formValidate}
                                            binding={[viewModel, "headHeight", bindingViewModel]}
                                        ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="position-relative form-group">
                                        <label>
                                            Height from floor (mm)
                                        </label>
                                        <label className="form-control" style={{ border: "none", backgroundColor: "white" }}>{calculateHeightFromFloor()}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="col-4" style={{marginBottom:"15px"}}>
                                        <div className="position-relative form-group">
                                        <label htmlFor="WindowHorizontalOffset">
                                            Horizontal offset (mm) *
                                        </label>
                                        <BindedInput
                                            className="form-control"
                                            min="0"
                                            id="WindowHorizontalOffset"
                                            name="WindowHorizontalOffset"
                                            type="number"
                                            validate={formValidate}
                                            binding={[viewModel, "horizontalOffset", bindingViewModel]}
                                        ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="position-relative form-group">
                                        <label htmlFor="WindowOpenablePercent">
                                            Openable (%) *<TooltipTemplate text="Fixed = 0%, Openable = 45% and Highly Openable = 90%" result={0} color="grey" />
                                        </label>
                                        <BindedInput                                 
                                            className="form-control"
                                            min="0"
                                            max="100"
                                            id="WindowOpenablePercent"
                                            name="WindowOpenablePercent"
                                            type="number"
                                            validate={formValidate}
                                            binding={[viewModel, "openablePercent", bindingViewModel]}
                                        ></BindedInput>
                                        </div>
                                    </div>     
                                </div>
                                <div className="form-row row">
                                    <div className="custom-checkbox custom-control">
                                        <BindedCheckBox
                                            disabled={viewModel.dwellingType === "New"}
                                            id="WindowHasInsectScreens"
                                            name="WindowHasInsectScreens"
                                            className="custom-control-input"
                                            binding={[
                                                viewModel,
                                                "hasInsectScreens",
                                                bindingViewModel,
                                            ]}
                                        ></BindedCheckBox>
                                        <label
                                        style={{ marginLeft: "5px" }}
                                        htmlFor="WindowHasInsectScreens"
                                        className="custom-control-label"
                                        >
                                        Insect screens
                                        </label>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="custom-checkbox custom-control">
                                        <BindedCheckBox
                                            disabled={viewModel.dwellingType === "New" && String(viewModel.zoneTypeId) !== "7"}
                                            id="WindowIsWeatherstripped"
                                            name="WindowIsWeatherstripped"
                                            className="custom-control-input"
                                            binding={[
                                                viewModel,
                                                "isWeatherstripped",
                                                bindingViewModel,
                                            ]}
                                        ></BindedCheckBox>
                                        <label
                                        style={{ marginLeft: "5px" }}
                                        htmlFor="WindowIsWeatherstripped"
                                        className="custom-control-label"
                                        >
                                        Weatherstripped
                                        </label>
                                    </div>
                                </div>
                                <div className="form-row row">                       
                                    <div className="position-relative form-group" style={{ marginLeft: "5px" }}>
                                        <label htmlFor="WindowGapSizeTypeId">
                                            Gap size
                                        </label>
                                        <BindedSelect
                                            disabled={viewModel.isWeatherstripped===true}
                                            className="form-control"
                                            id="WindowGapSizeTypeId"
                                            name="WindowGapSizeTypeId"
                                            options="gapSizeTypes"
                                            validate={formValidate}
                                            binding={[
                                            viewModel,
                                            "gapSizeTypeId",
                                            bindingViewModel,
                                            ]}
                                        ></BindedSelect>
                                    </div>
                                </div>
                                {viewModel.simulationType === "Existing Home" &&
                                    <>
                                        <div className="form-row row">
                                            <div className="col-6">
                                                <div className="custom-checkbox custom-control">
                                                    <BindedCheckBox
                                                        id="IsExistingIndoorCoveringPresent"
                                                        name="IsExistingIndoorCoveringPresent"
                                                        className="custom-control-input"
                                                        binding={[
                                                            viewModel,
                                                            "isExistingIndoorCoveringPresent",
                                                            bindingViewModel,
                                                        ]}
                                                    ></BindedCheckBox>
                                                    <label
                                                    style={{ marginLeft: "5px" }}
                                                    htmlFor="IsExistingIndoorCoveringPresent"
                                                    className="custom-control-label"
                                                    >
                                                    Indoor Window Covering Characteristics
                                                    </label>
                                                </div>
                                            </div>
                                        </div>                                     
                                        {viewModel.isExistingIndoorCoveringPresent &&
                                            <>
                                                <div className="form-row row">
                                                    <div className="col-6">
                                                        <div className="position-relative form-group">
                                                            <label htmlFor="ExistingWindowStreetSideAppearanceTypeId">Outside appearance (colour)</label>
                                                            <BindedSelect
                                                                className="form-control"
                                                                id="ExistingWindowStreetSideAppearanceTypeId"
                                                                name="ExistingWindowStreetSideAppearanceTypeId"
                                                                options="existingWindowStreetSideAppearanceTypes"
                                                                binding={[viewModel, "existingWindowStreetSideAppearanceTypeId", bindingViewModel]}
                                                            ></BindedSelect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row row">
                                                    <div className="col-6">
                                                        <div className="position-relative form-group">
                                                            <label htmlFor="ExistingWindowLightPassingThroughTypeId">Light transmittance</label>
                                                            <BindedSelect
                                                                className="form-control"
                                                                id="ExistingWindowLightPassingThroughTypeId"
                                                                name="ExistingWindowLightPassingThroughTypeId"
                                                                options="existingWindowLightPassingThroughTypes"
                                                                binding={[viewModel, "existingWindowLightPassingThroughTypeId", bindingViewModel]}
                                                            ></BindedSelect>
                                                        </div>
                                                    </div>
                                                </div>
                                                 <div className="form-row row">                                                                                                               
                                                    <div className="col-6">
                                                        <div className="position-relative form-group">
                                                        <label htmlFor="ExistingWindowInsulationAirflowTypeId">Insulation value + Window covering fit</label>
                                                        <BindedSelect
                                                            className="form-control"
                                                            id="ExistingWindowInsulationAirflowTypeId"
                                                            name="ExistingWindowInsulationAirflowTypeId"
                                                            options="existingWindowInsulationAirflowTypes"
                                                            binding={[viewModel, "existingWindowInsulationAirflowTypeId", bindingViewModel]}
                                                        ></BindedSelect>
                                                        </div>
                                                    </div>
                                                </div>   
                                            </>                              
                                        }                                                                 
                                    </>
                                }                              
                            </BaseFormNoHeader>
                            </div>
                        </div>
                    </div>
                    {init === true &&
                         <div className="col-xl-6 col-lg-12">
                         <div className="main-card mb-3 card">
                             <div className="card-header">
                                 <button type="button" data-toggle="collapse" data-target="#ConstructionsCollapsibleDiv" aria-expanded="true" aria-controls="ConstructionsCollapsibleDiv" className="text-left m-0 p-0 btn btn-block">
                                 <h5 className="m-0 p-0 card-title">Window Constructions</h5>
                                 </button>
                             </div>
                             <div data-parent="#ConstructionsAccordion" id="ConstructionsCollapsibleDiv" className="collapse show">
                                 <div className="card-body">
                                 <div className="separator"></div>
                                     <ConstructionElementDataTable
                                         tableId = {"WindowConstructionElement-4fe4679d-10e8-4618-9673-5fc0795f1f21"}
                                         tableReload={tableReloadForConstruction}
                                         setTableReload={setTableReloadForConstruction}
                                         extraPostModel = {extraPostModelForConstruction}
                                         constructionType = "Window"
                                         redirectUrl = {currentUrl}
                                         debug = {debug}
                                     >
                                     </ConstructionElementDataTable>
                                 </div>
                             </div>
                         </div>
                     </div>
                    }
               
                </div>
            </div>
        </Fragment>
    );
}
export default connectAppStore(WindowDetail);