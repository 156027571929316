
import { connectAppStore } from "../../../Store/AppStore";
import { defaultProjectSliceActions } from "../../../../components/Store/reducers/index";
import PageDependencies from "../../../Feature/PageDependencies";
import { Button } from 'primereact/button';

const About = (props) => {
  const { 
    layoutManager,
    getData,
    guid,
    useReducerSmart,
    toastPrint,
    usePrevious,
    useParams,
    BaseForm,
    BindedInput,
    BindedSelect,
    BindedTextArea,
    tryCatchFinally,
    BindedCheckBox,
    BindedInputNumber,
    getAppStore,
    useNavigate,
    useEffectWithPrevious,
    pageProfile,
    Fragment, useEffect, useState,
    FormValidate} = PageDependencies();
  const [appStoreState, appStoreDispatch] = getAppStore(props);
  const debug = layoutManager.Layout_GetDebugModel(props);
  const nav = useNavigate();
  const emptyGuid = guid.emptyGuid();
  const developmentId = useParams().developmentId;
  const projectId = useParams().projectId;
  const postUrl = "api/Project/Accessor/Update";
  const getUrl = "api/Project/Accessor/Get";
  const defaultAboutViewModel = {
    id: guid.emptyGuid(),
    developmentId: developmentId,
    projectId: projectId,
    designVariationId: emptyGuid,
    designVariationVersionName: ""
  };
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState({isLoading:false, text:""});
  const [about, dispatchAbout, bindingAbout] = useReducerSmart(defaultAboutViewModel);
  const validateMethods = {
    projectName:(value)=>{return FormValidate.validateTextRequired(value, "Project name")},
    projectCode:(value)=>{return FormValidate.validateTextRequired(value, "Project code")},
    declarationOfInterestTypeId:(value)=>{return parseInt(value) >= 1 ? "" : "Select a interest";},
    yearBuilt:(value, newAbout)=>{
      if(newAbout.simulationTypeId == 3){
        return value == 0? "" : FormValidate.validateNumberBetween(value, "Year Build", 1800, new Date().getFullYear());
      }
      return "";
    },
  };
  const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState());

  const loadModel = async (navToDwelling) => {
    setLoading((preLoad)=>{return {...preLoad, isLoading: true, text:"Loading project"};});
    try{
      const loadModelResponse = await getData.processPost(true, getUrl, about);
      if(loadModelResponse.state===false){
          toastPrint.printResponse(loadModelResponse);
          setLoading((preLoad)=>{return {...preLoad, isLoading: false, text:""};}); 
          return;
      }
    
      //write store for devlopmentId and projectId
      appStoreDispatch(
        defaultProjectSliceActions.setDefaultProject({
          type: "projectId",
          projectId: projectId
        })
      );
      pageProfile.setDefaultValues(appStoreDispatch, loadModelResponse.viewModel);
      dispatchAbout(loadModelResponse.viewModel, ()=>{
        setInit(true);
        setLoading({isLoading: false, text:""}); 
      });
      // if(developmentId != loadModelResponse.viewModel.developmentId || projectId != loadModelResponse.viewModel.projectId){
      //   nav("/ProjectAccessor/Detail/" + loadModelResponse.viewModel.developmentId + "/" + loadModelResponse.viewModel.projectId, {replace:true});
      //   setLoading((preLoad)=>{return {...preLoad, isLoading: false, text:""};}); 
      // }
      if(navToDwelling === true){
        nav("/ProjectAccessor/Dwelling/" + loadModelResponse.viewModel.projectId, {replace:true});
      }
    }catch(ex){
      toastPrint.printErrorMessage(ex.message);
      setLoading((preLoad)=>{return {...preLoad, isLoading: false, text:""};}); 
    }
  };

  const tickAll = (e) => {
    e.preventDefault();
    dispatchAbout({...about, separateLoadLimitsApply: true, softwareProviderResponsibilities: true, provideAllInformation: true, adherenceToTechNote: true, softwareUserTerms: true});
  };

  const previousProjectId = usePrevious(projectId); 
  useEffect(() => {
    tryCatchFinally.syncProcess(()=>{
      if (projectId == guid.emptyGuid()) {
        layoutManager.Layout_HideHeadterButton_Footer_HideSideBar(props);
      } else {
        layoutManager.Layout_Headter_Footer_SideBar(props);
      }
      const navToDwelling = previousProjectId == guid.emptyGuid() && projectId !== null;
      loadModel(navToDwelling);
    });
  }, [projectId]);

  useEffect(() => {
      dispatchAbout({...about, developmentId: developmentId})
  }, [developmentId]);

  useEffectWithPrevious((pAboutDependencies) => {
    if(pAboutDependencies === undefined) return;
    if(pAboutDependencies.length === 0) return;
    const dwellingTypeId = pAboutDependencies[0];
    if(dwellingTypeId === undefined && about.designVariationId != guid.emptyGuid()) return;
    if(about.simulationTypeId == 1) return;
 
    let simulationTypeId = 1;
    if(about.dwellingTypeId == 2 || about.dwellingTypeId == 1){
      simulationTypeId = 2;
    }
    if(about.dwellingTypeId == 3){
      simulationTypeId = 3;
    }
    dispatchAbout({...about, simulationTypeId: simulationTypeId});
  }, [about.dwellingTypeId]);

  const [disableButton, setDisableButton] = useState(true);
  useEffectWithPrevious((pAboutDependencies)=>{
    // if(disableButton === false) return;
    if(pAboutDependencies === undefined) return;
    if(pAboutDependencies.length === 0) return;
    const pAbout = pAboutDependencies[0];
    if(init === true){
        if(Object.keys(pAbout).length === Object.keys(about).length){
          const isDiff = JSON.stringify(pAbout) != JSON.stringify(about);
          const isAllTicked = about.separateLoadLimitsApply && about.softwareProviderResponsibilities && about.provideAllInformation && about.adherenceToTechNote && about.softwareUserTerms;
          if(isDiff && isAllTicked){
            setDisableButton(false);
          }
          if (!isAllTicked) {
            setDisableButton(true);
          }
        }
    }
  }, [about]);

  return (
    <Fragment>
      {debug && <p>{JSON.stringify(about)}</p>}
      <BaseForm
        id="ProjectFrom"
        icon="pe-7s-home"
        loading={loading}
        init={init}
        setLoading={setLoading}
        validate = {formValidate}
        submitButtonDisabled = {disableButton}
        submitButton={{ postUrl: postUrl, redirectUrl: developmentId != emptyGuid && projectId != emptyGuid ? "/ProjectAccessor/Dwelling" : "/ProjectAccessor/Detail", redirectParams:function(submitResponse){
          dispatchAbout({...about, developmentId:submitResponse.developmentId, projectId: submitResponse.projectId});
          if(developmentId != emptyGuid && projectId != emptyGuid){
            return projectId;
          }
          return submitResponse.developmentId + "/" + submitResponse.projectId; 
        } }}
        cancelButton={{ redirectUrl: "/DevelopmentAccessor" }}
        data={about}
        title={projectId == emptyGuid ? "About" : "Project profile - About"}
        subTitle={projectId == emptyGuid ? "Create new project" : pageProfile.getPageSubTitle(appStoreState)}
      >
        <div className="form-row row">
          <div className="col-6">
            <div className="position-relative form-group">
              <label htmlFor="ProjectName">Project name *</label>
              <BindedInput
                className="form-control"
                binding={[about, "projectName", bindingAbout]}
                id="ProjectName"
                name="ProjectName"
                type="text"
                validate = {formValidate}
              ></BindedInput>
            </div>

          </div>
          <div className="col-6">
            <div className="position-relative form-group">
              <label htmlFor="ProjectCode">Project code *</label>
              <BindedInput
                className="form-control"
                binding={[about, "projectCode", bindingAbout]}
                id="ProjectCode"
                name="ProjectCode"
                type="text"
                validate = {formValidate}
              ></BindedInput>
            </div>
          </div>
        </div>
        <div className="form-row row">
          <div className="col-6">
            <div className="position-relative form-group">
              <label htmlFor="DwellingTypeId">Dwelling type *</label>
              <BindedSelect
                className="form-control"
                id="DwellingTypeId"
                name="DwellingTypeId"
                binding={[about, "dwellingTypeId", bindingAbout]}
                options={"dwellingTypes"}
              ></BindedSelect>
            </div>
          </div>
          <div className="col-6">
            <div className="position-relative form-group">
              <label htmlFor="BuildingClassTypeId">Building class *</label>
              <BindedSelect
                className="form-control"
                id="BuildingClassTypeId"
                name="BuildingClassTypeId"
                binding={[
                  about,
                  "buildingClassTypeId",
                  bindingAbout,
                ]}
                options={"buildingClassTypes"}
              ></BindedSelect>
            </div>
          </div>
        </div>
        <div className="form-row row">
          <div className="col-6">
            <div className="position-relative form-group">
              <label htmlFor="SimulationTypeId">Simulation type *</label>
              <BindedSelect
                className="form-control"
                id="SimulationTypeId"
                name="SimulationTypeId"
                binding={[about, "simulationTypeId", bindingAbout]}
                options={"simulationTypes"}
                style={{marginBottom:"15px"}}
                disabled
              ></BindedSelect>
            </div>
          </div>
          {( about.simulationTypeId == 3) && 
            <div className="col-6">
            <div className="position-relative form-group">
              <BindedInputNumber inputId="YearBuilt" label="Year of Construction *" min="0" max={new Date().getFullYear()} binding={[about, "yearBuilt", bindingAbout]}  validate = {formValidate}/>
            </div>
          </div>
          }
          <div className="col-6">
            <label htmlFor="DeclarationOfInterestTypeId">Declaration of interest *</label>
            <BindedSelect
                className="form-control"
                id="DeclarationOfInterestTypeId"
                name="DeclarationOfInterestTypeId"
                binding={[about, "declarationOfInterestTypeId", bindingAbout]}
                options={"declarationOfInterestTypes"}
                validate ={formValidate}
            ></BindedSelect>
          </div>
          {/* <div className="col-12">
            <div className="custom-checkbox custom-control">
                <BindedCheckBox
                    id="UseDefaultDesignVariationOnly" 
                    name="UseDefaultDesignVariationOnly"
                    className="custom-control-input"
                    binding={[about, "useDefaultDesignVariationOnly", bindingAbout]}
                ></BindedCheckBox>
                <label style={{marginLeft:"5px"}} htmlFor="UseDefaultDesignVariationOnly" className="custom-control-label">Use default design variation only</label>
            </div>
          </div> */}
        </div>
        <div className="form-row row">
          <div className="col-12">
            <div className="position-relative form-group">
              <label htmlFor="AdditionalInformation">
                Additional information
              </label>
              <BindedTextArea
                    id="AdditionalInformation"
                    name="AdditionalInformation"
                    className="form-control"
                    cols="112"
                    rows="5"
                    binding={[
                    about,
                    "additionalInformation",
                    bindingAbout,
                  ]}
              ></BindedTextArea>
            </div>
          </div>
        </div>
        <div className="main-card mb-3 card">
            <div className="card-header">
              <button type="button" className="text-left m-0 p-0 btn btn-block">
                <h5 className="m-0 p-0 card-title">Acknowledgement</h5>
              </button>
            </div>
            <div className="collapse show">
              <div className="card-body">
                <div className="form-row row">
                  <div className="col-12">
                    <div className="custom-checkbox custom-control">
                      <BindedCheckBox
                          id="SeparateLoadLimitsApply" 
                          name="SeparateLoadLimitsApply"
                          className="custom-control-input"
                          binding={[about, "separateLoadLimitsApply", bindingAbout]}
                      ></BindedCheckBox>
                      <label style={{marginLeft:"5px"}} htmlFor="SeparateLoadLimitsApply" className="custom-control-label">I am aware that separate heating and cooling load limits may apply to this assessment to achieve compliance with NCC 2022. See ABCB Standard: NatHERS heating and cooling load limits for details.</label>
                    </div>                 
                  </div>
                  <div className="col-12">
                    <div className="custom-checkbox custom-control">
                      <BindedCheckBox
                          id="SoftwareProviderResponsibilities" 
                          name="SoftwareProviderResponsibilities"
                          className="custom-control-input"
                          binding={[about, "softwareProviderResponsibilities", bindingAbout]}
                      ></BindedCheckBox>
                      <label style={{marginLeft:"5px"}} htmlFor="SoftwareProviderResponsibilities" className="custom-control-label">User's acknowledgement of software provider's responsibilities to the NA.</label>
                    </div>                 
                  </div>
                  <div className="col-12">
                    <div className="custom-checkbox custom-control">
                      <BindedCheckBox
                          id="ProvideAllInformation" 
                          name="ProvideAllInformation"
                          className="custom-control-input"
                          binding={[about, "provideAllInformation", bindingAbout]}
                      ></BindedCheckBox>
                      <label style={{marginLeft:"5px"}} htmlFor="ProvideAllInformation" className="custom-control-label">User's express agreement to provide all information.</label>
                    </div>                 
                  </div>
                  <div className="col-12">
                    <div className="custom-checkbox custom-control">
                      <BindedCheckBox
                          id="AdherenceToTechNote" 
                          name="AdherenceToTechNote"
                          className="custom-control-input"
                          binding={[about, "adherenceToTechNote", bindingAbout]}
                      ></BindedCheckBox>
                      <label style={{marginLeft:"5px"}} htmlFor="AdherenceToTechNote" className="custom-control-label">Adherence to Tech note.</label>
                    </div>                 
                  </div>
                  <div className="col-12">
                    <div className="custom-checkbox custom-control">
                      <BindedCheckBox
                          id="SoftwareUserTerms" 
                          name="SoftwareUserTerms"
                          className="custom-control-input"
                          binding={[about, "softwareUserTerms", bindingAbout]}
                      ></BindedCheckBox>
                      <label style={{marginLeft:"5px"}} htmlFor="SoftwareUserTerms" className="custom-control-label">Abiding by the software user terms.</label>
                    </div>                 
                  </div>
                </div>
                <div className="form-row row">
                  <div className="position-relative form-group">
                    <Button label="Select All" icon="pi pi-list-check" onClick={(e) => tickAll(e)} outlined />
                  </div>
                </div>
              </div>
            </div>
          </div>
      </BaseForm>
    </Fragment>
  );
};
export default connectAppStore(About);
