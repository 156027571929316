import objectPropertyValueGetSet from "../Function/ObjectPropertyValueGetSet";
import { useStateWithCallbackLazy } from 'use-state-with-callback';

function useReducerSmart(initValue, addValue) {
    if(initValue === undefined || initValue === null){
        initValue = {};
    }
    const [model, setModel] = useStateWithCallbackLazy(initValue);
    const setModelWithAddValue = (model, callback, debug) => {
        if(model === null || model === undefined) model = {};
        if(addValue === false){
            if(debug === true){
                console.log("setModel", model);
            }
            if(callback !== undefined){
                setModel(model, ()=>{
                    callback();
                });
            }else{
                setModel(model);
            }
        }else{
            if(callback !== undefined){
                setModel((oldModel)=>{
                    if(oldModel === undefined || oldModel === null){
                        oldModel = {};
                    }
                    let newModel = {};
                    if(isFunction(model) === true){
                        newModel = model(oldModel);
                    }else{
                        newModel = {...model};
                    }
                    let newState = { ...oldModel };
                    const keys = Object.keys(newModel);
                    keys.forEach((key) => {
                        let value = newModel[key];
                        newState[key] = value;
                    });
                    if(debug === true){
                        console.log("setModelAddValueCallBack",newState);
                    }
                    return newState;
                }, ()=>{
                    callback();
                });
            }else{
                setModel((oldModel)=>{
                    if(oldModel === undefined || oldModel === null){
                        oldModel = {};
                    }
                    let newModel = {};
                    if(isFunction(model) === true){
                        newModel = model(oldModel);
                    }else{
                        newModel = {...model};
                    }
                    let newState = { ...oldModel };
                    const keys = Object.keys(newModel);
                    keys.forEach((key) => {
                        let value = newModel[key];
                        newState[key] = value;
                    });
                    if(debug === true){
                        console.log("setModelAddValue",newState);
                    }
                    return newState;
                });
            }
        }
    }

    const bindingMethod = function (property, e, value, returnEmptyIfNum, defaultValue){
        const data = { ...model };
        for (const pName in property) {
            let newValue = value !== undefined ? value : (e === undefined || e.target === undefined ? null : e.target.value);
            if(returnEmptyIfNum !== false && newValue === null) {
                newValue = "";
            }
            if(defaultValue !== undefined && (newValue === "" || newValue === null) ){
                newValue = defaultValue;
            }
            if (newValue === '(Multiple Values)') {
                newValue = null;
            }
            objectPropertyValueGetSet.SetPropertyValue(data, pName, newValue);
        }
        setModel(data);
    }
    return [model, setModelWithAddValue, bindingMethod];

    function isFunction(functionToCheck) {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }


    // const reducer = useReducer((oldState, action) => {
    //     const keys = Object.keys(action);
    //     let newState = { ...oldState };
    //     if(addValue === false){
    //         newState = {};
    //     }
    //     keys.forEach((key) => {
    //         let value = action[key];
    //         newState[key] = value;
    //     });
    //     return newState;
    // }, initValue);
    // const dispatchMethod = reducer[1];
    // const dispatchObject = reducer[0];
    // const bindingMethod = function (property, e, value) {
    //     const data = { ...dispatchObject };
    //     for (const pName in property) {
    //         let newValue = value !== undefined ? value : e.target.value;
    //         if(newValue === null) newValue = "";
    //         objectPropertyValueGetSet.SetPropertyValue(data, pName, newValue);
    //     }
    //     dispatchMethod(data);
    // };
    // reducer[2] = bindingMethod;
    // return reducer;

}
export default useReducerSmart;
